import Redux from 'redux';

import { AppState } from '../app/AppState';
import { withFuncFactory } from '../Factory';
import { AuthenticationOperations } from '../modules/Auth/Services/Authentication/Base/AuthenticationOperations';

const getReduxStore = () => null;

export class StoreProvider {

    public static getStore = withFuncFactory(getReduxStore);

    public static async getToken(): Promise<string> {
        const store: Redux.Store = StoreProvider.getStore();
        if (store) {
            const token = await AuthenticationOperations.getAuthorization()(store.dispatch, store.getState);
            return Promise.resolve(token);
        }
        return Promise.resolve("");
    }


    public static getAppState(): AppState {
        const store: Redux.Store = StoreProvider.getStore();
        return store.getState();
    }
}