import { Map } from 'immutable';
import { MyShopsActions } from './MyShopsActions';

import Redux from 'redux';

const initialState = Map({
    my_shops: [],
    selectedShop: null,
});

export const MyShopsReducer = (state = initialState, action: Redux.AnyAction) => {
    switch (action.type) {
        case MyShopsActions.SET_MY_SHOPS:
            return state.set('my_shops', action.payload.shops);
        case MyShopsActions.SET_SELECTED_SHOP:
            return state.set('selectedShop', action.payload.shop);
        default:
            return state;
    }
};


