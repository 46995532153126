import { OperationType } from '../../modules/Operations/Models/OperationType';

export const frOperations = {
    'operations.navbar': 'Plans de vente',
    'operation.title': 'Plans de vente',
    'operation.list.emptyLabel': "Il n'y a pas de plans de vente pour le moment",

    [`operation.filter.category.${OperationType.Operation}`]: 'Plan de vente',
    [`operation.filter.category.${OperationType.Engagement}`]: 'Engagement',

    'operation.type.operation.label': 'Plan de vente',
    'operation.type.engagement.label': 'Engagement',

    'operation.details.title': 'Details Plan de vente',
    'engagement.details.title': 'Details Engagement',

    'operation.details.actions.previous': 'Plans de vente',

    'operation.details.notFound': 'Aucun plan de vente trouvé',
    'engagement.details.notFound': 'Aucun engagement trouvé',

    'operation.details.promo_price_enabled.title': 'Paramétrer les prix promo ?',
    'operation.details.promo_price_enabled.true': 'Oui',
    'operation.details.promo_price_enabled.false': 'Non',

    'operation.details.linked.operation': 'Plan de vente lié : {title}',
    'operation.details.linked.engagement': 'Engagement lié : {title}',

    'operation.validation.products.Product':
        '{nbReferences, plural, =0 {Produit} one {Produit pour {nbReferences} réference} other {Produits pour {nbReferences} réferences}}',
    'operation.validation.products.Box':
        '{nbReferences, plural, =0 {Box} one {Box pour {nbReferences} réference} other {Box pour {nbReferences} réferences}}',
    'operation.validation.products.Palette':
        '{nbReferences, plural, =0 {Palette} one {Palette pour {nbReferences} réference} other {Palettes pour {nbReferences} réferences}}',
    'operation.validation.products.TG':
        '{nbReferences, plural, =0 {TG} one {TG pour {nbReferences} réference} other {TG pour {nbReferences} réferences}}',
};
