export const esCommunications = {
    'communication.details.notFound': 'No se encontró comunicación',
    'communication.error.type.message': 'Hay comunicación con errores de tipo',
    'communication.initial.before_promo_price.label': 'Precio inicial',
    'communication.invalid.quantities.message':
        'La cantidad ingresada es inválida o menor que la cantidad mínima: {cantidad_min}',
    'communication.invalid.quantities.title': 'Cantidad no válida',
    'communication.list.emptyLabel': 'No hay planes de comunicación en este momento',
    'communication.minimal.count.label': 'NB Pers. objetivo',
    'communication.minimal.count.value': '{Contar} personas',
    'communication.page.count.label': 'Páginas',
    'communication.page.count.value': '{Page_count} páginas',
    'communication.participate.label': 'Participo',
    'communication.promo_price.per.person.value': '{Precio}/pers.',
    'communication.promo.promo_price_no_before.label': 'PRECIO',
    'communication.promo.promo_price.label': 'Precio promocional',
    'communication.submission.label': 'Pedir',
    'communication.submission.quantity': 'Cantidad no válida',
    'communication.submission.repliedDate': 'Pedido realizado {fecha}',
    'communication.submission.total.before_promo_price': 'Precio total',
    'communication.title': 'Comunicación',
    'communication.type.digital.label': 'Digital',
    'communication.type.DigitalMarketing': 'Digital',
    'communication.type.Leaflet': 'Folleto',
    'communication.type.leafletType.label': 'Folleto',
    'communication.type.Partnership': 'Camaradería',
    'communication.type.partnerships.label': 'Camaradería',
    'communication.unsubmission.label': 'No participo',
    'communication.weight.label': 'Peso',
    'communication.weight.value': '{weight} g',
    'communications.navbar': 'Comunicación',
};