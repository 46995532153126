import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Analytics } from '../../../../services/analytics/Analytics';

export interface ConnectState {
    errorEmailInput?: React.ReactNode;
    errorPasswordInput?: React.ReactNode;
    error?: React.ReactNode;
}

export interface UseConnectParameters {
    readonly loginWithEmail?: (email: string, password: string) => Promise<any>;
    readonly loginActiveDirectory?: () => Promise<any>;
    readonly loginWithImpersonate?: (pin: string) => Promise<any>;

    readonly emailConnecting?: boolean;
    readonly googleConnecting?: boolean;
    readonly activeDirectoryConnecting?: boolean;
    readonly impersonateConnecting?: boolean;
}

export const useConnect = ({
    loginWithEmail = (email: string, password: string) => Promise.resolve({}),
    loginActiveDirectory = () => Promise.resolve(),
    loginWithImpersonate = pin => Promise.resolve(),
    emailConnecting,
    googleConnecting,
    activeDirectoryConnecting,
    impersonateConnecting,
}: UseConnectParameters) => {
    const [state, setState] = React.useState<ConnectState>({});
    const update = (values: Partial<ConnectState>) => setState(prev => ({ ...prev, ...values }));

    const clearErrors = () => update({ error: '', errorEmailInput: '', errorPasswordInput: '' });

    const doLoginWithEmail = (email: string, password: string): Promise<any> => {
        Analytics.log({ category: 'Clic', action: 'Login', label: 'Login', email });

        if (emailConnecting) {
            return Promise.reject(new Error('Already connecting'));
        }
        update({ error: '' });
        if (!email) {
            update({ errorEmailInput: <FormattedMessage id="login.username.inputEmailEmpty" /> });
            return Promise.reject(new Error('Email input is empty'));
        }
        update({ errorEmailInput: '' });
        if (!password) {
            update({
                errorPasswordInput: <FormattedMessage id="login.username.inputPasswordEmpty" />,
            });
            return Promise.reject(new Error('Password input is empty'));
        }
        //update({ errorPasswordInput: '', emailConnecting: true });
        update({ errorPasswordInput: '' });

        return loginWithEmail(email, password).catch(err => {
            update({
                //emailConnecting: false,
                error: <FormattedMessage id="login.username.error" />,
                errorEmailInput: true,
                errorPasswordInput: true,
            });
            return Promise.reject(err);
        });
    };

    const doLoginAzure = (): Promise<any> => {
        if (activeDirectoryConnecting) {
            return Promise.reject(new Error('Already connecting'));
        }
        return loginActiveDirectory().catch((err: any) => {
            update({
                //activeDirectoryConnecting: false,
                error: <FormattedMessage id="login.azure.error" />,
            });
            return Promise.reject(err);
        });
    };

    const doLoginWithImpersonate = (pin: string): Promise<any> => {
        Analytics.log({ category: 'Clic', action: 'Login', label: 'Login', pin });

        if (impersonateConnecting) {
            return Promise.reject(new Error('Already connecting'));
        }

        clearErrors();

        return loginWithImpersonate(pin).catch(err => {
            update({
                //impersonateConnecting: false,
                error: <FormattedMessage id="login.impersonate.error" />,
            });
            return Promise.reject(err);
        });
    };

    return { connectState: state, doLoginWithEmail, doLoginAzure, doLoginWithImpersonate };
};
