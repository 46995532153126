import React from 'react';
import { IntlProvider } from 'react-intl';

import { useI18nState } from '../../../Hooks/useAppState';
import { Loading } from '../../../scenes/Loading';
import { I18nHelper } from '../../../services/i18n/I18nHelper';
import { logger } from '../../../services/logger/logger';

export type IntlWrapperProps = { importLocaleData?: () => Promise<any> };

export const IntlWrapper = ({ children, importLocaleData }: React.PropsWithChildren<IntlWrapperProps>) => {
    const i18n = useI18nState();

    const [loadingIntl, setLoadingIntl] = React.useState(true);
    React.useEffect(() => {
        const setup = async () => {
            try {
                await I18nHelper.importNestedIntl();
                if (importLocaleData) {
                    await importLocaleData();
                }
            } catch (err) {
                logger.error('Error setting up intl', err);
            }
            setLoadingIntl(false);
        };
        setup();
    }, []);

    if (loadingIntl) {
        return <Loading />;
    }
    return <IntlProvider {...i18n}>{children}</IntlProvider>;
};
