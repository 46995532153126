export const frEvents = {
    'events.title': 'Évènements',
    'events.label': 'Évènement',
    'events.list.emptyLabel': 'Aucun évènement pour le moment.',

    'events.feed.noReply': "Je n'ai pas répondu",

    'events.details.title': 'Détails Évènement',
    'events.details.notFound': 'Aucun évènement trouvé',
    'events.participation.title': 'PARTICIPATION',
    'events.participation.question': 'Comptez-vous participer à cet évènement ?',
    'events.participation.date': 'Le {eventStartDate} de {eventStartDateStartTime} à {eventEndDateEndTime}',
    'events.participation.date_range': 'Du {eventStartDate} à {eventStartDateStartTime} au {eventEndDate} à {eventEndDateEndTime}',


};
