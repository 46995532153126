import Axios from 'axios';

import {
    ClientConfig,
    ConfigFactory,
    ElasticSearchConfig,
    EnvironmentConfig,
    getGoogleClientId,
    getGoogleMapApiKey,
    getLogsConfig,
    getRoot,
} from '../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/configs/ApiConfig';

export class FluizyApiConfig {

    public static VERSION: EnvironmentConfig = {
        development: "development",
        production: "__APP_VERSION__"
    };

    public static CLIENT: ClientConfig = {
        id: "FLUIZY_WEB_FRONT",
        secret: "Ideine"
    };

    public static API_DOMAIN: EnvironmentConfig = {
        production: "__API_HOST__",
        development: "https://idelink-fluizy-api-dev.azurewebsites.net",
    }

    public static GOOGLE_CLIENT_ID: EnvironmentConfig = {
        development: "1008543795176-3dd0ievcud2a728np6bf6krh2ie5b0li.apps.googleusercontent.com",
        production: "__GOOGLE_CLIENT_ID__"
    }

    public static GOOGLE_MAP_API_KEY: EnvironmentConfig = {
        development: "AIzaSyDMKYHxdUe126Mgb89kAB0hOKPqlQkBbVg",
        production: "__GOOGLE_MAP_API_KEY__"
    };

    public static ES_INDEX_PRODUCTION = '__ELASTIC_SEARCH_INDEX__';
	public static ES_INDEX_DEVELOPMENT = 'idelink-bo-local';

    public static LOGS: ElasticSearchConfig = {
        code: "fIU59n4QGM0OE7UVfQQLrVkwgmygDragYTGLZR1CqVetA6bPSj0Gnw==",
        clientId: "default",
        domain: "https://idelink-functions-app.azurewebsites.net",
        uri: "/api/IdelinkMobileAnalytics",
        elasticSearchIndex: {
			development: FluizyApiConfig.ES_INDEX_DEVELOPMENT,
			production: FluizyApiConfig.ES_INDEX_PRODUCTION,
		},
    }

    public static initApiConfig = () => {
        ConfigFactory.getRoot.register('**', () => getRoot(FluizyApiConfig.API_DOMAIN));
        Axios.defaults.baseURL = getRoot(FluizyApiConfig.API_DOMAIN);

        ConfigFactory.getClient.register('**', () => FluizyApiConfig.CLIENT);
        ConfigFactory.getGoogleClientId.register('**', () => getGoogleClientId(FluizyApiConfig.GOOGLE_CLIENT_ID));
        ConfigFactory.getGoogleMapApiKey.register('**', () => getGoogleMapApiKey(FluizyApiConfig.GOOGLE_MAP_API_KEY));
        ConfigFactory.getLogsConfig.register('**', () => getLogsConfig(FluizyApiConfig.LOGS));
    };
}
