import { useSelector } from 'react-redux';

import { AppState } from '../app/AppState';
import { AuthenticationState } from '../modules/Auth/Services/Authentication/AuthenticationState';
import { UserState } from '../modules/User/UserState';
import { I18nState } from '../services/i18n/I18nMessages';

/** @deprecated be carefull with this it will cause re-render for each state change.  */
export const useAppState = (): AppState => useSelector((state: AppState) => state);

export const useAuthenticationState = (): AuthenticationState => useSelector((state: AppState) => state.Authentication);
export const useI18nState = (): I18nState => useSelector((state: AppState) => state.I18n);
export const useUserState = (): UserState => useSelector((state: AppState) => state.User);
