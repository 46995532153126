import './Message.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { withFactory } from '../../../../../Factory';

export interface MessageProps {
    className?: string;
}

const Message = ({ className }: MessageProps) => (
    <div className={className}>
        <div className="page-login-citation">
            <FormattedMessage id="login.citation" />
        </div>
        <div className="page-login-author">
            <FormattedMessage id="login.author" />
        </div>
    </div>
);

const MessageFactory = withFactory(Message);

export { Message, MessageFactory };
