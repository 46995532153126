import { ActiveDirectoryAuthResult } from "../../../Models/AuthenticationResponse";

export class ActiveDirectoryAuthActions {
    public static LOGIN_AD_SUCCESS = 'app/auth/active_directory/login/success';
    public static LOGIN_AD_FAIL = 'app/auth/active_directory/login/fail';
    public static REFRESH_AD_SUCCESS = 'app/auth/active_directory/refresh/success';
    public static REFRESH_AD_FAIL = 'app/auth/active_directory/refresh/fail';

    public static loginAdSuccess = ({ ad_access_token, expiration_date }: ActiveDirectoryAuthResult) => ({
        type: ActiveDirectoryAuthActions.LOGIN_AD_SUCCESS,
        payload: { ad_access_token, expiration_date },
    });

    public static loginAdFail = (error: any) => ({
        type: ActiveDirectoryAuthActions.LOGIN_AD_FAIL,
        payload: { error },
    });

    public static refreshAdSuccess = ({ ad_access_token, expiration_date }: ActiveDirectoryAuthResult) => ({
        type: ActiveDirectoryAuthActions.REFRESH_AD_SUCCESS,
        payload: { ad_access_token, expiration_date },
    });

    public static refreshAdFail = (error: any) => ({
        type: ActiveDirectoryAuthActions.REFRESH_AD_FAIL,
        payload: { error },
    });
}