import { push } from 'connected-react-router';
import { Dispatch } from 'react';
import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { AppState } from '../../../../../app/AppState';
import { AppActions } from '../../../../../redux/AppActions';

import { AuthModule } from '../../../AuthModule';
import { AuthenticationActions } from './AuthenticationActions';
import { AuthenticationOperations } from './AuthenticationOperations';

export const AuthenticationMiddleware: Middleware = ({ dispatch, getState }: MiddlewareAPI<any, AppState>) => (next: Dispatch<AnyAction>) => (action: any) => {
    // ! exist in ThunkMiddleware
    // if (typeof action === 'function') {
    //     return action(dispatch, getState);
    // }
    if (action && action.type === AuthenticationActions.LOG_OUT) {
        const res = next(action);
        dispatch(push(AuthModule.AUTH_LOGIN));
        return res;
    }
    if (action && action.type === AppActions.APP_ESSENTIAL_RELOAD) {
        const res = next(action);
        dispatch(AuthenticationOperations.refreshAuthentication()).catch((err: any) => err);
        return res;
    }
    return next(action);
};

