import './UserInfoCard.scss';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useUserInfoCardController } from './UserInfoCardController';
import { CheckboxV2 } from '../../../../lib/IdeLink.Web.Common/src/components/CheckBoxes/CheckboxV2/CheckboxV2';

interface UserInfoCard {
    classes: {
        root?: string;
        header?: string;
        headerAvatar?: string;
        headerNames?: string;
        headerLastName?: string;
        headerFirstName?: string;
    };
}

export const UserInfoCard = ({ classes }: UserInfoCard) => {
    const { userInfo, enableEmailNotifications } = useUserInfoCardController();
    const initials = `${(userInfo.first_name || '').charAt(0)}${(userInfo.last_name || '').charAt(0)}`;

    const {
        root: rootClasses = '',
        header: headerClasses = '',
        headerAvatar: headerAvatarClasses = '',
        headerNames: headerNamesClasses = '',
        headerLastName: headerLastNameClasses = '',
        headerFirstName: headerFirstNameClasses = '',
    } = classes || {};

    return (
        <div className={classNames('user-info-card-root', rootClasses)}>
            <div className={classNames('uic-header', headerClasses)}>
                <div className={classNames('uic-h-avatar', headerAvatarClasses)}>{initials}</div>
                <div className={classNames('uic-h-names', headerNamesClasses)}>
                    <div className={classNames('uic-h-last-name', headerLastNameClasses)}>{userInfo.last_name}</div>
                    <div className={classNames('uic-h-first-name', headerFirstNameClasses)}>{userInfo.first_name}</div>
                </div>
            </div>
            <FormControlLabel
                control={
                    <CheckboxV2
                        checked={userInfo.has_email_notification_enabled}
                        onChange={(_, checked) => enableEmailNotifications(checked)}
                        classes={{ root: 'uic-notifs-checkbox' }}
                    />
                }
                label={<FormattedMessage id="profile.email_notifications_enabled.label" />}
                labelPlacement="start"
                classes={{ root: 'uic-notifs', label: 'uic-notifs-label' }}
            />
        </div>
    );
};
