import areIntlLocalesSupported from 'intl-locales-supported';

import { I18nMessages } from "./I18nMessages";

export class I18nHelper {

    //* use this to allow nested messages, taken from docs:
    //* https://github.com/yahoo/react-intl/wiki/Upgrade-Guide#flatten-messages-object
    public static flattenMessages = (nestedMessages: I18nMessages = {}, prefix = '') => {
        return Object.keys(nestedMessages).reduce((messages: I18nMessages, key: string) => {
            const value = nestedMessages[key];
            const prefixedKey: string = prefix ? `${prefix}.${key}` : key;

            if (typeof value === 'string') {
                messages[prefixedKey] = value;
            } else {
                Object.assign(messages, I18nHelper.flattenMessages(value, prefixedKey));
            }
            return messages;
        }, {});
    }

    //! je ne vois pas l'interêt de cette action
    /** @returns {Promise} resolves once react-intl locales and intl are loaded and setup */
    public static importNestedIntl = (): Promise<any> => {
        const intlImports: Array<any> = [];

        if (!window.Intl) {
            intlImports.push(import('intl'));
        }

        if (!areIntlLocalesSupported(['fr'])) {
            // `Intl` exists, but it doesn't have the data we need, so load the
            // polyfill and replace the constructors we need with the polyfill's.
            intlImports.push(
                //@ts-ignore
                import('@formatjs/intl-pluralrules/polyfill'),
                //@ts-ignore
                import('@formatjs/intl-pluralrules/dist/locale-data/fr'),
                //@ts-ignore
                import('@formatjs/intl-relativetimeformat/polyfill'),
                //@ts-ignore
                import('@formatjs/intl-relativetimeformat/dist/locale-data/fr')
            );
        }
        return Promise.all(intlImports);
    };

}