import React from 'react';
import { toast } from 'react-toastify';
import { AppState } from '../../app/AppState';
import { EnvironmentHelper } from '../../Helpers/EnvironmentHelper';
import { I18nMessages } from '../i18n/I18nMessages';

import { logger } from '../logger/logger';

export interface ApiError {
    type: string;
    request: { url: string };
    error: { config: any; response: any; data: any; status: any }; //* must be define
    shouldToast?: boolean;
    customErrorCode?: string;
}

export interface ToApiErrorParameters {
    url?: string;
    axiosError: any;
    shouldToast?: boolean;
    customErrorCode?: string;
}

export class ErrorLogger {
    //! REMOVE
    public static logApiError = (apiError: ApiError) => {
        return (dispatch: any, getState: any) => {
            const appState = getState();
            ErrorLogger.showApiError(apiError, appState);
        };
    };
    public static showApiError = (apiError: ApiError, appState: AppState) => {
        const { error, shouldToast = true, customErrorCode } = apiError;
        //const { config, response } = error;

        let message: React.ReactNode;

        const {
            data: { error_code = '', error_message = '' },
        } = error || { data: { error_code: '', error_message: '' } };

        if (error_code === 'IMPORT_ERROR') {
            message = <div style={{ whiteSpace: 'pre' }}>{error_message}</div>;
        } else {
            const messages: I18nMessages = appState.I18n.messages;

            if (error && error.status === 403) {
                message = messages[`toast.apiErrors.403`] || "Le compte n'est pas autorisé à accéder à l'application.";
            } else {
                message =
                    messages[`toast.apiErrors.${customErrorCode}`] ||
                    messages[`toast.apiErrors.${error_code}`] ||
                    messages[`toast.apiErrors.defaultMessage`] ||
                    "Quelque chose ne s'est pas passé comme prévu, merci de réessayer";

                if (EnvironmentHelper.isDev()) {
                    console.log('🔥🔥🔥 | logApiError : ', { customErrorCode, message, error_code, apiError });
                }
            }
        }

        if (shouldToast) {
            toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
        }

        const location = appState.router.location;
        const data = {
            log_level: 'error',
            timestamp: new Date(),
            context: {
                userAgent: navigator.userAgent,
                //location: window.location,
                location: {
                    pathname: location ? location.pathname : null,
                    search: location ? location.search : null,
                    hash: location ? location.hash : null,
                },
                user: {
                    ...appState.User.get('me'),
                    i18nLocale: appState.I18n.locale,
                },
            },
            error,
        };
        logger.error('API ERR', data);
    };

    //#region //* NESTED

    public static toApiApiError = ({
        url = '',
        axiosError = {},
        shouldToast,
        customErrorCode,
    }: ToApiErrorParameters): ApiError => {
        const { data } = axiosError || {};
        return {
            type: 'api',
            request: { url },
            error: {
                ...axiosError,
                data: data || { error_code: '', error_message: '' },
            },
            shouldToast,
            customErrorCode,
        };
    };

    //#endregion
}
