import Redux from 'redux';
import { XHRRequest } from '../../../../../services/api';
import { AuthenticationPinResponse } from '../../../Models/AuthenticationResponse';
export class ImpersonateApi {

    public static IMPERSONATE_PIN = (pin: string) => `/api/v1/impersonate/pins/${pin}`

    public static login(pin: string, dispatch: Redux.Dispatch): Promise<AuthenticationPinResponse> {
        return XHRRequest.post<AuthenticationPinResponse>(
            ImpersonateApi.IMPERSONATE_PIN(pin), {}, { dispatch }
        );
    }

}