import './GoogleConnectButton.scss';

import classNames from 'classnames';
import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { FormattedMessage } from 'react-intl';

import { Loader } from '../../../../../components/Loader';
import icConnectGoogle from '../../../assets/ic_connect_google.svg';
import { GoogleAuthService } from '../../../Services/Authentication/Google/GoogleAuthService';

export interface GoogleConnectButtonProps {
    readonly loading?: boolean;
    readonly setAuthFromGoogleCredentials: (credentials: any) => Promise<void>;
    readonly renderGoogleButton?: () => React.ReactNode;
    readonly classes?: { root?: string };
    readonly disabled?: boolean;
}

export const GoogleConnectButton = ({
    loading = false,
    disabled = false,
    setAuthFromGoogleCredentials,
    classes = {},
}: GoogleConnectButtonProps) => {

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setAuthFromGoogleCredentials(codeResponse),
        flow: 'auth-code',
        onError: () => GoogleAuthService.logout(),
    });

    return (
        <div
            role="button"
            className={classNames({ 'GoogleConnectButton-button': true, [classes.root || '']: classes.root })}
            onClick={disabled ? undefined : () => login()}
        >
            {!loading && (
                <div className="GoogleConnectButton-button-label">
                    <img className="GoogleConnectButton-icon" src={icConnectGoogle} alt="google-logo" />
                    <div className="GoogleConnectButton-title">
                        <FormattedMessage id="login.google.btnLabel" />
                    </div>
                </div>
            )}
            {loading && <Loader classes={{ container: 'GoogleConnectButton-loader' }} />}
        </div>
    );
};
