import { overrideFluizyAuthComponents } from './FluizyAuth/AuthComponentFactory';
import { overrideFluizyNavBar } from './FluizyNavBar/FluizyNavBar';

export class FluizyComponents {
    public static applyComponentOverrides = (): void => {
        overrideFluizyAuthComponents();
        overrideFluizyNavBar();
    };
}


