/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef } from 'react';

export type BaseViewState = {
    modified?: boolean;
};

export interface UseViewStateParams<TState extends BaseViewState> {
    readonly initialState: TState;
}

export interface UseViewStateResult<TState extends BaseViewState> {
    readonly initialState: TState;
    readonly getState: () => TState;
    readonly onChange: (field: keyof TState, value: any, afterUpdate?: (newState: TState) => void) => void;
    readonly update: (values: Partial<TState>, afterUpdate?: (newState: TState) => void) => void;
}

export const useViewState = <TState extends BaseViewState>(initialState: TState): UseViewStateResult<TState> => {
    const [state, setState] = React.useState<TState>(initialState || {});

    // On stocke l'état dans une ref, ça permet d'accéder au state actuel même si React n'a pas encore fait un re-render.
    const futureStateRef = useRef(state);
    futureStateRef.current = state;
    const getFutureState = (): TState => futureStateRef.current;
    // Note PGU :
    // c'est, à mon avis, une mauvaise pratique
    // à certain moment, il y a un décalage entre l'état "state" et getFutureState(). (et c'est sans doute pour ça que la ref existe à l'origine... pour accéder à l'état du prochain rendu)
    // normalement dans 99% des cas l'accès à la variable "state" devrait suffir (je me trompe??),
    // si elle n'est pas à jour, c'est qu'il y a un autre problème (dépendance de useEffect, useCallback, mauvaise utilisation).
    // J'ai essayé de remplacer ce getState() => ref.current par getState() => state ça crée des bugs un peu partout 😬 ....
    // Pour la suite, il faudrait :
    //    - retourner l'objet "state" et l'utiliser en priorité.
    //    - ne plus utiliser getState()/ getFutureState().

    const onChange = (field: keyof TState, value: any, afterUpdate?: (newState: TState) => void): void => {
        const values = { [field]: value, modified: true } as Partial<TState>;
        update(values, afterUpdate);
    };

    const update = (values: Partial<TState>, afterUpdate?: (newState: TState) => void): void => {
        setState((s) => {
            const newState = { ...s, ...values };

            setTimeout(() => {
                if (afterUpdate) {
                    afterUpdate(newState);
                }
            }, 0);

            futureStateRef.current = newState;
            return newState;
        });

        // Notes PGU:
        // Voilà le cheminement pour arriver au code ci-dessus:
        // J'ai essayé de supprimer la futureStateRef, sans me rendre compte de son importance, ce qui a amené un certain nombre de bug dont celui-ci.
        // Je garde le code corrigé car il fonctionne avec et sans futureStateRef.
        // Précédement:

        // const newState: TState = { ...getState(), ...values };
        // setState(newState);
        // if (afterUpdate) {
        //     afterUpdate(newState);
        // }
        // Dans certain cas les différents appels à setState sont font par lôt
        // (uniquement suite à un événement avec React 16, dans 100% des cas après react 18).
        // Du coup, lorsqu'il y a deux appels à la suite à la méthode "update":
        //  - le 1er appel modifie le state mais ne déclanche pas de rerendu
        //  - lors du 2eme appel, getState() retourne l'état "initial" avant la modif du 1er appel.
        // l'état final ne représente pas ce qui est voulu
        // ex: update({ data: result }); puis update({ loading: false})  => state= {data: undefined; loading: false; }

        // Pour corriger ça il y a l'appel à setState avec un callback
        //  qui retourne en permanance la bonne valeur de l'état (suppression de l'utilisation de getState() ):

        // setState((s) => {
        //     const newState = { ...s, ...values };
        //     if (afterUpdate) {
        //         afterUpdate(newState);
        //     }
        //     return newState;
        // });

        // ça ne corrige pas complétement le problème car l'afterUpdate est appellé avant la mise à jour de l'état (le return du callback)
        // Résultat en ajoutant un setTimeout(() => {afterupdate()}, 0) je m'assure que l'afterupdate est exécuté au plus vite *après* le return.
    };

    return { initialState, getState: getFutureState, onChange, update };
};
