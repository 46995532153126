/* eslint-disable no-setter-return */
import UAParser from 'ua-parser-js';

import { ConfigFactory } from '../../configs/ApiConfig';
import { MeInfo } from '../../modules/User/UserState';
import { uuid } from 'uuidv4';

export class UserInformations {
    public static toElasticSearchUserInfos = (meInfo: MeInfo) => {
        const { id: userId = '', email: userEmail = '', first_name = '', last_name = '' } = meInfo || {};
        return { userId, userEmail, userName: `${first_name} ${last_name}` };
    };
}

export class SelectedShopInformations {
    //@type : ShopForUserWithRoles
    public static toElasticSearchSelectedShopInfos = (selectedShop: any) => {
        const { id: shopId = '', name: shopName = '', metadata = [] } = selectedShop || {};
        //todo review
        return {
            shopId,
            shopName,
            ...metadata.reduce((prev: any, curr: any) => {
                prev[curr.key] = curr.value;
                return prev;
            }, {}),
        };
    };
}

export class DeviceInformation {
    private static DEVICE_ID_KEY = 'fluizy_front_device_id';

    private static getViewportDimensions = () => {
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return `(${width},${height})`;
    };

    private static getScreenDimensions = () => {
        const width = Math.max(window.screen.width, window.screen.availWidth || 0);
        const height = Math.max(window.screen.height, window.screen.availHeight || 0);
        return `(${width},${height})`;
    };

    private static getDeviceId = () => {
        let deviceId = localStorage.getItem(DeviceInformation.DEVICE_ID_KEY);
        if (!deviceId) {
            deviceId = uuid();
            localStorage.setItem(DeviceInformation.DEVICE_ID_KEY, deviceId || '');
        }
        return deviceId;
    };

    public static getElasticSearchDeviceInfos = () => {
        const parser = new UAParser();
        const { browser, os } = parser.getResult();

        const deviceId = DeviceInformation.getDeviceId();

        return {
            deviceId,
            DeviceId: deviceId,
            AppVersion: ConfigFactory.getVersion(),
            //* device
            device_ApplicationFullVersion: ConfigFactory.getVersion(),
            device_environment: 'Web',
            device_osVersion: os.version,
            device_osName: os.name,
            device_localizedModel: browser.name,
            device_model: browser.name,
            device_displaySize: DeviceInformation.getViewportDimensions(),
            device_displayNativeSize: DeviceInformation.getScreenDimensions(),
            //* os
            osType: os.name,
            osVersion: os.version,
            //* custom props
            browser,
        };
    };
}
