import React from 'react';
import { useDispatch } from 'react-redux';
import { AppActions } from '../../../redux/AppActions';

export const EssentialInfo = ({ children }: React.PropsWithChildren<any>) => {
    const dispatch = useDispatch();
    const triggerEssentialReload = (redirectMainPage: boolean) => {
        dispatch(AppActions.triggerEssentialReload(redirectMainPage));
    };

    React.useEffect(() => {
        triggerEssentialReload(false);
    }, []);

    return <React.Fragment>{children}</React.Fragment>;
};
