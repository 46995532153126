import './InputPinDialog.scss';

import { Dialog, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Assets } from '../../../../../../assets/Assets';
import { Button } from '../../../../../../components/Buttons';
import { TextField } from '../../../../../../components/TextField';
import icConnectMaintenance from '../../../../assets/ic_connect_maintenance.svg';

export interface InputPinDialogProps {
    readonly open: boolean;
    inputHintLabel: string;
    onValidateInput: (value: string) => void;
    onCancel: () => void;
}

export const InputPinDialog = ({ open, inputHintLabel, onValidateInput, onCancel, ...rest }: InputPinDialogProps) => {
    const [input, setInput] = React.useState('');

    const validateDiabled: boolean = input.trim().length === 0;
    return (
        <Dialog open={open} onClose={onCancel} {...rest}>
            <div className="InputPinDialog-content">
                <div className="InputPinDialog-content-header">
                    <img src={icConnectMaintenance} className="InputPinDialog-content-header-icon" alt="icon" />
                    <div className="InputPinDialog-content-header-title">
                        <FormattedMessage id="login.impersonate.dialog.title" />
                    </div>
                    <IconButton onClick={onCancel} classes={{ root: 'InputPinDialog-content-header-btn-close' }}>
                        <img src={Assets.IcCloseBlack()} alt="close" />
                    </IconButton>
                </div>
                <div className="InputPinDialog-content-body">
                    <TextField
                        value={input}
                        hintText={inputHintLabel}
                        onChange={(e) => setInput(e.target.value)}
                        classes={{ root: 'InputPinDialog-pin-input-container', input: 'InputPinDialog-pin-input' }}
                        disableUnderline
                    />
                    <Button
                        onClick={() => onValidateInput(input)}
                        disabled={validateDiabled}
                        label={<FormattedMessage id="commons.ok" />}
                        classes={{
                            root: classNames({
                                'InputPinDialog-validate-btn-container': true,
                                disabled: validateDiabled,
                            }),
                            button: classNames({ 'InputPinDialog-validate-btn': true, disabled: validateDiabled }),
                        }}
                    />
                </div>
            </div>
        </Dialog>
    );
};
