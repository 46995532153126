import { isDateBeforeWithParse } from "../../../../../utils/dateUtils";
import { LoginMethod } from "../AuthenticationConstants";

export class AuthenticationUtils {

    /** @param {Number} expirationDate milliseconds since epoch**/
    public static authenticationIsExpired = (expirationDate?: string | Date): boolean => {
        if (!expirationDate) return true;
        return isDateBeforeWithParse(expirationDate, new Date());
    };

    public static isLoginMethodGoogle = (method: LoginMethod): boolean => method === LoginMethod.LOGIN_METHOD_GOOGLE;
    public static isLoginMethodUsername = (method: LoginMethod): boolean => method === LoginMethod.LOGIN_METHOD_USERNAME;
    public static isLoginMethodActiveDirectory = (method: LoginMethod): boolean => method === LoginMethod.LOGIN_METHOD_ACTIVE_DIRECTORY;
    public static isLoginMethodImpersonate = (method: LoginMethod): boolean => method === LoginMethod.LOGIN_METHOD_IMPERSONATE;

}