import React from 'react';
import { FormattedMessage } from 'react-intl';

import iconOfferWhite from './assets/offer.svg';
import { NavbarModuleItem } from '../../lib/IdeLink.Web.Common/src/components/NavBar/components/NavBarItem/NavBarItem';
import { RouteItem } from '../../lib/IdeLink.Web.Common/src/types/RouteItem';
import { PrivateRoute } from '../../routers/PrivateRoute';
import { getApiRoute } from '../../configs/ApiRoutesMap';
import { PackagedOrderDetailsPageLoading } from './Pages/PackagedOrderDetailsPage/PackagedOrderDetailsPageLoading';
import { PackagedOrderListPageLoading } from './Pages/PackagedOrderListPage/PackagedOrderListPageLoading';

const OFFERS_BASE_ROUTE = () => getApiRoute(OffersModule.OFFERS_MODULE_CODE);

export class OffersModule {
    public static OFFERS_MODULE_CODE = 'PackagedOrders';

    public static PACKAGED_ORDER_LIST = () => OFFERS_BASE_ROUTE();
    public static PACKAGED_ORDER_DETAILS = () => `${OFFERS_BASE_ROUTE()}/:id`;
    public static GET_PACKAGED_ORDER_DETAILS = (id: string) => `${OFFERS_BASE_ROUTE()}/${id}`;

    private static getPackagedOrderListRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: OffersModule.PACKAGED_ORDER_LIST(),
            component: PackagedOrderListPageLoading,
        },
    });
    private static getPackagedOrderDetailsRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: OffersModule.PACKAGED_ORDER_DETAILS(),
            component: PackagedOrderDetailsPageLoading,
        },
    });

    public static getOffersNavbarModuleItem = (): NavbarModuleItem => ({
        icon: iconOfferWhite,
        label: <FormattedMessage id="offers.navbar" />,
        route: OffersModule.PACKAGED_ORDER_LIST(),
        onMouseOver: () => OffersModule.preloadPackagedOrders(),
    });

    private static preloadPackagedOrders = () => PackagedOrderListPageLoading.preload();

    public static getPackagedOrdersRoutes = (): Array<RouteItem> => [
        OffersModule.getPackagedOrderListRoute(),
        OffersModule.getPackagedOrderDetailsRoute(),
    ];
}
