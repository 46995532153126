import { ShopForUserWithRoles } from '../../models/ShopForUser';

export class MyShopsActions {
    public static SET_MY_SHOPS = 'app/shops/set/my';
    public static SET_SELECTED_SHOP = 'app/myshops/set/selected';

    public static setMyShops = (shops: Array<ShopForUserWithRoles>) => ({
        type: MyShopsActions.SET_MY_SHOPS,
        payload: { shops },
    });

    public static setSelectedShop = (shop?: ShopForUserWithRoles) => ({
        type: MyShopsActions.SET_SELECTED_SHOP,
        payload: { shop },
    });
}


