import Redux from 'redux';
import { AuthenticationActions } from '../Base/AuthenticationActions';
import { AuthenticationUtils } from '../Base/AuthenticationUtils';
import { GoogleAuthActions, GoogleLoginResponse } from './GoogleAuthActions';
import { GoogleAuthUtils } from './GoogleAuthUtils';
import { googleLogout } from '@react-oauth/google';

interface ErrorResponse {
    isError: true;
    error: Error;
}

export class GoogleAuthService {
    public static setToken =
        (credentials: any) =>
        (dispatch: Redux.Dispatch<Redux.AnyAction>): Redux.AnyAction => {
            return dispatch(GoogleAuthActions.setGoogleToken(credentials));
        };

    public static setRefreshToken =
        (refreshToken: string) =>
        (dispatch: Redux.Dispatch<Redux.AnyAction>): Redux.AnyAction => {
            return dispatch(GoogleAuthActions.setGoogleRefreshToken(refreshToken));
        };

    public static logout =
        (reason?: string) =>
        (dispatch: Redux.Dispatch): Redux.AnyAction => {
            googleLogout();
            return dispatch(AuthenticationActions.logout(reason));
        };

    public static isErrorResponse(item: any): item is ErrorResponse {
        return 'isError' in item;
    }

    public static setAuthFromGoogleCredentials = (codeResponse: any) => async (dispatch: (action: any) => any) => {
        await dispatch(AuthenticationActions.setGoogleLoginMethod());

        if (!!codeResponse.code) {
            const dataResponse = await GoogleAuthService.getRefreshAndAccessToken(codeResponse.code, dispatch);
            if (!GoogleAuthService.isErrorResponse(dataResponse)) {
                const idToken = dataResponse.id_token;

                await dispatch(
                    GoogleAuthService.setToken({
                        token: idToken,
                        expires_at: new Date(new Date().setHours(new Date().getHours() + 1)),
                    })
                );

                await dispatch(GoogleAuthService.setRefreshToken(dataResponse.refresh_token));
                return GoogleAuthUtils.getAuthorizationFromToken(idToken);
            }
        }
    };

    public static getRefreshAndAccessToken = async (
        code: string,
        dispatch: any
    ): Promise<GoogleLoginResponse | ErrorResponse> => {
        let payload = {
            code: code,
            redirect_uri: window.location.protocol + '//' + window.location.host,
        };

        try {
            const response = await GoogleAuthActions.googleLogin(payload);
            return response.data;
        } catch (error) {
            await dispatch(GoogleAuthService.logout());
            return {
                isError: true,
                error: new Error(`Could not fetch google tokens`),
            };
        }
    };

    public static refreshGoogleAuthentication = async (
        dispatch: any,
        getState: any
    ): Promise<string | ErrorResponse> => {
        const refreshToken = getState().Authentication.get('refresh_token');
        if (!refreshToken) {
            await dispatch(GoogleAuthService.logout());
            return {
                isError: true,
                error: new Error(`Could not refresh google auth : no refresh token exist`),
            };
        }
        try {
            const bodyRequest = { refresh_token: refreshToken };
            const dataResponse = (await GoogleAuthActions.googleRefresh(bodyRequest)).data;
            const idToken = dataResponse.id_token;
            await dispatch(
                GoogleAuthService.setToken({
                    token: idToken,
                    expires_at: new Date(new Date().setHours(new Date().getHours() + 1)),
                })
            );

            if (dataResponse.refresh_token) {
                await dispatch(GoogleAuthService.setRefreshToken(dataResponse.refresh_token));
            }

            return GoogleAuthUtils.getAuthorizationFromToken(idToken);
        } catch (error) {
            await dispatch(GoogleAuthService.logout());
            return {
                isError: true,
                error: new Error(`Could not refresh google auth after api request`),
            };
        }
    };

    public static getGoogleAuthToken =
        () =>
        async (dispatch: (action: any) => Promise<any>, getState: () => any): Promise<string | undefined> => {
            const tokenState = getState().Authentication.get('token');

            if (!tokenState) {
                const authToken = await GoogleAuthService.refreshGoogleAuthentication(dispatch, getState);
                return authToken as string;
            } else {
                const googleTokenExpired: boolean = AuthenticationUtils.authenticationIsExpired(tokenState.expires_at);
                if (googleTokenExpired) {
                    const authToken = await GoogleAuthService.refreshGoogleAuthentication(dispatch, getState);
                    return authToken as string;
                }
                return GoogleAuthUtils.getAuthorizationFromToken(tokenState.token);
            }
        };
}
