import { replace } from 'connected-react-router';
import { addHours } from 'date-fns/esm';
import { UserAgentApplication } from 'msal';
import Redux from 'redux';
import { ConfigFactory } from '../../../../../configs/ApiConfig';
import { logger } from '../../../../../services/logger/logger';
import { ActiveDirectoryAuthResult } from '../../../Models/AuthenticationResponse';
import { AuthenticationActions } from '../Base/AuthenticationActions';
import { AuthenticationUtils } from '../Base/AuthenticationUtils';

import { ActiveDirectoryAuthActions } from './ActiveDirectoryAuthActions';
import { ActiveDirectoryAuthUtils } from './ActiveDirectoryAuthUtils';

let myMSALObj: any = null;

const acquireTokenRedirectCallBack = (errorDesc: any, token: any, error: any, tokenType: any) => {
    if (tokenType === 'access_token') {
        //* callMSGraph(applicationConfig.graphEndpoint, token, graphAPICallback);
        logger.log('got callback ', tokenType);
    } else {
        logger.log('callback, token type is: ', tokenType);
    }
}

export const initMsalConfig = () => {
    myMSALObj = new UserAgentApplication(
        ConfigFactory.getActiveDirectoryConfig().clientID,
        ConfigFactory.getActiveDirectoryConfig().authority,
        acquireTokenRedirectCallBack,
        {
            storeAuthStateInCookie: true,
            cacheLocation: 'localStorage',
        }
    );
};

const acquireAccessToken = () =>
    myMSALObj
        .acquireTokenSilent(ConfigFactory.getActiveDirectoryConfig().graphScopes)
        .then((accessToken: string) => accessToken)
        .catch((silentError: any) => {
            // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure due to consent or interaction required ONLY
            if (
                silentError.indexOf('consent_required') !== -1 ||
                silentError.indexOf('interaction_required') !== -1 ||
                silentError.indexOf('login_required') !== -1
            ) {
                return myMSALObj.acquireTokenPopup(ConfigFactory.getActiveDirectoryConfig().graphScopes).then(
                    (accessToken: string) => Promise.resolve(accessToken),
                    (popupError: any) => Promise.reject(new Error(`could not retrieve access token with popup: ${popupError}`))
                );
            }
            return Promise.reject(new Error(`could not silently retrieve access token for auto login: ${silentError}`));
        });

export class ActiveDirectoryAuthService {
    public static loginAd = () => (dispatch: any) => {
        if (!myMSALObj) {
            return dispatch(ActiveDirectoryAuthService.loginFail(new Error('loginAd: myMSALObj is not initialized, cannot login')));
        }

        return myMSALObj
            .loginPopup(ConfigFactory.getActiveDirectoryConfig().graphScopes, 'prompt=select_account')
            .then(acquireAccessToken)
            .then((ad_access_token: string) => {
                const expiration_date = addHours(new Date(), 1).toISOString();
                return dispatch(ActiveDirectoryAuthService.loginSuccess({ ad_access_token, expiration_date }));
            })
            .catch((err: any) => dispatch(ActiveDirectoryAuthService.loginFail(err)));
    };

    public static loginSuccess = ({ ad_access_token, expiration_date }: ActiveDirectoryAuthResult) => (dispatch: any) => {
        dispatch(AuthenticationActions.setADLoginMethod());
        dispatch(ActiveDirectoryAuthActions.loginAdSuccess({ ad_access_token, expiration_date }));
        dispatch(AuthenticationActions.setInitialized(true));

        return Promise.resolve({ ad_access_token, expiration_date });
    };

    public static loginFail = (error: any) => (dispatch: any): any => {
        dispatch(AuthenticationActions.setInitialized(true));
        dispatch(AuthenticationActions.setAuthenticated(false));
        dispatch(ActiveDirectoryAuthActions.loginAdFail(error));

        logger.error(error);
        return Promise.resolve({ isError: true, error: new Error(`ad login fail: ${error}`) });
    };

    public static refreshAd = (forceRefresh?: boolean) => (dispatch: any, getState: any) => {
        const prevPathname = getState().router.location.pathname;
        const existing_ad_access_token = getState().Authentication.get('ad_access_token');
        const existing_expiration_date = getState().Authentication.get('expiration_date');

        if (
            !forceRefresh &&
            existing_ad_access_token &&
            !AuthenticationUtils.authenticationIsExpired(existing_expiration_date)
        ) {
            return dispatch(ActiveDirectoryAuthService.refreshSuccess(
                { ad_access_token: existing_ad_access_token, expiration_date: existing_expiration_date },
                prevPathname
            ));
        }

        dispatch(AuthenticationActions.setInitialized(false));

        dispatch(replace('/login'));

        return myMSALObj
            .acquireTokenSilent(ConfigFactory.getActiveDirectoryConfig().graphScopes)
            .then((ad_access_token: string) => {
                const expiration_date = addHours(new Date(), 1).toISOString();

                return dispatch(ActiveDirectoryAuthService.refreshSuccess({ ad_access_token, expiration_date }, prevPathname));
            })
            .catch((err: any) =>
                dispatch(ActiveDirectoryAuthService.refreshFail(new Error(`could not silently retrieve access token for auto refresh: ${err}`)))
            );
    };

    public static refreshSuccess = ({ ad_access_token, expiration_date }: ActiveDirectoryAuthResult, prevPathname: string) => (dispatch: Redux.Dispatch) => {
        dispatch(ActiveDirectoryAuthActions.refreshAdSuccess({ ad_access_token, expiration_date }));
        dispatch(AuthenticationActions.setAuthenticated(true));
        dispatch(AuthenticationActions.setInitialized(true));

        if (prevPathname !== '/login') dispatch(replace(prevPathname));

        return Promise.resolve({ ad_access_token, expiration_date });
    };

    public static refreshFail = (error: any) => (dispatch: Redux.Dispatch) => {
        dispatch(AuthenticationActions.setInitialized(true));
        dispatch(AuthenticationActions.setAuthenticated(false));
        dispatch(ActiveDirectoryAuthActions.refreshAdFail(error));

        logger.error(error);

        return Promise.resolve({
            isError: true,
            error: new Error(`ad refresh fail: ${error}`),
        });
    };

    public static getAuthorization = () => (dispatch: any, getState: any) => {
        const ad_access_token = getState().Authentication.get('ad_access_token');
        const expiration_date = getState().Authentication.get('expiration_date');

        if (!ad_access_token || AuthenticationUtils.authenticationIsExpired(expiration_date)) {
            return dispatch(ActiveDirectoryAuthService.refreshAd()).then((res: any) => {
                if (res.isError) return Promise.reject(res.error);
                return Promise.resolve(res.ad_access_token);
            });
        }

        const authorization = ActiveDirectoryAuthUtils.getAuthorizationFromToken(ad_access_token);

        return Promise.resolve(authorization);
    };

    public static logoutAd = (reason?: string) => (dispatch: Redux.Dispatch) => {
        myMSALObj.clearCache();
        dispatch(AuthenticationActions.logout(reason));
    };
}