import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useIdelinkTheme } from '../../xlink/configs/IdeLinkTheme';
import { NavbarModuleItem } from '../../lib/IdeLink.Web.Common/src/components/NavBar/components/NavBarItem/NavBarItem';
import { RouteItem } from '../../lib/IdeLink.Web.Common/src/types/RouteItem';
import { PrivateRoute } from '../../routers/PrivateRoute';
import { EventDetailsPageLoading } from './Pages/EventDetailsPage/EventDetailsPageLoading';
import { EventsListPageLoading } from './Pages/EventsListPage/EventsListPageLoading';

export class EventsModule {
    public static EVENTS_MODULE_CODE = 'Events';

    public static EVENT_LIST_ROUTE = '/events';
    public static EVENT_DETAILS_ROUTE = '/events/:eventId';
    public static GET_EVENT_DETAILS_ROUTE = (id: string) => `/events/${id}`;

    private static getEventListRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: EventsModule.EVENT_LIST_ROUTE,
            component: EventsListPageLoading,
        },
    });
    private static getEventDetailsRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: EventsModule.EVENT_DETAILS_ROUTE,
            component: EventDetailsPageLoading,
        },
    });

    public static getEventsNavbarModuleItem = (): NavbarModuleItem => ({
        label: <FormattedMessage id="events.title" />,
        route: EventsModule.EVENT_LIST_ROUTE,
        icon: useIdelinkTheme().events.iconWhite,
        onMouseOver: () => EventsModule.preloadEvents(),
    });

    private static preloadEvents = () => {
        EventsListPageLoading.preload();
        EventDetailsPageLoading.preload();
    };

    public static getEventRoutes = (): Array<RouteItem> => [
        EventsModule.getEventListRoute(),
        EventsModule.getEventDetailsRoute(),
    ];
}
