import Loadable from 'react-loadable';

import { AsyncPageLoading } from '../../scenes/AsyncPageLoading';

const DELAY_MS = 200;

export const withAsyncLoading = (asyncPageImport) =>
    Loadable({
        loader: asyncPageImport,
        loading: AsyncPageLoading,
        delay: DELAY_MS,
    });
