import { formatDateCustom } from "../../lib/IdeLink.Web.Common/src/utils/dateUtils";

export const frGondoles = {
    'gondoles.title': 'Gondoles',
    'gondoles.label': 'Gondole',
    'gondoles.list.emptyLabel': "Il n'y a actuellement aucun questionnaire de positionnement gondole disponible",

    'gondoles.details.title': 'Détails Gondole',
    'gondoles.details.notFound': 'Impossible de trouver le questionnaire de prositionnement gondole, réessayez dans quelques minutes.',

    'gondoles.questions.metrics.preconisation': 'Recommandation de positionnement de métrage',
    'gondoles.questions.metrics.currentLength': 'Métrage actuel',
    'gondoles.questions.metrics.caLastYear': 'CA année n-1',
    'gondoles.questions.metrics.shopRank': "Classement du centre au sein de l'enseigne",
    'gondoles.questions.additionnalInfos': 'Information supplémentaire : {infos}',

    //* FEED GONDOLE
    Feed_Gondolas_FEED_PUBLISH_EVENT: ({ EndDate }: any) => `Votre avis compte ! Un nouveau questionnaire de positionnement gondole est publié. Clôture des réponses le ${formatDateCustom(EndDate, 'dd/MM/yyyy', '-')}.`,
    Feed_Gondolas_FEED_PUBLISH_LAST_DAY_EVENT: () => `Votre avis compte ! Un nouveau questionnaire de positionnement gondole est publié. Clôture des réponses dans quelques heures.`,
    Feed_Gondolas_FEED_REMINDER_EVENT: () => `Vous n'avez plus beaucoup de temps pour donner votre réponse, après il sera trop tard.`,
    Feed_Gondolas_FEED_REMINDER_LAST_DAY_EVENT: () => `Vous n'avez plus que quelques heures pour donner votre réponse, après il sera trop tard.`,
    Feed_Gondolas_FEED_POSTPONE_EVENT: ({ RemainingDays }: any) => `Un peu de répit, la date d'échéance a changé, il vous reste ${RemainingDays} jours pour répondre.`,
    Feed_Gondolas_FEED_POSTPONE_LAST_DAY_EVENT: () => `Un peu de répit, la date d'échéance a changé, il vous reste quelques heures pour répondre.`,
    Feed_Gondolas_FEED_PPREPONE_EVENT: ({ RemainingDays }: any) => `Tout s'accélère, la date d'échéance a changé, il ne vous reste plus que ${RemainingDays} jours pour répondre.`,
    Feed_Gondolas_FEED_PREPONE_LAST_DAY_EVENT: () => `Tout s'accélère, la date d'échéance a changé, il ne vous reste plus que quelques heures pour répondre.`,
    Feed_Gondolas_FEED_TOO_LATE_EVENT: () => `C'est dommage, vous n'avez pas répondu à temps. On fera sans votre avis mais on compte sur vous la prochaine fois.`,
    Feed_Gondolas_FEED_REPLY_SAVED_EVENT: () => `Nous avons enregistré vos premières réponses. N'oubliez pas de finaliser et de valider l'envoi.`,
    Feed_Gondolas_FEED_REMINDER_SENT_EVENT: ({ RemainingDays }: any) => `Plus que ${RemainingDays} jours pour donner votre avis, après il sera trop tard.`,
    Feed_Gondolas_FEED_REMINDER_LAST_DAY_SENT_EVENT: () => `Plus que quelques heures pour donner votre avis, après il sera trop tard.`,
    Feed_Gondolas_FEED_REPLY_VALIDATED_EVENT: () => `Good job ! Nous avons bien reçu votre réponse.`,
    Feed_Gondolas_FEED_REPLY_INVALIDATED_EVENT: ({ RemainingDays }: any) => `Un moment de doute ? Vous avez annulé votre réponse, vous avez ${RemainingDays} jours pour valider votre réponse.`,
    Feed_Gondolas_FEED_REPLY_INVALIDATED_LAST_DAY_EVENT: () => `Un moment de doute ? Vous avez annulé votre réponse, vous n'avez plus que quelques heures pour valider votre réponse.`,
};
