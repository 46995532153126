import React from 'react';

import { useAuthenticationState } from '../../../Hooks/useAppState';
import { Loading } from '../../../scenes/Loading';

export interface AuthGateProps {
    minDelay?: number;
    children: React.ReactNode;
}

export const AuthGate: React.FC<AuthGateProps> = ({ minDelay = 1000, children }: AuthGateProps) => {
    const authInitialized = useAuthenticationState().get('initialized');

    // const [passedMinDelay, setPassedMinDelay] = React.useState(false);

    // React.useEffect(() => {
    //     setTimeout(() => setPassedMinDelay(true), minDelay);
    // }, []);

    // if (!authInitialized || !passedMinDelay) {
    //     return <Loading />;
    // }

    if (!authInitialized) {
        return <Loading />;
    }

    return <React.Fragment>{children}</React.Fragment>;
};
