
export interface ItemsGroupBy<Key, Item> {
    readonly keys: Array<Key>,
    readonly map: Map<Key, Array<Item>>
}

export class ListUtils {
    //* Key like string, K value {id : string, 'other': any }
    public static groupBy = <Key, Item extends {}>(
        items: Array<Item> = [],
        keyGetter: (item: Item) => Key
    ): ItemsGroupBy<Key, Item> => {

        const keys: Array<Key> = [];
        const map = new Map<Key, Array<Item>>();

        items.forEach((item: Item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                keys.push(key);
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return { keys, map };
    };

    public static toDictionary = <Key, Item>(items: Array<Item>, keyGetter: (item: Item) => Key): ReadonlyMap<Key, Item> => {
        const map = new Map<Key, Item>();
        items.forEach((item: Item) => {
            const key: Key = keyGetter(item);
            map.set(key, item);
        });
        return map;
    }

    public static reorder = <T>(list: Array<T>, startIndex: number, endIndex: number): Array<T> => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    /**
   * Checks if the arrrays are equal. The arrays must be arrays of objects with 'id'
   * @param {Array} a - the first array of objects with key `id`
   * @param {Array} b - the second array of objects with key `id`
   * @returns {Boolean} if the arrays contain exactly the same objects and nothing else that the other doesn't have
   */
    public static areArraysEqual = (a: any, b: any): boolean => {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        return a.every((aItem: any) => b.find((bItem: any) => bItem.id === aItem.id));
    }

    public static sum = <T>(items: Array<T> = [], getValue: (item: T) => number): number => {
        return items.reduce((previousValue, currentItem) => previousValue + getValue(currentItem), 0);
    }
}