import './ShopSelection.scss';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ChevronRight from '@material-ui/icons/ChevronRight';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { withFactory } from '../../../lib/IdeLink.Web.Common/src/Factory';
import { useAppState } from '../../../lib/IdeLink.Web.Common/src/Hooks/useAppState';
import { ShopForUserWithRoles } from '../../../models/ShopForUser';
import { MyShopsActions } from '../../../services/myShops/MyShopsActions';

export interface ShopSelectionProps {
    readonly disabled?: boolean;
}

export const ShopSelection = ({ disabled = false }: ShopSelectionProps) => {
    const myShopsState = useAppState().MyShops;
    const shops: Array<ShopForUserWithRoles> = myShopsState.get('my_shops') || [];
    const selectedShop: ShopForUserWithRoles | undefined = (myShopsState.get('selectedShop') as unknown) as
        | ShopForUserWithRoles
        | undefined;

    const [anchorEl, setAnchorEl] = React.useState();

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(undefined);

    const handleShopClick = (shop: ShopForUserWithRoles) => {
        handleClose();
        setSelectedShop(shop);
    };

    const dispatch = useDispatch();
    const setSelectedShop = (shop: ShopForUserWithRoles) => dispatch(MyShopsActions.setSelectedShop(shop));

    return (
        <div className="shop-selection-container">
            {shops.length === 0 && (
                <div className="shop-selection-none">
                    <FormattedMessage id="commons.shops.none" />
                </div>
            )}
            {shops.length > 0 && (
                <ClickAwayListener onClickAway={handleClose}>
                    <div className="shop-selection-content-container">
                        <Button
                            fullWidth
                            aria-owns={anchorEl ? 'navbar-shop-selection' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            classes={{
                                root: 'shop-selection-button-root',
                                label: 'shop-selection-button-label',
                            }}
                            disabled={disabled}
                        >
                            {selectedShop && selectedShop.name}
                            {!selectedShop && <FormattedMessage id="commons.shops.noSelection" />}
                            {!disabled && <ChevronRight className="shop-selection-chevron-icon" />}
                        </Button>
                        <Menu
                            id="navbar-shop-selection"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {[...shops]
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(shop => (
                                    <MenuItem
                                        key={shop.id}
                                        onClick={() => handleShopClick(shop)}
                                        selected={selectedShop && selectedShop.id === shop.id}
                                    >
                                        {shop.name}
                                    </MenuItem>
                                ))}
                        </Menu>
                    </div>
                </ClickAwayListener>
            )}
        </div>
    );
};

export const ShopSelectionFactory = withFactory(ShopSelection);
