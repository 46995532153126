import './AppRouter.scss';

import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RouteItem } from '../lib/IdeLink.Web.Common/src/types/RouteItem';

export interface RouterProps {
    routerHistory: any;
    routes: Array<RouteItem>;
    defaultRoute?: string;
}
export const AppRouter = ({ routerHistory, routes = [], defaultRoute = '/' }: RouterProps) => {
    return (
        <ConnectedRouter history={routerHistory}>
            <div className="AppRouter-root">
                <Switch>
                    {routes.map(({ render: Render, props }: RouteItem) => {
                        const { exact = true, ...rest } = props;
                        return <Render {...rest} key={`${props.path}_${exact ? 'exact' : ''}`} exact={exact} />;
                    })}
                    <Route path="*">
                        <Redirect to={defaultRoute} />
                    </Route>
                </Switch>
            </div>
        </ConnectedRouter>
    );
};
