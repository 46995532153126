export class FluizyConstants {

    public static FLUIZY_AUTH_STORAGE_KEY = 'IdeLink-Web-FrontOffice-root';
    //public static FLUIZY_AUTH_STORAGE_KEY = 'fluizy-front-auth';

    public static OFFERS_MODULE_CODE = 'FluizyGoodDeals';
    public static OPERATIONS_MODULE_CODE = 'FluizyOperations';

    public static DIGITAL_MARKETINGS_MODULE_CODE = 'FluizyDigitalMarketings';
    public static PARTNERSHIPS_MODULE_CODE = 'FluizyPartnerships';
    public static LEAFLETS_MODULE_CODE = 'FluizyLeaflets';
}