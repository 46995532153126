import './header.scss';

import React from 'react';

import { withFactory } from '../../../../../Factory';

export interface ConnectHeaderProps {
    className?: string;
    children?: React.ReactNode;
}

const ConnectHeader = ({ children, className }: ConnectHeaderProps) => (
    <div className={`header-bar ${className}`}>{children}</div>
);

const ConnectHeaderFactory = withFactory(ConnectHeader);

export { ConnectHeader, ConnectHeaderFactory };
