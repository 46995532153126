import { Map } from 'immutable';
import Redux from 'redux';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { ActiveDirectoryAuthActions } from '../ActiveDirectory/ActiveDirectoryAuthActions';
import { LoginMethod } from '../AuthenticationConstants';
import { AuthenticationStateData } from '../AuthenticationState';
import { EmailAuthActions } from '../Email/EmailAuthActions';
import { GoogleAuthActions } from '../Google/GoogleAuthActions';
import { ImpersonateAuthActions } from '../Impersonate/ImpersonateAuthActions';

import { AuthenticationActions } from './AuthenticationActions';

const initialState = Map<keyof AuthenticationStateData, any>({
    isAuthenticated: false,
    initialized: false,

    loginMethod: LoginMethod.LOGIN_METHOD_GOOGLE,
    error: null,

    //* GOOGLE | GoogleToken : { token: string; expires_in: number }
    token: null,
    code: null,

    //* EMAIL
    access_token: '',
    refresh_token: '',
    expires_in: '', // seconds, from when the token was acquired
    token_type: '',
    expiration_date: 0, // ms since epoch

    //* IMPERSONATE
    impersonate_token: '',
});

export const AuthenticationReducer = (state: Map<keyof AuthenticationStateData, any> = initialState, action: Redux.AnyAction) => {

    switch (action.type) {
        case REHYDRATE:
            return Map.isMap(state) ? state : Map(state);

        //#region //* AuthenticationActions

        case AuthenticationActions.LOG_OUT:
            return initialState
                .set('loginMethod', state.get('loginMethod')) // return initial state but preserve login method
                .set('initialized', true);
        case AuthenticationActions.SET_INITIALIZED:
            return state.set('initialized', action.payload.initialized);
        case AuthenticationActions.SET_LOGIN_IN_PRGRESSS:
            return state.set('loginInProgress', action.payload.loginInProgress);
        case AuthenticationActions.SET_AUTHENTICATED:
            return state.set('isAuthenticated', action.payload.authenticated);
        case AuthenticationActions.REMOVE_ERROR:
            return state.set('error', false);
        case AuthenticationActions.SET_LOGIN_METHOD:
            return state.set('loginMethod', action.payload.loginMethod);

        //#endregion

        //#region  //* GOOGLE

        case GoogleAuthActions.SET_TOKEN:
            return state
                .set('token', action.payload.credentials)
                .set('error', false)
                .set('initialized', true);
        case GoogleAuthActions.SET_REFRESH_TOKEN:
            return state
                .set('refresh_token', action.payload)
                .set('error', false)
                .set('initialized', true);

        //#endregion

        //#region  //* EMAIL

        case EmailAuthActions.LOGIN_EMAIL_SUCCESS:
        case EmailAuthActions.REFRESH_EMAIL_SUCCESS:
            return state
                .set('access_token', action.payload.access_token)
                .set('refresh_token', action.payload.refresh_token)
                .set('expires_in', action.payload.expires_in)
                .set('token_type', action.payload.token_type)
                .set('expiration_date', action.payload.expiration_date)
                .set('error', false)
                .set('initialized', true);

        case EmailAuthActions.LOGIN_EMAIL_FAIL:
        case EmailAuthActions.REFRESH_EMAIL_FAIL:
            //* return initial state but preserve login method
            //* return initial state (logged out) but preserve login method & set initialized
            return initialState
                .set('loginMethod', state.get('loginMethod'))
                .set('error', action.payload.error)
                .set('isAuthenticated', false)
                .set('initialized', true);

        //#endregion

        //#region  //* ACTIVE DIRECTORY

        case ActiveDirectoryAuthActions.LOGIN_AD_SUCCESS:
        case ActiveDirectoryAuthActions.REFRESH_AD_SUCCESS:
            return state
                .set('ad_access_token', action.payload.ad_access_token)
                .set('expiration_date', action.payload.expiration_date)
                .set('error', false)
                .set('initialized', true);

        case ActiveDirectoryAuthActions.LOGIN_AD_FAIL:
        case ActiveDirectoryAuthActions.REFRESH_AD_FAIL:
            //* return initial state but preserve login method
            //* return initial state (logged out) but preserve login method & set initialized
            return initialState
                .set('loginMethod', state.get('loginMethod'))
                .set('error', action.payload.error)
                .set('isAuthenticated', false)
                .set('initialized', true);

        //#endregion

        //#region //* IMPERSONATE

        case ImpersonateAuthActions.LOGIN_IMPERSONATE_SUCCESS:
        case ImpersonateAuthActions.REFRESH_IMPERSONATE_SUCCES:
            return state
                .set('impersonate_token', action.payload.impersonate_token)
                .set('error', false)
                .set('initialized', true);

        case ImpersonateAuthActions.LOGIN_IMPERSONATE_FAIL:
        case ImpersonateAuthActions.REFRESH_IMPERSONATE_FAIL:
            return state
                .set('loginMethod', state.get('loginMethod'))
                .set('error', action.payload.error)
                .set('isAuthenticated', false)
                .set('initialized', true);

        //#endregion

        default:
            return Map.isMap(state) ? state : Map(state);
    }
};

