
const OAUTH2_BASE_URL: string = '/api/v1/oauth2'

export class AuthenticationUrls {
    public static LOGIN = `${OAUTH2_BASE_URL}/login`
    public static REFRESH = `${OAUTH2_BASE_URL}/refresh`

    public static PASSWORD_FORGOTTEN = `${OAUTH2_BASE_URL}/passwords/forgotten`
    public static PASSWORD_RESET = `${OAUTH2_BASE_URL}/passwords/reset`;
    public static PASSWORD_UPDATE = `${OAUTH2_BASE_URL}/passwords/update`;

    public static GOOGLE_LOGIN = `api/v1/signin/login`;
    public static GOOGLE_REFRESH = `api/v1/signin/refresh`;

}