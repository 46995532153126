import React from 'react';
import { FormattedMessage } from 'react-intl';

import { NavbarModuleItem } from '../../lib/IdeLink.Web.Common/src/components/NavBar/components/NavBarItem/NavBarItem';
import { PrivateRoute } from '../../routers/PrivateRoute';
import { RouteItem } from '../../lib/IdeLink.Web.Common/src/types/RouteItem';
import ic_gondole_white from './assets/ic_gondole_white.svg';
import { GondoleDetailsPageLoading } from './Pages/GondoleDetailsPage/GondoleDetailsPageLoading';
import { GondoleListPageLoading } from './Pages/GondoleListPage/GondoleListPageLoading';

export class GondolesModule {
    public static GONDOLES_MODULE_CODE = 'Gondolas';

    public static GONDOLE_LIST = '/gondoles';
    public static GONDOLE_DETAILS = '/gondoles/:gondoleId';
    public static GET_GONDOLE_DETAILS_ROUTE = (id: string) => `/gondoles/${id}`;

    public static getGondoleListRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: GondolesModule.GONDOLE_LIST,
            component: GondoleListPageLoading,
        },
    });

    public static getGondoleDetailsRoute = () => ({
        render: PrivateRoute,
        props: {
            path: GondolesModule.GONDOLE_DETAILS,
            component: GondoleDetailsPageLoading,
        },
    });

    public static getGondolesRoutes = (): Array<RouteItem> => [
        GondolesModule.getGondoleListRoute(),
        GondolesModule.getGondoleDetailsRoute(),
    ];

    public static getGondoleNavbarModuleItem = (): NavbarModuleItem => ({
        label: <FormattedMessage id="gondoles.title" />,
        route: GondolesModule.GONDOLE_LIST,
        icon: ic_gondole_white,
        onMouseOver: () => GondolesModule.preloadGondoles(),
    });

    public static preloadGondoles = () => {
        GondoleListPageLoading.preload();
        GondoleDetailsPageLoading.preload();
    };
}
