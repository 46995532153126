import './navbar.scss';

import React from 'react';
import { useDispatch } from 'react-redux';

import { withFactory } from '../../Factory';
import { AppActions } from '../../redux/AppActions';
import { LogoFactory } from '../Logo';
import { NavBarCategory, NavBarCategoryItem } from './components';
import { NavbarModuleItem } from './components/NavBarItem/NavBarItem';

export const NAVBAR_C_LOCATION = 'NavBar';

export interface NavBarProps {
    readonly cLocation?: string;
    readonly titleImg?: string;
    readonly content?: Array<NavBarCategoryItem>;
    readonly bottomContent?: React.ReactNode;
    readonly isElementSelected: (item: NavbarModuleItem) => boolean;

    readonly onEssentialReloadHandled?: () => void;
    readonly classes?: {
        root?: string;
        title?: string;
    };
}

export const NavbarBase = ({
    cLocation = '/',
    isElementSelected,
    content = undefined,
    bottomContent = undefined,
    onEssentialReloadHandled = undefined,
    titleImg,
    classes,
}: NavBarProps) => {
    const { root: rootClasses = '', title: titleClasses = '' } = classes || {};

    const dispatch = useDispatch();
    const triggerEssentialReload = (redirectMainPage?: boolean): Promise<boolean> => {
        dispatch(AppActions.triggerEssentialReload(redirectMainPage));
        return Promise.resolve(true);
    };

    return (
        <div className={`navbar-container ${rootClasses}`}>
            <div
                onClick={() =>
                    triggerEssentialReload(true).then(() => {
                        if (onEssentialReloadHandled) onEssentialReloadHandled();
                    })
                }
                className={`title-container ${titleClasses}`}
            >
                <LogoFactory cLocation={`${cLocation}${NAVBAR_C_LOCATION}/`} alt={titleImg || 'title logo'} />
            </div>
            {(content || []).map((category, index) => (
                <NavBarCategory category={category} isElementSelected={isElementSelected} key={index} />
            ))}
            {bottomContent}
        </div>
    );
};
export const NavBarFactory = withFactory(NavbarBase);
