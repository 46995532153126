export const esOffers = {
    'offer.details.button.quantities_recommendation.apply': 'Aplicar todas las recomendaciones',
    'offer.details.delivery_week.no_info': 'No hay información disponible en la semana de entrega',
    'offer.details.notFound': 'No se encontró ningún oferta',
    'offer.details.product.box.button.quantity_recommendation.apply': 'Aplicar el preco: x {quantity_recommandation}',
    'offer.details.product.box.label.accept_tg': '¿Quiero recibir este TG?',
    'offer.details.product.box.label.recommendation_tg':
        '{value, plural, =0 {preco: no} =1 {preco: sí} other {preco: -}}',
    'offer.details.product.box.quantity': 'Monto',
    'offer.details.product.box.tg.label': '{value, plural, =0 {no} =1 {sí} other { -}}',
    'offer.details.product.dialog.additionnalInfos': 'Información adicional: {info}',
    'offer.details.product.dialog.bought_price': 'Precio compra',
    'offer.details.product.dialog.empty_price': '-',
    'offer.details.product.dialog.normal_price': 'Precio normal',
    'offer.details.product.dialog.odr_price': 'Después de ODR',
    'offer.details.product.dialog.price': 'Precio promocional',
    'offer.details.product.dialog.product_highlighted_4x3': 'Producto resaltado en 4x3',
    'offer.details.product.dialog.product_highlighted_80x120': 'Producto presentado en 80x120',
    'offer.details.product.dialog.quantities.title': 'Número de producto',
    'offer.details.product.dialog.quantity_buy_by_multiple_of': 'Múltiple de',
    'offer.details.product.dialog.quantity_maximum': 'NB máximo',
    'offer.details.product.dialog.quantity_minimum': 'Nb mini',
    'offer.details.product.dialog.reference': 'Ref. {reference}',
    'offer.details.product.dialog.seen_in_prospectus': 'Presente en el folleto',
    'offer.details.product.dialog.sell_price': 'PVP',
    'offer.details.product.item.quantity_buy_by_multiple_of': 'Múltiplo de',
    'offer.details.product.item.quantity_maximum': 'Cantidad max',
    'offer.details.product.item.quantity_minimum': 'Cantidad min',
    'offer.details.product.last_3_orders_for_this_product': '3 Últimos pedidos de este producto',
    'offer.details.product.seeOrderedProducts': 'Vea los productos pedidos <12 últimos meses: {Count}',
    'offer.last_3_orders.table.header.name': 'Planes de venta',
    'offer.last_3_orders.table.header.quantity': 'Cantidad',
    'offer.list.emptyLabel': 'No hay ofertas de momento',
    'offer.product.category.Box': 'Caja',
    'offer.product.category.Palette': 'Palet',
    'offer.product.category.Product': 'Producto',
    'offer.product.category.TG': 'Cabecera',
    'offer.product.error.quantity.maximum.description':
        'La cantidad ingresada para el producto {Title} ({Code}) no es válida. La cantidad máxima es {quantity}.',
    'offer.product.error.quantity.maximum.title': 'Cantidad',
    'offer.product.error.quantity.maximum.toast':
        'La cantidad incautada no es válida. La cantidad máxima es {quantity}',
    'offer.product.error.quantity.minimum.description':
        'La cantidad ingresada para el producto {Title} ({Code}) no es válida. La cantidad mínima es {quantity}.',
    'offer.product.error.quantity.minimum.title': 'Cantidad',
    'offer.product.error.quantity.minimum.toast':
        'La cantidad incautada no es válida. La cantidad mínima es {quantity}',
    'offer.product.error.quantity.missing.description':
        'Debe ingresar una cantidad para el producto {title} ({código}) no es válida',
    'offer.product.error.quantity.missing.title': 'Cantidad no informada',
    'offer.product.error.quantity.missing.toast': 'Debes ingresar una cantidad',
    'offer.product.error.quantity.multiple_of.description':
        'La cantidad ingresada para el producto {Title} ({Code}) no es válida. La cantidad debe ser un múltiplo de {quantity}.',
    'offer.product.error.quantity.multiple_of.title': 'Cantidad',
    'offer.product.error.quantity.multiple_of.toast':
        'La cantidad incautada no es válida. La cantidad debe ser un múltiplo de {quantity}',
    'offer.validation.box.total_price.title': 'Precio total',
    'offer.validation.dialog.total_price.label': 'Total pedido:',
    'offers.navbar': 'Ofertas',
    'offers.title': 'Ofertas',
    'packaged.order.details.title': 'Detalles del oferta',
    'packaged.order.details.validate': 'Validar el compromiso',
    'packaged.order.feed.total_price': 'Cantidad pedida:',
    'packaged.order.label': 'Oferta',
};
