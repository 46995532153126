import React from 'react';

import leftImg from '../../../assets/images/bg-img.jpg';
import {
    AuthBase,
    AuthBaseFactory,
    AuthBaseProps,
} from '../../../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Pages/components/AuthBase/AuthBase';
import {
    ConnectFactory,
    ConnectProps,
} from '../../../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Pages/components/Connect/Connect';
import { FluizyConnect } from './FluizyConnect';

const FluizyAuthBase = (props: AuthBaseProps) => <AuthBase {...props} leftImg={leftImg} />;

export const overrideFluizyAuthBase = () => {
    AuthBaseFactory.register('**', FluizyAuthBase);
    ConnectFactory.register('**', (props: ConnectProps) => <FluizyConnect {...props} />);
};
