import './loading.scss';

import React from 'react';

import { Loader } from '../../components/Loader';
import { LogoFactory } from '../../components/Logo';

const C_LOCATION = 'LoadingScene';

export type LoadingSceneProps = { pastDelay?: boolean };

export const Loading = ({ pastDelay = true }: LoadingSceneProps) => {
    if (!pastDelay) return null;

    return (
        <div className="loading-container">
            <div className="page-loading-content-container">
                <LogoFactory cLocation={`/${C_LOCATION}/`} />
                <Loader
                    position="center"
                    classes={{
                        container: 'page-loading-loader-container',
                        loader: 'page-loading-loader',
                    }}
                />
            </div>
        </div>
    );
};

Loading.C_LOCATION = C_LOCATION;
