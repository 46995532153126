import './OffersTheme.scss';

import { IdelinkModuleTheme } from '../../../xlink/configs/IdeLinkTheme';
import defaultImgPackagedOrder from '../assets/default-img-bonplan-square.svg';
import defaultImgPackagedOrderProduct from '../assets/default-img-product-square.svg';
import emptyIconPackagedOrder from '../assets/ic-offer-empty.svg';
import iconColorPackagedOrder from '../assets/offer-color.svg';
import iconWhite from '../assets/offer.svg';

export const OffersTheme: IdelinkModuleTheme = {
    className: 'idelink-theme il-theme-packaged-orders',
    backgroundColor: '#26668c',
    feedImage: defaultImgPackagedOrder,
    productImage: defaultImgPackagedOrderProduct,
    emptyIcon: emptyIconPackagedOrder,
    iconColor: iconColorPackagedOrder,
    iconWhite,
};
