import { push } from 'connected-react-router';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';


export class AppActions {

    public static APP_ESSENTIAL_RELOAD = 'app/essential/reload'

    public static triggerEssentialReloadSimple = (): AnyAction => ({ type: AppActions.APP_ESSENTIAL_RELOAD });

    public static triggerEssentialReload = (redirectMainPage?: boolean) => (dispatch: Dispatch<AnyAction>): Promise<boolean> => {
        if (redirectMainPage) {
            dispatch(push('/'));
        }
        dispatch(AppActions.triggerEssentialReloadSimple());
        return Promise.resolve(true);
    };
}


