import './textField.scss';

import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import React, { CSSProperties, ChangeEvent } from 'react';

import { ErrorValidator } from '../../types/ErrorValidator';

const getInitialValue = ({
    value = '',
    disabled = false,
    emptyValue = '',
}: {
    value?: string | number;
    disabled?: boolean;
    emptyValue?: string;
}) => {
    if (value || value === 0) return value;
    return disabled ? emptyValue || 'Aucune donnée' : '';
};

export interface TextFieldComponentProps {
    label?: React.ReactNode;
    floatingLabelText?: React.ReactNode;
    hintText?: string;
    placeholder?: string;
    disableTopMargin?: boolean;
    multiLine?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    floatingLabelClassName?: string;
    valueClassName?: string;
    name?: string;
    value?: string | number | any;
    minValue?: number;
    maxValue?: number;
    step?: number;
    type?: string;
    emptyValue?: string; //* remove from textfieldProps
    checkValidation?: boolean; //* remove from textfieldProps
    disableUnderline?: boolean;
    /** Don't style the input as disabled. Useful to preverve text with label layout */
    noDisabledStyle?: boolean;
    multiline?: boolean;
    rowsMax?: number;
    tabIndex?: number;
    alignRight?: boolean;
    clickable?: boolean; //* Style the input & label as clickable
    autoFocus?: boolean;
    maxLength?: number;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onClick?: (event: React.MouseEvent) => void;
    onBlur?: (event: React.ChangeEvent) => void;

    validators?: Array<ErrorValidator>;
    className?: string;
    classes?: { root?: string; input?: string };
    styles?: { root?: CSSProperties; input?: CSSProperties };
    withLog?: boolean;
}

const TextFieldComponent = ({
    label,
    floatingLabelText,
    placeholder,
    hintText,
    disableTopMargin,
    multiLine,
    floatingLabelClassName,
    valueClassName,
    emptyValue = '', // remove from textfieldProps
    checkValidation = false, // remove from textfieldProps
    disableUnderline = false,
    fullWidth = false,
    multiline = false,
    tabIndex,
    alignRight,
    autoFocus,
    maxLength,
    type = 'Text',
    name,
    value,
    minValue,
    maxValue,
    onChange,
    onBlur,
    onClick,
    disabled = false,
    validators = [],
    clickable = false,
    noDisabledStyle = false,
    className,
    classes,
    styles,
    ...textfieldProps
}: TextFieldComponentProps) => {
    //#region //* CLASSES
    const { root: rootClasses = '', input: inputClasses = '' } = classes || {};
    //#endregion

    const validate = (value?: string): React.ReactNode => {
        let error: React.ReactNode = undefined;
        validators.some((validator: ErrorValidator) => {
            if (!validator.isValid(value)) {
                error = validator.errorText;
                return true;
            }
            return false;
        });
        return error;
    };

    const [stateValue, setStateValue] = React.useState<string | number | undefined>(
        getInitialValue({ value, disabled, emptyValue })
    );
    const [stateError, setStateError] = React.useState<React.ReactNode>(
        checkValidation ? validate(value as string) : ''
    );

    React.useEffect(() => {
        if (stateValue !== value) {
            setStateValue(value);
            setStateError(validate(value as string));
        }
        if (checkValidation) {
            setStateError(validate(stateValue as string));
        }
        if (value === 0) {
            setStateValue(0);
        } else if (!value && !disabled) {
            setStateValue('');
        } else if (!value && disabled) {
            setStateValue(emptyValue || 'Aucune donnée');
        }
    }, [value, checkValidation, disabled]);

    const handleChange = (e: any) => {
        const newValue: string = e.target.value;

        const valueGreatherThanLimit: boolean = maxValue !== undefined ? parseFloat(newValue) > maxValue : false;
        const valueLessThanLimit: boolean = minValue !== undefined ? minValue > parseFloat(newValue) : false;

        if (!(valueGreatherThanLimit || valueLessThanLimit)) {
            if (onChange) {
                onChange(e);
            }
            setStateValue(newValue);
            setStateError(validate(newValue));
        }
    };

    return (
        <TextField
            {...textfieldProps}
            multiline={multiLine}
            disabled={disabled}
            autoFocus={autoFocus}
            fullWidth={fullWidth}
            type={type}
            name={name}
            style={styles?.root}
            classes={{
                root: classNames('idelink-text-field-form-control-root', className, rootClasses, {
                    disableTopMargin,
                    alignRight,
                    noDisabledStyle,
                    clickable,
                }),
            }}
            helperText={stateError}
            error={Boolean(stateError)}
            label={floatingLabelText || label}
            placeholder={emptyValue || hintText || placeholder}
            value={stateValue}
            onChange={handleChange}
            onClick={onClick}
            onBlur={onBlur}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                classes: {
                    root: classNames('idelink-text-field-root', inputClasses, { noDisabledStyle }),
                    input: classNames('idelink-text-field-input', {
                        alignRight,
                        noDisabledStyle,
                        clickable,
                    }),
                    disabled: 'disabled',
                },
                inputProps: {
                    tabIndex,
                    maxLength,
                    min: minValue,
                    max: maxValue,
                    style: styles?.input,
                },
                disableUnderline,
            }}
        />
    );
};

export { TextFieldComponent as TextField, TextFieldComponent as CustomTextField };
