import './FluizyMessage.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MessageFactory } from '../../../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Pages/components/Message/Message';

const FluizyMessage = () => (
    <span className="FluizyMessage-label">
        <FormattedMessage id="login.welcome" />
    </span>
);

export const overrideFluizyMessage = () => MessageFactory.register('**', FluizyMessage);
