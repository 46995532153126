import { withFuncFactory } from '../lib/IdeLink.Web.Common/src/Factory';
import { OffersModule } from '../modules/Offers/OffersModule';

export type ApiRoutesMap = Record<string, string>;

export const GET_IDELINK_API_ROUTES_MAP = (): ApiRoutesMap => {
    return { [OffersModule.OFFERS_MODULE_CODE]: '/packaged-orders' }
};

const getDefaultApiRoutesMap = (): ApiRoutesMap => GET_IDELINK_API_ROUTES_MAP();

export const getApiRoutesMapFactory = withFuncFactory(getDefaultApiRoutesMap);
export const getApiRoute = (key: string) => getApiRoutesMapFactory()[key];
