import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useAppState } from '../../../../lib/IdeLink.Web.Common/src/Hooks/useAppState';
import { UserActions } from '../../../../lib/IdeLink.Web.Common/src/modules/User/UserActions';
import { logger } from '../../../../lib/IdeLink.Web.Common/src/services/logger/logger';
import { FrontUserApi } from '../../../../services/user/FrontUserApi';
import { CurrentFrontUserMe } from '../../../../services/user/UserMeResponse';

export const useUserInfoCardController = () => {
    const dispatch = useDispatch();

    const userInfo = useAppState().User.get('info') as CurrentFrontUserMe;

    const enableEmailNotifications = async (enable: boolean) => {
        try {
            const response = await FrontUserApi.enableEmailnotifications({ enable });
            dispatch(UserActions.setAuthenticatedUserInfo(response.data));
            toast.success(
                enable ? (
                    <FormattedMessage id="profile.email.notifications.enabled.success" />
                ) : (
                    <FormattedMessage id="profile.email.notifications.disabled.success" />
                ),
                { position: 'bottom-right' }
            );
        } catch (error) {
            logger.error('Error changing user notifications ', error);
        }
    };

    return { userInfo, enableEmailNotifications };
};
