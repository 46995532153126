import Redux from 'redux';
import { AppState } from '../../../../../app/AppState';
import { AuthenticationState } from '../AuthenticationState';
import { AuthenticationActions } from '../Base/AuthenticationActions';
import { AuthenticationUtils } from '../Base/AuthenticationUtils';
import { EmailApi } from './EmailApi';
import { EmailAuthActions } from './EmailAuthActions';
import { EmailAuthUtils } from './EmailAuthUtils';

export class EmailAuthService {
    public static logout = (reason?: string) => (dispatch: Redux.Dispatch) => dispatch(AuthenticationActions.logout(reason));

    // REFRESH

    /**
     ** Dispatches the success auth actions
     * @param {Object} result - The result of the successful refresh
     * @param {String} result.access_token
     * @param {String} result.refresh_token
     * @param {Number} result.expires_in
     * @param {String} result.token_type
     * @param {String} result.expiration_date: - ms since epoch
     * @returns {Promise<Object>} result - the result of the refresh, same as the login result
     */
    public static refreshSuccess = (result: any) => (dispatch: Redux.Dispatch) => {
        dispatch(EmailAuthActions.refreshEmailSuccess(result));
        // dispatch(AuthenticationActions.setAuthenticated(true));
        // dispatch(AuthenticationActions.setInitialized(true));
        return Promise.resolve(result);
    };

    /**
     ** Dispatches the email refresh failure
     * @throws {Promise<Error>} the error received
     */
    public static refreshFail = (error: any) => (dispatch: Redux.Dispatch) => {
        // dispatch(AuthenticationActions.setInitialized(true));
        // dispatch(AuthenticationActions.setAuthenticated(false));
        dispatch(EmailAuthActions.refreshEmailFailure(error));
        return Promise.reject(error);
    };

    /**
    ** If the access token is not expired, fires refreshSuccess right away with current state.
    ** Resets the initialized state (keeps the authenticated state) and then refreshes the access token
    * @param {Boolean} forceRefresh - If should refresh the tokens, even if the current ones are not expired
    * @returns {Promise<Object>} result - the result of the refresh, same as the login result OR an object
    ** with props `isError` and `err`
    * @throws {Promise<Error>} an Axios error if the refresh failed
    */
    public static refreshAuth = (forceRefresh?: boolean) => (dispatch: (action: any) => any, getState: () => AppState) => {
        const expiration_date = getState().Authentication.get('expiration_date');

        if (!AuthenticationUtils.authenticationIsExpired(expiration_date) && !forceRefresh) {
            const res = {
                access_token: getState().Authentication.get('access_token'),
                refresh_token: getState().Authentication.get('refresh_token'),
                expires_in: getState().Authentication.get('expires_in'),
                token_type: getState().Authentication.get('token_type'),
                expiration_date: getState().Authentication.get('expiration_date'),
            };
            return dispatch(EmailAuthService.refreshSuccess(res));
        }

        const refresh_token = getState().Authentication.get('refresh_token');

        if (!refresh_token) {
            const error = new Error('No refresh token found, cannot refresh and will logout');
            return dispatch(EmailAuthService.refreshFail(error))
                .catch((error: any) => ({ isError: true, err: error }));
        }

        dispatch(AuthenticationActions.setInitialized(false)); // is loading

        return EmailApi.refresh(refresh_token, dispatch)
            .then((res: any) => {
                const transformedAuth = EmailAuthUtils.transformAuth(res.data);
                return dispatch(EmailAuthService.refreshSuccess(transformedAuth));
            })
            .catch(err => {
                dispatch(EmailAuthService.refreshFail(err));
                return Promise.resolve({
                    isError: true,
                    error: new Error(`Could not refresh email auth ${err.toString()}`),
                });
            });
    };

    // LOGIN

    /**
     ** Dispatches the success auth actions     *
     * @param {Object} result - The result of the successful login
     * @param {String} result.access_token
     * @param {String} result.refersh_token
     * @param {Number} result.expires_in
     * @param {String} result.token_type
     * @param {String} result.expiration_date: - ms since epoch
     * @returns {Promise<Object>} result - the result received
     */
    public static loginSuccess = (auth: any) => (dispatch: Redux.Dispatch) => {
        dispatch(EmailAuthActions.loginEmailSuccess(auth));
        //dispatch(AuthenticationActions.setInitialized(true));
    };

    /**
     ** Dispatches the email login failure with the errror received
     * @param {Error} error
     * @throws {Promise<Error>} the error received
     */
    public static loginFail = (error: any) => (dispatch: Redux.Dispatch) => {
        // dispatch(AuthenticationActions.setInitialized(true));
        // dispatch(AuthenticationActions.setAuthenticated(false));
        dispatch(EmailAuthActions.loginEmailFailure(error));
    };

    /**
     * @param {String} email
     * @param {String} password
     * @returns {Promise<Object>} the full result of the login: access_token, refresh_token...
     * @throws {Promise<Error>} an Axios error if the request failed
     */
    public static login = (email: string, password: string) => {
        return async (dispatch: (action: any) => any) => {
            try {
                await dispatch(AuthenticationActions.setLoginInProgress(true));
                const response: any = await EmailApi.login(email, password, dispatch, false);
                await dispatch(AuthenticationActions.setUsernameLoginMethod());
                const transformedAuth = EmailAuthUtils.transformAuth(response.data);
                dispatch(EmailAuthService.loginSuccess(transformedAuth));
                return Promise.resolve(transformedAuth);
            } catch (error) {
                dispatch(EmailAuthService.loginFail(error));
                return Promise.reject(error);
            }
        }
    }


    // TOKEN

    /**
     ** Automatically refreshes the access token if necessary
     * @returns {Promise<String>} the full authorization header. Ex: `Bearer abcedf`
     * @throws {Promise<Error>} an Axios error if the refresh failed
     */
    public static getAuthorization = () => (dispatch: (action: any) => Promise<any>, getState: () => any) => {

        const authentication: AuthenticationState = getState().Authentication;
        const expiration_date = getState().Authentication.get('expiration_date');

        if (AuthenticationUtils.authenticationIsExpired(expiration_date)) {
            return dispatch(EmailAuthService.refreshAuth()).then((auth: any) => EmailAuthUtils.getAuthorizationFromToken(auth.access_token));
        }

        const access_token = authentication.get('access_token');
        const auth = EmailAuthUtils.getAuthorizationFromToken(access_token);

        return Promise.resolve(auth);
    };
}


