import './CommunicationsTheme.scss';

import iconWhiteCommunication from '../assets/marketing.svg';

import iconColorCommunication from '../assets/communication-color.svg';
import emptyIconCommunication from '../assets/ic-communication-empty.svg';
import defaultImgDigitalMarketing from '../assets/default-img-digital-marketing-square.svg';
import defaultImgLeaflet from '../assets/default-img-leaflets-square.svg';
import defaultImgPartnership from '../assets/default-img-partnerships-square.svg';

import { IdelinkModuleTheme } from '../../../xlink/configs/IdeLinkTheme';


export const CommunicationsTheme: IdelinkModuleTheme = {
    className: 'idelink-theme il-theme-communications',
    backgroundColor: '#fcb731', //? mango
    emptyIcon: emptyIconCommunication,
    iconColor: iconColorCommunication,
    iconWhite: iconWhiteCommunication,
};
export const DigitalmarketingsTheme: IdelinkModuleTheme = {
    className: 'idelink-theme il-theme-digitalmarketings',
    backgroundColor: '#002b6f', //? marine-blue
    feedImage: defaultImgDigitalMarketing,
    iconColor: iconColorCommunication,
    iconWhite: iconWhiteCommunication,
};
export const PartnershipsTheme: IdelinkModuleTheme = {
    className: 'idelink-theme il-theme-partnerships',
    backgroundColor: '#0e967d', //? blue-green
    feedImage: defaultImgPartnership,
    iconColor: iconColorCommunication,
    iconWhite: iconWhiteCommunication,
};
export const LeafletsTheme: IdelinkModuleTheme = {
    className: 'idelink-theme il-theme-leaflets',
    backgroundColor: '#f25940', //! pastel-red
    feedImage: defaultImgLeaflet,
    iconColor: iconColorCommunication,
    iconWhite: iconWhiteCommunication,
};
