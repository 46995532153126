import React from 'react'

//!include  script below in index.html
//! <script src="https://apis.google.com/js/platform.js" async defer></script>

export const useGoogleConnect = () => {

    const [isGoogleApiReady, setGoogleApiReady] = React.useState(false);
    const [delayCount, setDelayCount] = React.useState<number>(0);

    const getGoogleApi = () => (window as any).gapi;

    const delayLoadGapi = () => {
        const gapi = getGoogleApi();
        if (gapi) {
            gapi.load('auth2', () => {
                setGoogleApiReady(true);
            });
        } else {
            if (delayCount < 3) {
                setDelayCount(delayCount + 1);
                setTimeout(delayLoadGapi, 1000);
            }
        }
    }

    React.useEffect(delayLoadGapi, []);

    return { isGoogleApiReady }
}