import { TokenItem } from "../../../Models/AuthenticationResponse";


export class ImpersonateAuthActions {

    public static LOGIN_IMPERSONATE_SUCCESS = 'app/auth/impersonate/login/success';
    public static LOGIN_IMPERSONATE_FAIL = 'app/auth/impersonate/login/fail';
    public static REFRESH_IMPERSONATE_SUCCES = 'app/auth/impersonate/refresh/success';
    public static REFRESH_IMPERSONATE_FAIL = 'app/auth/impersonate/refresh/fail';

    public static loginImpersonateSuccess = (result: TokenItem) => ({
        type: ImpersonateAuthActions.LOGIN_IMPERSONATE_SUCCESS,
        payload: { impersonate_token: result.token },
    });

    public static loginImpersonateFail = (error: any) => ({
        type: ImpersonateAuthActions.LOGIN_IMPERSONATE_FAIL,
        payload: { error },
    });

    public static refreshImpersonateSuccess = (result: TokenItem) => ({
        type: ImpersonateAuthActions.REFRESH_IMPERSONATE_SUCCES,
        payload: { impersonate_token: result.token },
    });

    public static refreshImpersonateFail = (error: any) => ({
        type: ImpersonateAuthActions.REFRESH_IMPERSONATE_FAIL,
        payload: { error },
    });
}