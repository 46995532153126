import './FluizyLogo.scss';

import classNames from 'classnames';
import React from 'react';

import logo from '../../../assets/images/fluizy.svg';
import {
    LogoFactory,
    LogoProps,
} from '../../../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/components/Logo';

const FluizyLogo = ({ className = '' }: LogoProps) => (
    <img alt="Fluizy" className={classNames('FluizyLogo-login', className)} src={logo} />
);

export const overrideFluizyLogo = () => LogoFactory.register('**', FluizyLogo);
