import './loader.scss';
import 'loaders.css/loaders.min.css';

import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import Loader, { LoaderType } from 'react-loaders';

export interface LoaderProps {
    position?: 'left' | 'center' | 'right';
    type?: LoaderType;
    color?: string;
    classes?: { container?: string; loader?: string };
    styles?: { root?: CSSProperties };
}

const LoaderComponent = ({
    position = 'left',
    type = 'ball-beat',
    color = '#2A384B',
    classes,
    styles,
}: LoaderProps) => {
    return (
        <div style={styles?.root} className={classNames('my-loader-container', position, classes?.container)}>
            <Loader innerClassName={classNames('my-loader', classes?.loader)} type={type} active {...{ color }} />
        </div>
    );
};

export { LoaderComponent as Loader };
