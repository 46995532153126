import './dialog2.scss';

import { DialogTitle } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import classNames from 'classnames';
import React from 'react';

import { Assets } from '../../assets/Assets';

export interface Dialog2Props {
    open: boolean;
    title?: React.ReactNode;
    content?: React.ReactNode;
    children?: React.ReactNode;
    onClose?: VoidFunction;
    fullWidth?: boolean;

    /** Hide the close icon */
    hideAutoClose?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

    disableEscapeKeyDown?: boolean;
    scroll?: 'body' | 'paper';
    dialogContentClasses?: { root?: string; content?: string };
}

export const Dialog2 = ({
    open = false,
    title = undefined,
    content = undefined,
    onClose = () => {},
    children = undefined,
    hideAutoClose = false,
    dialogContentClasses,
    ...rest
}: Dialog2Props) => {
    //#region  //* CLASSES

    const { root: rootClasses = '', content: contentClasses = '' } = dialogContentClasses || {};
    const enhancedRootClasses = classNames({ 'cd2-content-container': true, [rootClasses || '']: rootClasses });
    const enhancedContent = classNames({ 'cd2-content': true, [contentClasses || '']: contentClasses });

    //#endregion

    return (
        <Dialog open={open} onClose={onClose} {...rest}>
            <div className={enhancedRootClasses}>
                {!hideAutoClose && (
                    <div className="cd2-close-container">
                        <div className="cd-close-button" onClick={onClose}>
                            <img src={Assets.IcCloseWhite()} className="cd-close-img" alt="close" />
                        </div>
                    </div>
                )}
                {title && <DialogTitle>{title}</DialogTitle>}
                <div className={enhancedContent}>{content || children}</div>
            </div>
        </Dialog>
    );
};
