import Redux from 'redux';
import { XHTTP } from '../../../../../services/api';
import { ConfigFactory } from '../../../../../configs/ApiConfig';
import { AuthenticationUrls } from '../../../Models/AuthenticationUrls';

export class EmailApi {

    public static login = (email: string, password: string, dispatch: Redux.Dispatch, toast: boolean) =>
        XHTTP(
            AuthenticationUrls.LOGIN,
            {
                method: 'POST',
                data: {
                    email,
                    password,
                    client_id: ConfigFactory.getClient().id,
                    client_secret: ConfigFactory.getClient().secret,
                },
            },
            dispatch,
            toast
        );

    public static refresh = (refresh_token: string, dispatch: Redux.Dispatch) =>
        XHTTP(
            AuthenticationUrls.REFRESH,
            {
                method: 'POST',
                data: {
                    refresh_token,
                    client_id: ConfigFactory.getClient().id,
                    client_secret: ConfigFactory.getClient().secret,
                },
            },
            dispatch
        );
}

