import { ItemsGroupBy, ListUtils } from "../lib/IdeLink.Web.Common/src/utils/ListUtils";

export interface ShopMetadata {
    readonly key: string;
    readonly value: string;
}

export enum FrontRoleCode {
    GroupLeader = 'GroupLeader'
}
export interface ShopForUser {
    readonly id: string;
    readonly name: string;
    readonly externalReference: string;
    readonly role: FrontRoleCode;
    readonly isGhost: boolean;
    readonly metadata: Array<ShopMetadata>;
}

export interface ShopForUserWithRoles {
    readonly id: string;
    readonly name: string;
    readonly externalReference: string;
    readonly isGhost: boolean;
    readonly metadata: Array<ShopMetadata>;
    readonly roles: Array<FrontRoleCode>;
}

export class ShopForUserHelper {
    public static toItemsShopForUserWithRoles = (shops: Array<ShopForUser> = []): Array<ShopForUserWithRoles> => {

        const shopsById: ItemsGroupBy<string, ShopForUser> = ListUtils.groupBy(shops, (shop) => shop.id);

        let shopForUserWithRolesList: Array<ShopForUserWithRoles> = [];
        shopsById.keys.map((shopId: string) => {
            const shopForUserItems: Array<ShopForUser> | undefined = shopsById.map.get(shopId);
            if (shopForUserItems && shopForUserItems.length > 0) {
                const shopForUser = shopForUserItems[0];
                const roles: Array<FrontRoleCode> = shopForUserItems.map((shop: ShopForUser) => shop.role);
                const shopForUserWithRoles: ShopForUserWithRoles = { ...shopForUser, roles };

                shopForUserWithRolesList = [...shopForUserWithRolesList, shopForUserWithRoles]
            }
        });
        return shopForUserWithRolesList;
    }
}