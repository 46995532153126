import { MyShopsActions } from './MyShopsActions';

import Redux from 'redux';
import { ShopForUser, ShopForUserHelper, ShopForUserWithRoles } from '../../models/ShopForUser';
import { UserActions } from '../../lib/IdeLink.Web.Common/src/modules/User/UserActions';

export const MyShopsMiddleware: Redux.Middleware = ({ dispatch, getState }: Redux.MiddlewareAPI<any>) => next => (action: any) => {
    if (typeof action === 'function') {
        return action(dispatch, getState);
    }

    if (action && action.type && action.type === UserActions.SET_AUTHENTICATED_USER_INFO) {
        const shops: Array<ShopForUser> = (action.info && action.info.shops) || [];
        const shopsWithRoles: Array<ShopForUserWithRoles> = ShopForUserHelper.toItemsShopForUserWithRoles(shops);

        //* Group

        const prevSelectedShop: ShopForUserWithRoles = getState().MyShops.get('selectedShop');

        dispatch(MyShopsActions.setMyShops(shopsWithRoles));

        if (
            shops.length > 0 &&
            (!prevSelectedShop || !prevSelectedShop.id || !shops.find((shop: ShopForUser) => prevSelectedShop.id === shop.id))
        ) {
            dispatch(MyShopsActions.setSelectedShop(shopsWithRoles.length ? shopsWithRoles[0] : undefined));
        }
    }

    return next(action);
};


