import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useIdelinkTheme } from '../../xlink/configs/IdeLinkTheme';
import { NavbarModuleItem } from '../../lib/IdeLink.Web.Common/src/components/NavBar/components/NavBarItem/NavBarItem';
import { RouteItem } from '../../lib/IdeLink.Web.Common/src/types/RouteItem';
import { PrivateRoute } from '../../routers/PrivateRoute';
import { EngagementDetailsPageLoading } from './Pages/EngagementDetailsPage/EngagementDetailsPageLoading';
import { OperationDetailsPageLoading } from './Pages/OperationDetailsPage/OperationDetailsPageLoading';
import { OperationListPageLoading } from './Pages/OperationsListPage/OperationListPageLoading';

export class OperationsModule {
    public static OPERATIONS_MODULE_CODE = 'Operations';
    public static ENGAGEMENT_MODULE_CODE = 'Engagements'; //! je pense que ça n'existe pas

    public static ROUTE_MAP_KEY_OPERATIONS = 'operations';
    public static ROUTE_MAP_KEY_ENGAGEMENTS = 'engagements';
    public static OPERATION_LIST = '/operations';
    public static OPERATION_DETAILS = `/operations/:id`;

    public static ENGAGEMENT_BASE = '/engagements';
    public static ENGAGEMENT_DETAILS = `/engagements/:id`;

    public static GET_OPERATION_DETAILS_ROUTE = (id: string) => `/operations/${id}`;
    public static GET_ENGAGEMENT_DETAILS_ROUTE = (id: string) => `/engagements/${id}`;

    private static getOperationListRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: OperationsModule.OPERATION_LIST,
            component: OperationListPageLoading,
        },
    });
    private static getOperationsDetailsRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: OperationsModule.OPERATION_DETAILS,
            component: OperationDetailsPageLoading,
        },
    });
    private static getEngagementsDetailsRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: OperationsModule.ENGAGEMENT_DETAILS,
            component: EngagementDetailsPageLoading,
        },
    });

    public static getOperationsNavbarModuleItem = (): NavbarModuleItem => ({
        label: <FormattedMessage id="operations.navbar" />,
        icon: useIdelinkTheme().operations.iconWhite,
        route: OperationsModule.OPERATION_LIST,
        onMouseOver: () => OperationsModule.preloadOperations(),
        childRoutes: [OperationsModule.ENGAGEMENT_BASE],
    });

    private static preloadOperations = () => OperationListPageLoading.preload();

    public static getOperationsRoutes = (): Array<RouteItem> => [
        OperationsModule.getOperationListRoute(),
        OperationsModule.getOperationsDetailsRoute(),
        OperationsModule.getEngagementsDetailsRoute(),
    ];
}
