
export class EmailAuthActions {
    public static LOGIN_EMAIL_SUCCESS = 'app/auth/email/login/success';
    public static LOGIN_EMAIL_FAIL = 'app/auth/email/login/fail';
    public static REFRESH_EMAIL_SUCCESS = 'app/auth/email/refresh/success';
    public static REFRESH_EMAIL_FAIL = 'app/auth/email/refresh/fail';

    public static refreshEmailSuccess = (result: any) => ({
        type: EmailAuthActions.REFRESH_EMAIL_SUCCESS,
        payload: { ...result },
    });

    public static refreshEmailFailure = (error: any) => ({
        type: EmailAuthActions.REFRESH_EMAIL_FAIL,
        payload: { error },
    });

    public static loginEmailSuccess = (result: any) => ({
        type: EmailAuthActions.LOGIN_EMAIL_SUCCESS,
        payload: { ...result },
    });

    public static loginEmailFailure = (error: any) => ({
        type: EmailAuthActions.LOGIN_EMAIL_FAIL,
        payload: { error },
    });
}
