import { FluizyConstants } from '../FluizyConstants';
import {
    getDefaultModuleNamesMap,
    getModuleNamesMapFactory,
} from '../lib/IdeLink.Web.FrontOffice/src/configs/ModuleNamesMap';
import { CommunicationsModule } from '../lib/IdeLink.Web.FrontOffice/src/modules/Communications/CommunicationsModule';
import { OffersModule } from '../lib/IdeLink.Web.FrontOffice/src/modules/Offers/OffersModule';
import { OperationsModule } from '../lib/IdeLink.Web.FrontOffice/src/modules/Operations/OperationsModule';

export class FluizyModuleNamesConfig {

    public static getModuleNamesMap = () => ({
        ...getDefaultModuleNamesMap(),

        [FluizyConstants.OFFERS_MODULE_CODE]: OffersModule.OFFERS_MODULE_CODE,
        [FluizyConstants.OPERATIONS_MODULE_CODE]: OperationsModule.OPERATIONS_MODULE_CODE,

        [FluizyConstants.DIGITAL_MARKETINGS_MODULE_CODE]: CommunicationsModule.DIGITAL_MARKETINGS_MODULE_CODE,
        [FluizyConstants.PARTNERSHIPS_MODULE_CODE]: CommunicationsModule.PARTNERSHIPS_MODULE_CODE,
        [FluizyConstants.LEAFLETS_MODULE_CODE]: CommunicationsModule.LEAFLETS_MODULE_CODE,
    });

    public static overrideFrontModuleNamesMap = () => {
        getModuleNamesMapFactory.register(['*', '/'], FluizyModuleNamesConfig.getModuleNamesMap);
    };
}
