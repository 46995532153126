import React from 'react';
//@ts-ignore
import Loadable from 'react-loadable';

export interface LazyLoaderProps {
    delay?: number;
    children?: React.ReactNode;
}

export const LazyLoader = ({ delay = 1000, children }: LazyLoaderProps) => {
    let chunksTimeout: any = undefined;

    React.useEffect(() => {
        chunksTimeout = setTimeout(() => {
            Loadable.preloadAll().catch((err: any) => console.error(err));
        }, delay);

        return () => clearTimeout(chunksTimeout);
    }, []);

    return <React.Fragment>{children}</React.Fragment>;
};
