import { Profile } from './components/Auth/Profile/Profile';
import {
    ProfileFactory,
} from './lib/IdeLink.Web.Common/src/components/Layouts/AuthenticatedPageLayout/Header/Profile/ProfileHeaderMenu/ProfileHeaderMenu';
import { UserMeFactory } from './services/user/UserMeFactory';

export class FrontOverrides {

    public static applyOverrides = () => {
        ProfileFactory.register('**', Profile)
        UserMeFactory.overrideMe();
    };
}



