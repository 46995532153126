import * as Redux from 'redux';
import { combineReducers } from 'redux';
import { createTransform, Persistor, persistReducer, persistStore } from 'redux-persist';
import ThunkMiddleware from 'redux-thunk';

import {
    AuthenticationMiddleware,
} from './lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Services/Authentication/Base/AuthenticationMiddleware';
import {
    UserMiddleware,
} from './lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/User/UserMiddleware';
import {
    RouterMiddleware,
    RouterReducer,
} from './lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/redux/BrowserHistory';

import { ReduxLogger } from './lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/services/logger/ReduxLogger';
import { MyShopsMiddleware } from './lib/IdeLink.Web.FrontOffice/src/services/myShops/MyShopsMiddleware';
import { FluizyI18n } from './locales/FluizyI18n';
import storage from 'redux-persist/lib/storage';

import { AppReducers } from './lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/app/AppState';
import {
    AuthenticationReducer,
} from './lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Services/Authentication/Base/AuthenticationReducer';
import { UserReducer } from './lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/User/UserReducer';
import { I18nReducer } from './lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/services/i18n/I18nReducer';
import { MyShopsReducer } from './lib/IdeLink.Web.FrontOffice/src/services/myShops/MyShopsReducer';
import { FluizyConstants } from './FluizyConstants';
import { composeWithDevTools } from '@redux-devtools/extension';
import { immutable } from '@redux-devtools/serialize';
import Immutable from 'immutable';

export class FluizyStore {

    public static getStore = () => {
        const rawAppReducer = combineReducers<AppReducers>({
            router: RouterReducer,
            I18n: I18nReducer,
            Authentication: AuthenticationReducer,
            User: UserReducer,
            MyShops: MyShopsReducer,
        });

        const immutableTransform = () => {
            const config: any = {};
            const serializer = immutable(Immutable, config.records);
            return createTransform(serializer.stringify, serializer.parse, config);
        };

        const applicationReducer = persistReducer(
            {
                key: FluizyConstants.FLUIZY_AUTH_STORAGE_KEY,
                whitelist: ['Authentication', 'User', 'MyShops'],
                transforms: [immutableTransform()],
                storage,
            },
            rawAppReducer
        );

        let middlewares: Array<Redux.Middleware> = ReduxLogger.withMiddlewares([
            ThunkMiddleware,
            RouterMiddleware,
            AuthenticationMiddleware,
            UserMiddleware,
            MyShopsMiddleware,
        ]);

        const initialState = { I18n: FluizyI18n.getI18nState() };

        const composeEnhancers = composeWithDevTools({});
        const enhancer: Redux.StoreEnhancer = composeEnhancers(Redux.applyMiddleware(...middlewares));

        const store: Redux.Store = Redux.createStore(applicationReducer, initialState, enhancer);
        const persistor: Persistor = persistStore(store);

        return { store, persistor };
    }
}
