import { overrideFluizyAuthBase } from './FluizyAuthBase/FluizyAuthBase';
import { overrideFluizyConnectHeader } from './FluizyConnectHeader/FluizyConnectHeader';
import { overrideFluizyLogo } from './FluizyLogo/FluizyLogo';
import { overrideFluizyMessage } from './FluizyMessage/FluizyMessage';

export const overrideFluizyAuthComponents = () => {
    overrideFluizyAuthBase();
    overrideFluizyLogo();
    overrideFluizyMessage();
    overrideFluizyConnectHeader();
};
