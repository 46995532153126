import './FluizyConnect.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useConnect } from '../../../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Hooks/Connect/useConnect';
import { ConnectProps } from '../../../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Pages/components/Connect/Connect';
import { ConnectBoxFactory } from '../../../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Pages/components/ConnectBox/ConnectBox';
import { ConnectContentFactory } from '../../../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Pages/components/ConnectContent/ConnectContent';
import { ConnectHeaderFactory } from '../../../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Pages/components/ConnectHeader/ConnectHeader';
import { ErrorFactory } from '../../../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Pages/components/Error/Error';
import { GoogleConnectButton } from '../../../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Pages/components/GoogleConnect/GoogleConnectButton';
import { ImpersonateButtonConnect } from '../../../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Pages/components/ImpersonateConnect/ImpersonateConnectButton';
import { UsernameConnect } from '../../../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Pages/components/UsernameConnect/UsernameConnect';

export const FluizyConnect = ({
    i18n,
    loginWithEmail,
    setAuthFromGoogleCredentials,
    loginWithImpersonate,

    emailConnecting,
    googleConnecting,
    impersonateConnecting,
}: ConnectProps) => {
    const {
        connectState: { error, errorEmailInput, errorPasswordInput },
        doLoginWithEmail,
        doLoginWithImpersonate,
    } = useConnect({ loginWithEmail, loginWithImpersonate, emailConnecting, googleConnecting, impersonateConnecting });

    const loginDisabled = emailConnecting || googleConnecting || impersonateConnecting;

    return (
        <div className="FluizyConnect-root">
            <ErrorFactory classNameRoot="FluizyConnect-error" hide={!error && !errorEmailInput && !errorPasswordInput}>
                {error || errorEmailInput || errorPasswordInput}
            </ErrorFactory>
            <ConnectBoxFactory>
                <ConnectHeaderFactory>
                    <FormattedMessage id="login.google.title" />
                </ConnectHeaderFactory>
                <ConnectContentFactory className="FluizyConnect-connect">
                    <UsernameConnect
                        i18n={i18n}
                        loading={emailConnecting}
                        login={doLoginWithEmail}
                        disabled={loginDisabled}
                        errorEmailInput={!!errorEmailInput}
                        errorPasswordInput={!!errorPasswordInput}
                    />
                    <div className="FluizyConnect-login-options-separator">
                        <div className="FluizyConnect-login-options-separator-left" />
                        <div className="FluizyConnect-login-options-separator-center">
                            <FormattedMessage id="commons.or" />
                        </div>
                        <div className="FluizyConnect-login-options-separator-right" />
                    </div>
                    <GoogleConnectButton
                        loading={googleConnecting}
                        disabled={loginDisabled}
                        setAuthFromGoogleCredentials={setAuthFromGoogleCredentials}
                    />
                    <ImpersonateButtonConnect
                        i18n={i18n}
                        loading={impersonateConnecting}
                        disabled={loginDisabled}
                        loginWithImpersonate={doLoginWithImpersonate}
                        classes={{ root: 'FluizyConnect-btn-maintenance' }}
                    />
                </ConnectContentFactory>
            </ConnectBoxFactory>
        </div>
    );
};
