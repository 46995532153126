import { addSeconds } from 'date-fns/esm';
export class EmailAuthUtils {

    public static getAuthorizationFromToken = (token: string) => `Bearer ${token}`;

    /**
     ** Adds an `expiration_date` as ms since epoch
     *  @param {Object} auth
     */
    public static transformAuth = (auth: any) => ({
        ...auth,
        expiration_date: addSeconds(new Date(), auth.expires_in || 0).toISOString(),
    });

}
