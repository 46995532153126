import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
    root: {
        margin: '10px 5px',
        display: 'flex',
        flex: '1',
        width: '100%',
    },
    bootstrapRoot: {
        'label + &': {
            marginTop: theme.spacing.unit * 3,
        },
    },
    bootstrapInput: {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
    bootstrapInputError: {
        border: '1px solid #ff1900',
        color: '#ff1900',
    },
});

const LoginInput = ({ classes, customClasses, classNameRoot, error, errorMessage, ...rest }) => (
    <FormControl error={error} className={`${classes.root} ${classNameRoot}`}>
        <InputBase
            classes={{
                root: classNames({
                    [classes.bootstrapRoot]: classes.bootstrapRoot,
                    [customClasses.root]: customClasses.root,
                }),
                input: classNames({
                    [classes.bootstrapInput]: classes.bootstrapRoot,
                    [customClasses.input]: customClasses.input,
                }),
                error: classNames({
                    [classes.bootstrapInputError]: classes.bootstrapRoot,
                    [customClasses.error]: customClasses.error,
                }),
            }}
            {...rest}
        />
        {error && errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
);

LoginInput.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    classNameRoot: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.node,

    customClasses: PropTypes.shape({
        root: PropTypes.string,
        input: PropTypes.string,
        error: PropTypes.string,
    }),
};

LoginInput.defaultProps = {
    value: '',
    onChange: undefined,
    placeholder: undefined,
    name: undefined,
    type: undefined,
    classNameRoot: '',
    error: false,
    errorMessage: null,
    customClasses: {},
};

const StyledLoginInput = withStyles(styles)(LoginInput);

export { StyledLoginInput as LoginInput };
