import { Map } from 'immutable';
import Redux from 'redux';
import { REHYDRATE } from 'redux-persist';

import { AuthenticationActions } from '../Auth/Services/Authentication/Base/AuthenticationActions';
import { UserActions } from './UserActions';

const initialState = Map({
    idle: false,
    me: null,

    info: {},
    // {
    //     last_name: string,
    //     first_name: string,
    //     shops: string,
    // }

    initialized: false,
});

export const UserReducer = (state = initialState, action: Redux.AnyAction) => {
    switch (action.type) {
        case REHYDRATE:
            // case reset : return new Map({ ...initialState.toJS() });
            return Map.isMap(state) ? state : Map(state);
        case AuthenticationActions.LOG_OUT:
            return initialState;
        case UserActions.SET_INITIALIZED:
            return (Map.isMap(state) ? state : Map(state)).set('initialized', action.initialized);
        case UserActions.SET_ME:
            return state.set('me', action.me);
        case UserActions.SET_IDLE:
            return state.set('idle', action.idle);
        case UserActions.SET_AUTHENTICATED_USER_INFO:
            return state.set('info', action.info).set('initialized', true);
        default:
            return Map.isMap(state) ? state : Map(state);
    }
};

