import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useIdelinkTheme } from '../../xlink/configs/IdeLinkTheme';
import { NavbarModuleItem } from '../../lib/IdeLink.Web.Common/src/components/NavBar/components/NavBarItem/NavBarItem';
import { PrivateRoute } from '../../routers/PrivateRoute';
import { RouteItem } from '../../lib/IdeLink.Web.Common/src/types/RouteItem';
import { SurveyDetailsPageLoading } from './Pages/SurveyDetailsPage/SurveyDetailsPageLoading';
import { SurveyListPageLoading } from './Pages/SurveysListPage/SurveyListPageLoading';

export class SurveysModule {
    public static SURVEYS_MODULE_CODE = 'Surveys';

    public static SURVEY_LIST_ROUTE = '/surveys';
    public static SURVEY_DETAILS_ROUTE = '/surveys/:surveyId';
    public static GET_SURVEY_DETAILS_ROUTE = (id: string) => `/surveys/${id}`;

    private static getSurveyListRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: SurveysModule.SURVEY_LIST_ROUTE,
            component: SurveyListPageLoading,
        },
    });
    private static getSurveyDetailsRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: SurveysModule.SURVEY_DETAILS_ROUTE,
            component: SurveyDetailsPageLoading,
        },
    });

    public static getSurveysNavbarModuleItem = (): NavbarModuleItem => {
        return {
            label: <FormattedMessage id="surveys.title" />,
            route: SurveysModule.SURVEY_LIST_ROUTE,
            icon: useIdelinkTheme().surveys.iconWhite,
            onMouseOver: () => SurveysModule.preloadSurveys(),
        };
    };

    private static preloadSurveys = () => {
        SurveyListPageLoading.preload();
        SurveyDetailsPageLoading.preload();
    };

    public static getSurveysRoutes = (): Array<RouteItem> => [
        SurveysModule.getSurveyListRoute(),
        SurveysModule.getSurveyDetailsRoute(),
    ];
}
