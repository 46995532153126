import './connectContent.scss';

import classNames from 'classnames';
import React from 'react';

import { withFactory } from '../../../../../Factory';

export interface ConnectContentProps {
    className?: string;
    children?: React.ReactNode;
}

export const ConnectContent = ({ children, className = '' }: ConnectContentProps) => (
    <div className={classNames('connect-content-container', { [className]: className })}>{children}</div>
);

export const ConnectContentFactory = withFactory(ConnectContent);
