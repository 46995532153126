import './Input2.scss';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import classnames from 'classnames';
import React, { ChangeEvent } from 'react';

export interface InputClasses {
    classes?: {
        root?: string;
        input?: string;
        focused?: string;
        error?: string;
        adornedStart?: string;
        adornedEnd?: string;
    };
    placeholder?: string;
    startAdornment?: React.ReactNode;
}

export interface Input2Props {
    className?: string;
    /** Classes for the FormControl */
    classes?: { root?: string };
    FormControlProps?: {};
    InputLabelProps?: InputClasses;
    InputBaseProps?: InputClasses;
    /** Label for the input, displays above it */
    label?: React.ReactNode;
    /** Adds borders to the input */
    bordered?: boolean;

    rounded?: boolean;
    /** Adds a bottom border to the input */
    underline?: boolean;
    /** Sets the input bg color of off white when not focused */
    offWhiteUnfocused?: boolean;
    /** Centers the text in the input */
    centerText?: boolean;
    /** Centers the label */
    centerLabel?: boolean;
    /** Styles the label to be warm-grey */
    lightLabel?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    /** If true, apply error styles */
    error?: boolean;
    /** Style the label and borders as copper */
    copper?: boolean;
    /** Style the label and borders as mango */
    mango?: boolean;
    /** The value of the input */
    value?: string;
    hintText?: string;
    /** Type of the input */
    type?: string;
    /** Name attribute of the input element. */
    name?: string;
    maxLength?: number;
    disabled?: boolean;
    fullWidth?: boolean;
    errorMessage?: React.ReactNode;
}

export const Input2 = ({
    classes = {},
    FormControlProps = {},
    InputLabelProps = { classes: {} },
    InputBaseProps = { classes: {} },
    label = null,
    bordered = false,
    rounded = false,
    underline = false,
    offWhiteUnfocused = false,
    centerText = false,
    centerLabel = false,
    lightLabel = false,
    onChange = undefined,
    error = false,
    copper = false,
    mango = false,
    value = '',
    hintText,
    type = 'text',
    name = '',
    maxLength = undefined,
    errorMessage = null,
    ...rest
}: Input2Props) => {
    const { classes: customClassesBase, ...otherInputBaseProps } = InputBaseProps;
    const { classes: customClassesInputLabel, ...otherInputLabelProps } = InputLabelProps;

    const classesBase = {
        ...customClassesBase,
        root: classnames({
            'pst-input-base': true,
            bordered,
            rounded,
            underline,
            hasLabel: !!label,
            offWhiteUnfocused,
            centerText,
            copper,
            mango,
            [(customClassesBase && customClassesBase.root) || '']: customClassesBase && customClassesBase.root,
        }),
        focused: classnames({
            focused: true,
            [(customClassesBase && customClassesBase.focused) || '']: customClassesBase && customClassesBase.focused,
        }),
        error: classnames({
            error: true,
            [(customClassesBase && customClassesBase.error) || '']: customClassesBase && customClassesBase.error,
        }),
        adornedStart: classnames({
            adornedStart: true,
            [(customClassesBase && customClassesBase.adornedStart) || '']:
                customClassesBase && customClassesBase.adornedStart,
        }),
        adornedEnd: classnames({
            adornedEnd: true,
            [(customClassesBase && customClassesBase.adornedEnd) || '']:
                customClassesBase && customClassesBase.adornedEnd,
        }),
    };

    const classesInputLabel = {
        ...customClassesInputLabel,
        root: classnames({
            'pst-input-label': true,
            centerLabel,
            lightLabel,
            copper,
            mango,
            [(customClassesInputLabel && customClassesInputLabel.root) || '']:
                customClassesInputLabel && customClassesInputLabel.root,
        }),
        focused: classnames({
            focused: true,
            [(customClassesInputLabel && customClassesInputLabel.focused) || '']:
                customClassesInputLabel && customClassesInputLabel.focused,
        }),
        shrink: classnames({
            shrink: true,
        }),
        error: classnames({
            error: true,
            [(customClassesInputLabel && customClassesInputLabel.error) || '']:
                customClassesInputLabel && customClassesInputLabel.error,
        }),
    };

    const classesFormControl = {
        ...classes,
        root: classnames({
            'pst-input-form-control': true,
            [classes.root || '']: classes.root,
        }),
    };

    return (
        <FormControl classes={classesFormControl} error={error} {...FormControlProps} {...rest}>
            {label && (
                <InputLabel shrink classes={classesInputLabel} {...otherInputLabelProps}>
                    {label}
                </InputLabel>
            )}
            <InputBase
                classes={classesBase}
                onChange={onChange}
                value={value}
                type={type}
                placeholder={hintText}
                name={name}
                {...otherInputBaseProps}
                inputProps={{ maxLength }}
            />
            {errorMessage && error && <FormHelperText>{errorMessage}</FormHelperText>}
        </FormControl>
    );
};
