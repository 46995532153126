import React from 'react';

import { DefaultIdelinkTheme, IdelinkThemeContext } from '../lib/IdeLink.Web.FrontOffice/src/xlink/configs/IdeLinkTheme';
import {
    CommonConfig,
    CommonContext,
} from '../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/configs/CommonConfig';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ConfigFactory } from '../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/configs/ApiConfig';

export class FluizyConfigs {
    public static CommonConfig: CommonConfig = { showProfile: true };
}

export const FluizyContextWrapper = ({ children }: React.PropsWithChildren<any>) => {
    return (
        <IdelinkThemeContext.Provider value={DefaultIdelinkTheme}>
            <CommonContext.Provider value={FluizyConfigs.CommonConfig}>
                <GoogleOAuthProvider clientId={ConfigFactory.getGoogleClientId()}>{children}</GoogleOAuthProvider>
            </CommonContext.Provider>
        </IdelinkThemeContext.Provider>
    );
};
