import Redux from 'redux';
import { AppState } from '../../../../../app/AppState';
import { AuthenticationPinResponse, TokenItem } from '../../../Models/AuthenticationResponse';
import { AuthenticationActions } from '../Base/AuthenticationActions';
import { ImpersonateApi } from './ImpersonateApi';
import { ImpersonateAuthActions } from './ImpersonateAuthActions';
import { ImpersonateAuthUtils } from './ImpersonateAuthUtils';

export class ImpersonateAuthService {

    /**
    * @param {String} pin
    * @returns {Promise<Object>} the full result of the login: access_token, refresh_token...
    * @throws {Promise<Error>} an Axios error if the request failed
    */
    public static login = (pin: string) => (dispatch: (action: any) => any): Promise<TokenItem> => {
        return ImpersonateApi.login(pin, dispatch)
            .then(async (res: AuthenticationPinResponse) => {
                const authResult: TokenItem = res.data;
                await dispatch(AuthenticationActions.setImpersonateLoginMethod());
                return dispatch(ImpersonateAuthService.loginSuccess(authResult));
            })
            .catch(err => dispatch(ImpersonateAuthService.loginFail(err)));
    }

    /**
     * Dispatches the success auth actions
     * @param {Object} result : TokenItem - The result of the successful login
     * @param {String} result.token
     * @returns {Promise<Object>} result - the result received
     */
    public static loginSuccess = (auth: TokenItem) => (dispatch: Redux.Dispatch) => {
        dispatch(ImpersonateAuthActions.loginImpersonateSuccess(auth));
        //dispatch(AuthenticationActions.setInitialized(true));
        return Promise.resolve(auth);
    };

    /**
     ** Dispatches the email login failure with the errror received
     * @param {Error} error
     * @throws {Promise<Error>} the error received
     */
    public static loginFail = (error: any) => (dispatch: Redux.Dispatch) => {
        // dispatch(AuthenticationActions.setInitialized(true));
        // dispatch(AuthenticationActions.setAuthenticated(false));
        dispatch(ImpersonateAuthActions.loginImpersonateFail(error));
        return Promise.reject(error);
    };

    /**
   ** If the access token is not expired, fires refreshSuccess right away with current state.
   ** Resets the initialized state (keeps the authenticated state) and then refreshes the access token
   * @param {Boolean} forceRefresh - If should refresh the tokens, even if the current ones are not expired
   * @returns {Promise<Object>} result - the result of the refresh, same as the login result OR an object
   ** with props `isError` and `err`
   * @throws {Promise<Error>} an Axios error if the refresh failed
   */
    public static refreshAuth = (forceRefresh?: boolean) => (dispatch: (action: any) => any, getState: () => AppState) => {
        const token = getState().Authentication.get('impersonate_token');
        if (token) {
            return Promise.resolve(dispatch(ImpersonateAuthActions.refreshImpersonateSuccess({ token })));
        } else {
            const error = new Error('No token found, cannot continue and will logout');
            dispatch(ImpersonateAuthActions.refreshImpersonateFail(error));
            return Promise.reject(error);
        }
    };

    public static getAuthorization = () => (dispatch: (action: any) => Promise<any>, getState: () => AppState) => {
        const token = getState().Authentication.get('impersonate_token');
        const auth = ImpersonateAuthUtils.getAuthorizationFromToken(token);
        return Promise.resolve(auth);
    };

    public static logout = (reason?: string) => (dispatch: Redux.Dispatch) => dispatch(AuthenticationActions.logout(reason));
}