import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { AuthenticationActions } from '../../lib/IdeLink.Web.Common/src/modules/Auth/Services/Authentication/Base/AuthenticationActions';
import { UserApi } from '../../lib/IdeLink.Web.Common/src/modules/User/UserApi';
import {
    UserMeHandlerFactory,
    UserMeHandlerParameters,
} from '../../lib/IdeLink.Web.Common/src/modules/User/UserMeHandler';
import { ShopForUser } from '../../models/ShopForUser';

export class UserMeFactory {
    public static overrideMe = () => {
        UserApi.GET_ME_URL.register('**', () => '/api-front/v1/me');

        UserMeHandlerFactory.register('**', (props: UserMeHandlerParameters) => {
            const { userData, dispatch } = props;

            const shops: Array<ShopForUser> = userData.shops || [];
            if (shops.length > 0) {
                dispatch(AuthenticationActions.setAuthenticated(true));
            } else {
                toast.error(<FormattedMessage id="toast.apiErrors.noShops" />);
            }
        });
    };
}
