import './GondolesTheme.scss';


import defaultImg from '../assets/default-img-gondole-square.svg';
import emptyIcon from '../assets/ic-gondole-empty.svg';
import iconColor from '../assets/ic_gondole_color.svg';
import iconWhite from '../assets/ic_gondole_white.svg';
import { IdelinkModuleTheme } from '../../../xlink/configs/IdeLinkTheme';


export const GondolesTheme: IdelinkModuleTheme = {
    className: 'idelink-theme il-theme-gondoles',
    backgroundColor: '#109dd1', // water blue
    feedImage: defaultImg,
    emptyIcon: emptyIcon,
    iconColor: iconColor,
    iconWhite,
};
