export const esPasswords = {
    'password.forgot.goBackLabel': 'Volver a la conexión',
    'password.forgot.inputEmailEmpty': 'Por favor, introduce una dirección de correo electrónico válida',
    'password.forgot.inputEmailLabel': 'Correo electrónico',
    'password.forgot.label': 'Ingrese su dirección de correo electrónico para restablecer su contraseña.',
    'password.forgot.pageTitle': 'Restablecer la contraseña',
    'password.forgot.submitLabel': 'Restablecer mi contraseña',
    'password.forgot.title': 'Contraseña olvidada',
    'password.initialize.ask.initialize.label': 'Contraseña olvidada',
    'password.initialize.ask.initialize.message':
        'El enlace para inicializar su contraseña parece haber expirado. Al hacer clic en el botón de abajo, puede solicitar el envío de un nuevo correo electrónico de inicialización',
    'password.initialize.inputPassword': 'Contraseña',
    'password.initialize.inputPasswordConfirmation': 'confirmación de contraseña',
    'password.initialize.inputPasswordConfirmationEmpty': 'Por favor, confirme su contraseña',
    'password.initialize.inputPasswordEmpty': 'porfavor ingrese una contraseña',
    'password.initialize.label': 'Después de la creación de su cuenta, informe una contraseña aquí y confirme.',
    'password.initialize.pageTitle': 'Reiniciar mi contraseña',
    'password.initialize.passwordsNotSame': 'Las contraseñas no son idénticas',
    'password.initialize.submitLabel': 'Reiniciar',
    'password.initialize.success.message': '¡Su contraseña ha sido reiniciada!',
    'password.initialize.title': 'Reiniciar mi contraseña',
    'password.reset.ask.reset.label': 'Contraseña olvidada',
    'password.reset.ask.reset.message':
        'El enlace para restablecer su contraseña parece haber expirado. Al hacer clic en el botón de abajo, puede solicitar el reinicio de su contraseña.',
    'password.reset.inputPassword': 'Contraseña',
    'password.reset.inputPasswordConfirmation': 'Confirmación de contraseña',
    'password.reset.inputPasswordConfirmationEmpty': 'Por favor, confirme su contraseña',
    'password.reset.inputPasswordEmpty': 'Por favor, ingrese una contraseña',
    'password.reset.label':
        'Después de su solicitud de restablecimiento de contraseña, ingrese una nueva contraseña aquí y confirme.',
    'password.reset.pageTitle': 'Restablecer mi contraseña',
    'password.reset.passwordsNotSame': 'Las contraseñas no son idénticas',
    'password.reset.submitLabel': 'Reiniciar',
    'password.reset.success.message': '¡Tu contraseña ha sido restablecida!',
    'password.reset.title': 'Restablecer mi contraseña',
};