import { withFuncFactory } from "../../Factory";

export class I18nLocaleConfig {
    public static getDefaultLocale = (): string => 'fr';
    public static getDefaultSupportedLocales = (): Array<string> => ['fr'];

    public static getNavigatorLanguage = () => {
        const navigator = window.navigator;
        //* TO USE the full country codes, like : fr-BE, es-ES, nl-BE
        const navigatorLang = navigator && navigator.language ? navigator.language : '';

        //* TO USE the short country code, like : fr, es, nl
        const language: string = navigatorLang && navigatorLang.length >= 2 ? navigatorLang.slice(0, 2).toLowerCase() : '';
        return { navigatorLang, language };
    };

    public static getInitialLocale = () => {
        const { navigatorLang, language } = I18nLocaleConfig.getNavigatorLanguage();
        const supportedLocales: Array<string> = getSupportedLocalesFactory();

        if (supportedLocales.includes(navigatorLang)) {
            return navigatorLang;
        } else if (supportedLocales.includes(language)) {
            return language;
        }

        return getDefaultLocaleFactory();
    };
}

export const getDefaultLocaleFactory = withFuncFactory(I18nLocaleConfig.getDefaultLocale);
export const getSupportedLocalesFactory = withFuncFactory(I18nLocaleConfig.getDefaultSupportedLocales);
export const getInitialLocaleFactory = withFuncFactory(I18nLocaleConfig.getInitialLocale);
