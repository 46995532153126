import moment from 'moment';
import { getInitialLocaleFactory, getSupportedLocalesFactory } from '../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/services/i18n/I18nLocaleConfig';
import { I18nMessagesByLocale } from '../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/services/i18n/I18nMessages';
import { esFluizyMessages } from './es/esFluizyMessages';
import { frFluizyMessages } from './fr/frFluizyMessages';

export class FluizyI18n {
    public static getI18nState = () => {
        getSupportedLocalesFactory.register('**', () => ['fr', 'es']);
        const locale: string = getInitialLocaleFactory();
        const supportedLocales = ['fr', 'es'];
        // @ts-ignore
        const allMessages: I18nMessagesByLocale = { fr: frFluizyMessages, es: esFluizyMessages };

        moment.locale(locale);

        return {
            locale,
            locales: supportedLocales,
            messages: allMessages[locale],
            allMessages
        }
    }
}