import './checkboxV2.scss';

import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';

import classNames from 'classnames';

export interface CheckboxV2Props {
    checked?: boolean;
    indeterminate?: boolean;
    value?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    disabled?: boolean;
    classes?: {
        root?: string;
        checked?: string;
        colorSecondary?: string;
        indeterminate?: string;
        disabled?: string;
    };
    onClick?: (event: React.MouseEvent) => void;
}

export const CheckboxV2 = ({
    checked,
    indeterminate,
    onChange,
    value,
    disabled,
    classes,
    ...props
}: CheckboxV2Props) => {
    const {
        root: rootClasses = '',
        checked: checkedClasses = '',
        colorSecondary: colorSecondaryClasses = '',
        indeterminate: indeterminateClasses = '',
        disabled: disabledClasses = '',
    } = classes || { classes };
    return (
        <Checkbox
            {...props}
            value={value}
            checked={checked}
            indeterminate={indeterminate}
            onChange={onChange}
            disabled={disabled}
            classes={{
                ...classes,
                root: classNames({ 'cc2-root': true, [rootClasses]: rootClasses }),
                checked: classNames({ 'cc2-checked': true, [checkedClasses]: checkedClasses }),
                colorSecondary: classNames({ 'cc2-secondary': true, [colorSecondaryClasses]: colorSecondaryClasses }),
                indeterminate: classNames({ 'cc2-indeterminate': true, [indeterminateClasses]: indeterminateClasses }),
                disabled: classNames({ 'cc2-disabled': true, [disabledClasses]: disabledClasses }),
            }}
        />
    );
};
