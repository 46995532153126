import Redux from 'redux';

import { FluizyComponents } from './components/FluizyComponents';
import { FluizyModuleNamesConfig } from './configs/FluizyModuleNamesConfig';
import { FluizyRouteConfigs } from './configs/FluizyRouteConfigs';
import { FrontOverrides } from './lib/IdeLink.Web.FrontOffice/src/FrontOverrides';
import {
    AuthenticationActions,
} from './lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Services/Authentication/Base/AuthenticationActions';
import { StoreProvider } from './lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/redux/StoreProvider';

import { FluizyApiConfig } from './configs/FluizyApiConfig';
import { FrontAnalytics } from './lib/IdeLink.Web.FrontOffice/src/services/Analytics/FrontAnalytics';

export class FluizySetup {

    public static initializeApp = (store: Redux.Store) => {
        //* STORE
        StoreProvider.getStore.register('**', () => store);
        store.dispatch(AuthenticationActions.setUsernameLoginMethod());

        FrontOverrides.applyOverrides();
        FluizyComponents.applyComponentOverrides();

        //* OVERRIDE Configs
        FluizyApiConfig.initApiConfig();
        FluizyRouteConfigs.initApiRouteMappingConfig();
        FluizyModuleNamesConfig.overrideFrontModuleNamesMap();

        FrontAnalytics.initialize();
    }
}

