import './ChangePasswordButton.scss';

import Button from '@material-ui/core/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Dialog2 } from '../../../../lib/IdeLink.Web.Common/src/components/Dialog2';
import { Input2 } from '../../../../lib/IdeLink.Web.Common/src/components/Inputs/Input2/Input2';
import { Analytics } from '../../../../lib/IdeLink.Web.Common/src/services/analytics/Analytics';
import {
    AnalyticsRoutes,
    getAnalyticsRoute,
} from '../../../../lib/IdeLink.Web.Common/src/services/analytics/AnalyticsRoutes';
import { useChangePasswordButtonController } from './ChangePasswordButtonController';

export const ChangePasswordButton = () => {
    const { i18n, state, events } = useChangePasswordButtonController();
    return (
        <React.Fragment>
            <Button
                classes={{ root: 'profile-change-password-button-root', text: 'text' }}
                onClick={() => {
                    events.onChange('openDialog', !state.openDialog);
                    if (!state.openDialog) {
                        Analytics.log({ route: getAnalyticsRoute(AnalyticsRoutes.CA_ROUTE_CHANGE_PW) });
                    }
                }}
            >
                <FormattedMessage id="profile.change.password.button" />
            </Button>

            <Dialog2
                open={Boolean(state.openDialog)}
                onClose={() => {
                    Analytics.log({ route: getAnalyticsRoute(AnalyticsRoutes.CA_ROUTE_CHANGE_PW), back: true });
                    events.clearState();
                    events.update({ openDialog: false });
                }}
                maxWidth="sm"
                fullWidth
                content={
                    <div className="pcp-dialog-content">
                        <div className="pcp-dialog-title">
                            <FormattedMessage id="profile.change.password.dialog.title" />
                        </div>
                        <form onSubmit={e => e.preventDefault()}>
                            <PasswordInput
                                label={<FormattedMessage id="profile.password.old.label" />}
                                placeholder={i18n.messages['profile.password.old.placeholder']}
                                value={state.old_password}
                                onChange={value => events.onChange('old_password', value)}
                            />
                            <PasswordInput
                                label={<FormattedMessage id="profile.password.new.label" />}
                                placeholder={i18n.messages['profile.password.new.placeholder']}
                                value={state.new_password}
                                onChange={value => events.onChange('new_password', value)}
                            />
                            <PasswordInput
                                label={<FormattedMessage id="profile.password.confirmation.label" />}
                                placeholder={i18n.messages['profile.password.confirmation.placeholder']}
                                value={state.newPasswordConfirmation}
                                onChange={value => events.onChange('newPasswordConfirmation', value)}
                            />
                            <div className="pcp-dialog-button-submit-container">
                                <Button
                                    classes={{ root: 'pcp-dialog-button-submit', text: 'text' }}
                                    onClick={events.updatePassword}
                                    type="submit"
                                >
                                    <FormattedMessage id="profile.change.password.dialog.button.submit.label" />
                                </Button>
                            </div>
                        </form>
                    </div>
                }
            />
        </React.Fragment>
    );
};

type PasswordInputProps = {
    label: React.ReactNode;
    placeholder: string;
    value?: string;
    onChange: (value: string) => void;
};

const PasswordInput = ({ label, placeholder, value = '', onChange }: PasswordInputProps) => {
    return (
        <Input2
            label={label}
            bordered
            rounded
            fullWidth
            value={value}
            onChange={e => onChange(e.target.value)}
            InputBaseProps={{ placeholder }}
            type="password"
            classes={{ root: 'pcp-dialog-input' }}
        />
    );
};
