import './fluizyNavBar.scss';

import React from 'react';

import { FrontNavBar } from '../../lib/IdeLink.Web.FrontOffice/src/components/FrontNavBar/FrontNavBar';
import {
    NavBarFactory,
    NavBarProps,
} from '../../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/components/NavBar';
import { CommunicationsModule } from '../../lib/IdeLink.Web.FrontOffice/src/modules/Communications/CommunicationsModule';
import { DashboardModule } from '../../lib/IdeLink.Web.FrontOffice/src/modules/Dashboard/DashboardModule';
import { EventsModule } from '../../lib/IdeLink.Web.FrontOffice/src/modules/Events/EventsModule';
import { GondolesModule } from '../../lib/IdeLink.Web.FrontOffice/src/modules/Gondoles/GondolesModule';
import { OffersModule } from '../../lib/IdeLink.Web.FrontOffice/src/modules/Offers/OffersModule';
import { OperationsModule } from '../../lib/IdeLink.Web.FrontOffice/src/modules/Operations/OperationsModule';
import { SurveysModule } from '../../lib/IdeLink.Web.FrontOffice/src/modules/Surveys/SurveysModule';
import { NavbarUtilsFactory } from '../../lib/IdeLink.Web.FrontOffice/src/services/navbar/navbarUtils';

const FluizyNavBar = (props: NavBarProps) => {
    return (
        <FrontNavBar
            {...props}
            classes={{ root: 'FluizyNavBar-root' }}
            content={[
                {
                    items: [
                        DashboardModule.getDashboardNavbarModuleItem(),
                        OffersModule.getOffersNavbarModuleItem(),
                        OperationsModule.getOperationsNavbarModuleItem(),
                        CommunicationsModule.getCommunicationsNavbarModuleItem(),
                        EventsModule.getEventsNavbarModuleItem(),
                        SurveysModule.getSurveysNavbarModuleItem(),
                        GondolesModule.getGondoleNavbarModuleItem(),
                    ],
                },
            ]}
        />
    );
};

export const overrideFluizyNavBar = () => {
    NavBarFactory.register('**', FluizyNavBar);

    NavbarUtilsFactory.getWhitelistedShopSelectionRoutes.register('**', () => [
        DashboardModule.DASHBOARD_ROUTE,
        OffersModule.PACKAGED_ORDER_LIST(),
        OperationsModule.OPERATION_LIST,
        CommunicationsModule.COMMUNICATION_LIST,
        EventsModule.EVENT_LIST_ROUTE,
        SurveysModule.SURVEY_LIST_ROUTE,
        GondolesModule.GONDOLE_LIST,
    ]);
};
