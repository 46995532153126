import './asyncPageLoading.scss';

import React from 'react';

import { Loader } from '../../components/Loader';
import { LogoFactory } from '../../components/Logo';

const AsyncPageLoadingClocation = 'AsyncPageLoading';

export type AsyncPageLoadingProps = { pastDelay?: boolean };

export const AsyncPageLoading = ({ pastDelay = true }: AsyncPageLoadingProps) => {
    if (!pastDelay) return null;

    return (
        <div className="async-page-loading-container">
            <div className="apl-content-container">
                <LogoFactory cLocation={`/${AsyncPageLoadingClocation}/`} />
                <Loader position="center" classes={{ container: 'apl-loader-container', loader: 'apl-loader' }} />
            </div>
        </div>
    );
};

AsyncPageLoading.C_LOCATION = AsyncPageLoadingClocation;
