/* eslint-disable @typescript-eslint/no-explicit-any */
import { format, isValid, parseISO, isBefore, formatDistance } from 'date-fns/esm';
import { fr } from 'date-fns/esm/locale';

/**
 * @param {String|Date} date - ISO format
 * @param {*} defaultValue
 * @param {Object|String} options
 * @param {Boolean} options.acceptFalsyDefault - return the 'defaultDate' even if it's falsy
 * @returns {Date} - the parsed Date, or the default date, or the current date in that order
 */
export const customParseDate = <DefaultValue = any>(
    date: string | Date,
    defaultDate: DefaultValue,
    { acceptFalsyDefault }: { acceptFalsyDefault?: boolean } = {}
): Date | DefaultValue => {

    if (typeof date === 'string' && isValid(parseISO(date))) {
        return parseISO(date);
    }

    if (date instanceof Date && isValid(date)) {
        return date;
    }

    if (acceptFalsyDefault) {
        return defaultDate;
    }

    return defaultDate || new Date();
};

export const formatDateCustom = <DefaultValue extends any>(
    rawDate: string | Date,
    customFormat: string,
    defaultValue: DefaultValue | string = '-'
): string | DefaultValue => {
    const parsedDate = customParseDate<DefaultValue | string>(rawDate, defaultValue, {
        acceptFalsyDefault: true,
    });

    if (parsedDate === defaultValue) return defaultValue;

    return parsedDate
        ? format(parsedDate as Date, customFormat, {
            useAdditionalWeekYearTokens: true,
            locale: fr,
        })
        : defaultValue;
};

/**
 * @param {String|Date} rawDate
 * @param {Date|Number} baseDate
 * @param {*} defaultValue
 * @param {Object|String} options
 * @param {Boolean} options.includeSeconds - distances less than a minute are more detailed
 * @param {Boolean} options.addSuffix - result indicates if the second date is earlier or later than the first
 */
export const formatDistanceCustom = <DefaultValue extends any>(
    rawDate: string | Date,
    baseDate: Date,
    defaultValue: DefaultValue | string | null = '-',
    { includeSeconds, addSuffix }: { includeSeconds?: boolean; addSuffix?: boolean } = {}
): null | string | DefaultValue => {
    const parsedDate = customParseDate<DefaultValue | string | null>(rawDate, defaultValue, {
        acceptFalsyDefault: true,
    });

    if (parsedDate === defaultValue) return defaultValue;

    return parsedDate
        ? formatDistance(parsedDate as Date, baseDate, { includeSeconds, addSuffix, locale: fr })
        : defaultValue;
};

/**
 * @param {String|Date} rawDate
 * @param {*} defaultValue
 * @returns {String} the date formatted as 'dd/MM/yy' or the default value
 */
export const formatDateShort = <DefaultValue extends any>(
    rawDate: string | Date,
    defaultValue: DefaultValue | string = '-'
): string | DefaultValue => formatDateCustom<DefaultValue>(rawDate, 'dd/MM/yy', defaultValue);

/**
 * @param {String|Date} rawDate
 * @param {*} defaultValue
 * @returns {String} the date formatted as 'd MMMM Y' or the default value
 */
export const formatDateLong = <DefaultValue extends any>(
    rawDate: string | Date,
    defaultValue: DefaultValue | string = '-'
): string | DefaultValue => formatDateCustom<DefaultValue>(rawDate, 'd MMMM Y', defaultValue);

/**
 * @param {String|Date} rawDate
 * @param {Object|String} i18n - the i18n state or the 'at' label
 * @param {*} defaultValue
 * @returns {String}
 */
export const formatAtDateTime = <DefaultValue extends any>(
    rawDate: string | Date,
    i18n: { messages: { [x: string]: string } } | string,
    defaultValue: DefaultValue | string = '-'
): string | DefaultValue => {
    const label = typeof i18n === 'string' ? i18n : i18n.messages['label.at'];

    return formatDateCustom<DefaultValue>(rawDate, `dd/MM/yyyy '${label}' HH:mm`, defaultValue);
};

/**
 * @param {String|Date} rawDate
 * @param {*} defaultValue
 * @returns {String}
 */
export const formatDateTime = <DefaultValue extends any>(
    rawDate: string | Date,
    defaultValue: DefaultValue | string = '-'
): string | DefaultValue =>
    formatDateCustom<DefaultValue>(rawDate, `dd/MM/yyyy HH:mm`, defaultValue);

/**
 * @param {String|Date} rawFirst
 * @param {String|Date} rawSecond
 * @returns {Boolean} if the first date is before the second. Will return false if any
 * of the two dates are not valid
 */

export const isDateBeforeWithParse = (rawFirst: string | Date, rawSecond: string | Date): boolean => {
    const first = customParseDate<null>(rawFirst, null, { acceptFalsyDefault: true });
    const second = customParseDate<null>(rawSecond, null, { acceptFalsyDefault: true });
    return (!first || !second) ? false : isBefore(first, second);
};
