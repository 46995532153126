export const esOperations = {
    'operation.details.title': 'Detalles Plan de ventas',
    'engagement.details.title': 'Detalles Compromiso',
    'engagement.details.notFound': 'No se encontró ningún compromiso',
    'operation.details.actions.previous': 'Planes de venta',
    'operation.details.engagement.validate': 'Validar el compromiso',
    'operation.details.linked.engagement': 'Compromiso vinculado: {title}',
    'operation.details.linked.operation': 'Plan de ventas vinculado: {title}',
    'operation.details.notFound': 'No se encontró un plan de ventas',
    'operation.details.operation.validate': 'Validar el compromiso',
    'operation.details.promo_price_enabled.false': 'No',
    'operation.details.promo_price_enabled.title': 'Configurar los precios de promoción?',
    'operation.details.promo_price_enabled.true': 'Sí',
    'operation.filter.category.Engagement': 'Compromiso',
    'operation.filter.category.Operation': 'Plan de ventas',
    'operation.list.emptyLabel': 'No hay planes de ventas por el momento',
    'operation.title': 'Planes de venta',
    'operation.type.engagement.label': 'Compromiso',
    'operation.type.operation.label': 'Plan de ventas',
    'operation.validation.products.Box':
        '{nbReferences, plural, =0 {box} one {box para {nbReferences} reference} other {box para {nbReferences} referencias}}',
    'operation.validation.products.Palette':
        '{nbReferences, plural, =0 {palette} one {paleta para {nbReferences} reference} other {paletas para {nbReferences} referencias}}',
    'operation.validation.products.Product':
        '{nbReferences, plural, =0 {producto} one {producto para {nbReferences} reference} other {productos para {nbReferences} referencias}}',
    'operation.validation.products.TG':
        '{nbReferences, plural, =0 {tg} one {tg para {nbReferences} reference} other {tg para {nbReferences} referencias}}',
    'operations.navbar': 'Planes de venta',
};
