import { XHRRequest } from '../../../../../services/api';
import { ApiDataResponse } from '../../../../../services/api/ApiResponse';
import { AuthenticationUrls } from '../../../Models/AuthenticationUrls';

export class GoogleAuthActions {
    public static SET_TOKEN = 'app/auth/google/token/set';
    public static SET_REFRESH_TOKEN = 'app/auth/google/refresh_token/set';

    public static setGoogleToken = (credentials: any) => ({
        type: GoogleAuthActions.SET_TOKEN,
        payload: { credentials },
    });

    public static setGoogleRefreshToken = (refreshToken: string) => ({
        type: GoogleAuthActions.SET_REFRESH_TOKEN,
        payload: refreshToken,
    });

    public static googleLogin = (bodyRequest: GoogleLoginParameters): Promise<ApiDataResponse<GoogleLoginResponse>> => {
        return XHRRequest.post<ApiDataResponse<GoogleLoginResponse>>(AuthenticationUrls.GOOGLE_LOGIN, bodyRequest, {
            withAuth: false,
        });
    };

    public static googleRefresh = (
        bodyRequest: GoogleRefreshParameters
    ): Promise<ApiDataResponse<GoogleRefreshResponse>> => {
        return XHRRequest.post<ApiDataResponse<GoogleRefreshResponse>>(AuthenticationUrls.GOOGLE_REFRESH, bodyRequest, {
            withAuth: false,
        });
    };
}

export interface GoogleLoginParameters {
    readonly code: string;
    readonly redirect_uri: string;
}

export interface GoogleRefreshParameters {
    readonly refresh_token: string;
}

export interface GoogleLoginResponse {
    access_token: string;
    expires_in: number;
    id_token: string;
    refresh_token: string;
    scope: string;
    token_type: string;
}

export interface GoogleRefreshResponse {
    access_token: string;
    expires_in: number;
    id_token: string;
    refresh_token?: string;
    scope: string;
    token_type: string;
}