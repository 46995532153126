import React from 'react';
import { useLocation } from 'react-router-dom';

import { NavbarBase, NavBarProps } from '../../lib/IdeLink.Web.Common/src/components/NavBar';
import { NavbarUtilsFactory } from '../../services/navbar/navbarUtils';
import { ShopSelectionFactory } from './ShopSelection/ShopSelection';

export const FrontNavBar = (props: NavBarProps) => {
    const pathname: string = useLocation().pathname;

    const whitelist = NavbarUtilsFactory.getWhitelistedShopSelectionRoutes();
    const enableShopSelection = NavbarUtilsFactory.displayShopSelection({ pathname, whitelist });

    return <NavbarBase {...props} bottomContent={<ShopSelectionFactory disabled={!enableShopSelection} />} />;
};
