import React from 'react';

import { FluizyContextWrapper } from './configs/FluizyContextConfigs';
import { FluizyRouteConfigs } from './configs/FluizyRouteConfigs';
import { FluizySetup } from './FluizySetup';
import { AppWrapper } from './lib/IdeLink.Web.FrontOffice/src/AppWrapper';
import { useGoogleConnect } from './lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Hooks/GoogleConnect/useGoogleConnect';
import { browserHistory } from './lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/redux/BrowserHistory';
import { Loading } from './lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/scenes/Loading';
import { AppRouter } from './lib/IdeLink.Web.FrontOffice/src/routers/AppRouter';
import { FluizyStore } from './FluizyStore';

export const FluizyApp = () => {
    const { isGoogleApiReady } = useGoogleConnect();
    if (!isGoogleApiReady) {
        return <Loading />;
    }

    const { store, persistor } = FluizyStore.getStore();
    FluizySetup.initializeApp(store);

    return (
        <AppWrapper store={store} persistor={persistor}>
            <FluizyContextWrapper>
                <AppRouter routerHistory={browserHistory} routes={FluizyRouteConfigs.getAppRoutes()} />
            </FluizyContextWrapper>
        </AppWrapper>
    );
};
