import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useIdelinkTheme } from '../../xlink/configs/IdeLinkTheme';
import { NavbarModuleItem } from '../../lib/IdeLink.Web.Common/src/components/NavBar/components/NavBarItem/NavBarItem';
import { RouteItem } from '../../lib/IdeLink.Web.Common/src/types/RouteItem';
import { PrivateRoute } from '../../routers/PrivateRoute';
import { CommunicationType } from './Models/CommunicationType';
import { CommunicationDetailsPageLoading } from './Pages/CommunicationDetailsPage/CommunicationDetailsPageLoading';
import { CommunicationListPageLoading } from './Pages/CommunicationListPage/CommunicationListPageLoading';

export class CommunicationsModule {
    public static COMMUNICATION_GROUP_ITEMS_CODE = 'CommunicationsGroupItems';
    public static COMMUNICATION_GROUP_CODE = 'CommunicationsGroup';

    public static DIGITAL_MARKETINGS_MODULE_CODE = 'DigitalMarketings';
    public static PARTNERSHIPS_MODULE_CODE = 'Partnerships';
    public static LEAFLETS_MODULE_CODE = 'Leaflets';

    public static DIGITAL_MARKETINGS_ROUTE = 'digitalmarketings';
    public static PARTNERSHIPS_ROUTE = 'partnerships';
    public static LEAFLETS_ROUTE = 'leaflets';

    public static COMMUNICATION_LIST = '/communications';
    public static COMMUNICATION_DETAILS = '/communications/:type/:id';

    public static LIST = (filter?: CommunicationType) => `/communications?${filter ? `filter=${filter}` : ''}`;
    public static DIGITAL_DETAILS = (id: string) => `/communications/digitalmarketings/${id}`;
    public static PARTNERSHIP_DETAILS = (id: string) => `/communications/partnerships/${id}`;
    public static LEAFLET_DETAILS = (id: string) => `/communications/leaflets/${id}`;

    public static DIGITAL_MARKETING_BASE = `/communications/digitalmarketings`;
    public static PARTNERSHIP_BASE = `/communications/partnerships`;
    public static LEAFLET_BASE = `/communications/leaflets`;

    private static getCommunicationListRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: CommunicationsModule.COMMUNICATION_LIST,
            component: CommunicationListPageLoading,
        },
    });
    private static getCommunicationDetailsRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: CommunicationsModule.COMMUNICATION_DETAILS,
            component: CommunicationDetailsPageLoading,
        },
    });

    public static getCommunicationsNavbarModuleItem = (): NavbarModuleItem => ({
        label: <FormattedMessage id="communications.navbar" />,
        route: CommunicationsModule.COMMUNICATION_LIST,
        icon: useIdelinkTheme().communications.iconWhite,
        onMouseOver: () => CommunicationsModule.preloadCommunications(),
    });

    private static preloadCommunications = () => CommunicationListPageLoading.preload();

    public static getCommunicationRoutes = (): Array<RouteItem> => [
        CommunicationsModule.getCommunicationListRoute(),
        CommunicationsModule.getCommunicationDetailsRoute(),
    ];
}
