import Axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import qs from 'qs';

import { ApiError, ErrorLogger } from '../errors/ErrorLogger';
import { UploadProgressEvent } from '../Events/ProgressEvent';
import { qsOptions, serializeRequestParameters } from '../querystring/querystring';
import { ConfigFactory } from '../../configs/ApiConfig';
import { StoreProvider } from '../../redux/StoreProvider';


Axios.defaults.baseURL = ConfigFactory.getRoot();

//#region OLD //! DELETE LATER
//* OLD
export const XHTTP = <TResponse extends {}>(
    endPoint: string,
    config: AxiosRequestConfig = {},
    dispatch: (action: any) => any,
    shouldToast: boolean = true,
    { customErrorCode, trustSuccess }: {
        customErrorCode?: string, trustSuccess?: boolean
    } = {}
) =>
    new Promise<TResponse>((resolve, reject) => {
        Axios(endPoint, {
            ...config,
            paramsSerializer: params => qs.stringify(params, { ...qsOptions, addQueryPrefix: false }),
        })
            .then((res: AxiosResponse) => {
                if (!trustSuccess && !res.data.is_success) {
                    const apiError: ApiError = ErrorLogger.toApiApiError({ url: config.url, axiosError: res });
                    if (dispatch) {
                        dispatch(ErrorLogger.logApiError(apiError));
                    } else {
                        ErrorLogger.showApiError(apiError, StoreProvider.getAppState());
                    }

                    return reject(res.data);
                }
                return resolve(res.data);
            })
            .catch(error => {
                const isCancel: boolean = Axios.isCancel(error);
                const apiError: ApiError = ErrorLogger.toApiApiError({
                    url: endPoint,
                    axiosError: error.response || error,
                    shouldToast: !isCancel && shouldToast,
                    customErrorCode
                });
                if (dispatch) {
                    dispatch(ErrorLogger.logApiError(apiError));
                } else {
                    ErrorLogger.showApiError(apiError, StoreProvider.getAppState());
                }
                return reject(apiError);

            });
    });

//* OLD
export const XHTTP2 = async (endPoint: string, axiosConfig: AxiosRequestConfig, handleError: (error: any, showToast: boolean) => void, shouldToast: boolean = true) =>
    Axios(endPoint, axiosConfig)
        .then(res => {
            if (!res.data.is_success) {
                return Promise.reject(res);
            }
            return Promise.resolve(res.data);
        })
        .catch(error => {
            const isCancel: boolean = Axios.isCancel(error);
            const apiError: ApiError = ErrorLogger.toApiApiError({
                url: endPoint,
                axiosError: error.response || error,
                shouldToast: !isCancel && shouldToast,
            });
            // if (dispatch) {
            //     dispatch(ErrorLogger.logApiError(apiError));
            // }

            handleError(apiError, !isCancel && shouldToast);
            return Promise.reject(apiError);
        });

//#endregion

export enum UploadDataType {
    Files = "files",
    File = 'file',
    Document = 'document',
    Image = 'image',
    Video = 'video',
}
export interface UploadData {
    readonly type: UploadDataType | string;
    readonly value: File | Blob; // * OR Array<File>
}

export interface XHRRequestConfig extends AxiosRequestConfig {
    readonly token?: string;
    readonly params?: {};
    readonly dispatch?: (action?: any) => any; //! delete after refactor (not necessary)

    onDownloadProgress?: (progressEvent: UploadProgressEvent) => void;
    onUploadProgress?: (progressEvent: UploadProgressEvent) => void;

    //! on ERROR
    readonly shouldToast?: boolean;
    readonly customErrorCode?: string;
    readonly trustSuccess?: boolean;

    readonly withAuth?: boolean;

    readonly cts?: CancelTokenSource;
}

export class XHRRequest {
    public static get = <TResponse extends {}>(endPoint: string, requestConfig: XHRRequestConfig = {}): Promise<TResponse> => {
        return new Promise<TResponse>(async (resolve, reject) => {
            try {
                const config = await XHRRequest.getAxiosRequestConfig(requestConfig);
                const response: AxiosResponse = await Axios.get(endPoint, config);
                return XHRRequest.throwErrorIfNeeded(response, requestConfig)(resolve, reject);
            } catch (error) {
                reject(XHRRequest.throwError(endPoint, error, requestConfig));
            }
        });
    }

    public static post = <TResponse extends {}>(endPoint: string, bodyRequest: {}, requestConfig: XHRRequestConfig = {}): Promise<TResponse> => {
        return new Promise<TResponse>(async (resolve, reject) => {
            try {
                const config = await XHRRequest.getAxiosRequestConfig(requestConfig);
                const response: AxiosResponse = await Axios.post(endPoint, bodyRequest, config);
                return XHRRequest.throwErrorIfNeeded(response, requestConfig)(resolve, reject);
            } catch (error) {
                reject(XHRRequest.throwError(endPoint, error, requestConfig));
            }
        });
    }

    public static patch = <TResponse extends {}>(endPoint: string, bodyRequest: {}, requestConfig: XHRRequestConfig = {}): Promise<TResponse> => {
        return new Promise<TResponse>(async (resolve, reject) => {
            try {
                const config = await XHRRequest.getAxiosRequestConfig(requestConfig);
                const response: AxiosResponse = await Axios.patch(endPoint, bodyRequest, config);
                return XHRRequest.throwErrorIfNeeded(response, requestConfig)(resolve, reject);
            } catch (error) {
                reject(XHRRequest.throwError(endPoint, error, requestConfig));
            }
        });
    }

    public static put = <TResponse extends {}>(endPoint: string, bodyRequest: {}, requestConfig: XHRRequestConfig = {}): Promise<TResponse> => {
        return new Promise<TResponse>(async (resolve, reject) => {
            try {
                const config = await XHRRequest.getAxiosRequestConfig(requestConfig);
                const response: AxiosResponse = await Axios.put(endPoint, bodyRequest, config);
                return XHRRequest.throwErrorIfNeeded(response, requestConfig)(resolve, reject);
            } catch (error) {
                reject(XHRRequest.throwError(endPoint, error, requestConfig));
            }
        });
    }

    public static delete = <TResponse extends {}>(endPoint: string, requestConfig: XHRRequestConfig = {}): Promise<TResponse> => {
        return new Promise<TResponse>(async (resolve, reject) => {
            try {
                const config = await XHRRequest.getAxiosRequestConfig(requestConfig);
                const response: AxiosResponse = await Axios.delete(endPoint, config);
                return XHRRequest.throwErrorIfNeeded(response, requestConfig)(resolve, reject);
            } catch (error) {
                reject(XHRRequest.throwError(endPoint, error, requestConfig));
            }
        });
    }

    public static getRaw<ArrayBuffer>(url: string, requestConfig: XHRRequestConfig = {}): Promise<ArrayBuffer> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const config = await XHRRequest.getAxiosRequestConfig(requestConfig);
                const response: AxiosResponse<ArrayBuffer> = await Axios.get(url, config);
                resolve(response);
            } catch (error) {
                reject(XHRRequest.throwError(url, error, requestConfig));
            }
        });
    }

    public static upload<TResponse extends {}>(url: string, uploadData: UploadData, requestConfig: XHRRequestConfig = {}): Promise<TResponse> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const data = new FormData();
                data.append(uploadData.type, uploadData.value);
                const config = await XHRRequest.getAxiosRequestConfig(requestConfig);

                const response: AxiosResponse<ArrayBuffer> = await Axios.post(url, data, config);
                return XHRRequest.throwErrorIfNeeded(response, requestConfig)(resolve, reject);
            } catch (error) {
                reject(XHRRequest.throwError(url, error, requestConfig));
            }
        });
    }

    public static throwErrorIfNeeded = (response: AxiosResponse, requestConfig: XHRRequestConfig) => {
        return (resolve: (result: any) => void, reject: (error: any) => void) => {
            const { dispatch, shouldToast = true, trustSuccess = false } = requestConfig || {};
            if (!trustSuccess && !response.data.is_success) {
                const apiError: ApiError = ErrorLogger.toApiApiError({
                    url: response.config.url,
                    axiosError: response,
                    shouldToast
                });
                if (dispatch) {
                    dispatch(ErrorLogger.logApiError(apiError));
                } else {
                    ErrorLogger.showApiError(apiError, StoreProvider.getAppState());
                }
                return reject(response.data);
            }
            return resolve(response.data);
        }
    }

    public static throwError = (endPoint: string, axiosError: any, requestConfig: XHRRequestConfig): any => {
        const { dispatch, shouldToast = true, customErrorCode } = requestConfig || {};

        const isCancel: boolean = Axios.isCancel(axiosError);

        const apiError: ApiError = ErrorLogger.toApiApiError({
            url: endPoint,
            axiosError: axiosError.response || axiosError,
            shouldToast: !isCancel && shouldToast,
            customErrorCode
        });

        if (dispatch) {
            dispatch(ErrorLogger.logApiError(apiError));
        } else {
            ErrorLogger.showApiError(apiError, StoreProvider.getAppState());
        }
        return apiError;
    }

    //#region //* NESTED

    public static getAxiosRequestConfig = async ({
        withAuth=true, token, params, onUploadProgress, onDownloadProgress, cts, ...rest
    }: XHRRequestConfig) => {
        const tokenToUse = withAuth ? token || await StoreProvider.getToken() : undefined;
        const headers: Record<string, string> = tokenToUse ? { Authorization: tokenToUse } : {};

        const getProgress = (progressEvent: any): number => {
            //* percent from 0.0 to 1.0
            return Math.round((progressEvent.loaded * 100) / progressEvent.total) / 100;
        };

        const axiosRequestConfig: AxiosRequestConfig = {
            ...rest,
            headers,
            params,
            paramsSerializer: serializeRequestParameters,
            onUploadProgress: (progressEvent: any) => {
                if (onUploadProgress) {
                    onUploadProgress({ percent: getProgress(progressEvent) });
                }
            },
            onDownloadProgress: (progressEvent: any) => {
                if (onDownloadProgress) {
                    onDownloadProgress({ percent: getProgress(progressEvent) });
                }
            },
            cancelToken: cts ? cts.token : rest.cancelToken
        };
        return axiosRequestConfig;
    };
    
    public static toStringParams = (params?: {}): string => {
        return params ? qs.stringify(params, qsOptions) : '';
    };

    public static getRequestCanceler = (): CancelTokenSource => {
        return Axios.CancelToken.source();
    }

    //#endregion
}
