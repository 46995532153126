import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { useAppState } from '../lib/IdeLink.Web.Common/src/Hooks/useAppState';

export interface PrivateRouteProps {
    readonly component?: React.ComponentType;
    readonly hasAccess?: boolean;
    readonly children?: React.ReactNode;
}

export const PrivateRoute = ({ component: TargetComponent, hasAccess = true, ...rest }: PrivateRouteProps) => {
    const location: string = useLocation().pathname;
    const authState = useAppState().Authentication;
    const isAuthenticated = authState.get('isAuthenticated');

    return (
        <Route
            {...rest}
            render={() =>
                isAuthenticated && hasAccess ? (
                    TargetComponent ? (
                        <TargetComponent {...rest} />
                    ) : null
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};
