import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { useAppState } from '../../../../lib/IdeLink.Web.Common/src/Hooks/useAppState';
import { useViewState } from '../../../../lib/IdeLink.Web.Common/src/Hooks/ViewState';
import { Analytics } from '../../../../lib/IdeLink.Web.Common/src/services/analytics/Analytics';
import {
    AnalyticsRoutes,
    getAnalyticsRoute,
} from '../../../../lib/IdeLink.Web.Common/src/services/analytics/AnalyticsRoutes';
import { logger } from '../../../../lib/IdeLink.Web.Common/src/services/logger/logger';
import { FrontUserApi } from '../../../../services/user/FrontUserApi';

export interface ChangePasswordState {
    modified?: boolean;
    openDialog?: boolean;
    old_password?: string;
    new_password?: string;
    newPasswordConfirmation?: string;
}

export const useChangePasswordButtonController = () => {
    const i18n = useAppState().I18n;
    const { getState, onChange, update } = useViewState<ChangePasswordState>({});

    const clearState = () => update({ old_password: '', new_password: '', newPasswordConfirmation: '' });

    const updatePassword = async () => {
        const { old_password = '', new_password = '', newPasswordConfirmation } = getState();
        Analytics.log({ category: 'Clic', action: 'UpdatePassword', label: 'UpdatePassword' });

        if (!old_password || !new_password || !newPasswordConfirmation) {
            toast.error(<FormattedMessage id="profile.change.password.toast.missing.inputs" />, {
                position: 'bottom-right',
            });
            return;
        }

        if (new_password !== newPasswordConfirmation) {
            toast.error(<FormattedMessage id="profile.change.password.toast.confirmation.not.same" />, {
                position: 'bottom-right',
            });
            return;
        }

        try {
            await FrontUserApi.updatePassword({ old_password, new_password });
            Analytics.log({ route: getAnalyticsRoute(AnalyticsRoutes.CA_ROUTE_CHANGE_PW_SUCCESS) });
            toast.success(<FormattedMessage id="profile.change.password.toast.success" />, {
                position: 'bottom-right',
            });
            update({ old_password: '', new_password: '', newPasswordConfirmation: '', openDialog: false });
        } catch (error) {
            logger.error('error changing password ', error);
        }
    };

    return { i18n, state: getState(), events: { onChange, update, clearState, updatePassword } };
};
