import { Middleware, MiddlewareAPI } from 'redux';

import { AppState } from '../../app/AppState';
import {
    ActiveDirectoryAuthActions,
} from '../Auth/Services/Authentication/ActiveDirectory/ActiveDirectoryAuthActions';
import { EmailAuthActions } from '../Auth/Services/Authentication/Email/EmailAuthActions';
import { ImpersonateAuthActions } from '../Auth/Services/Authentication/Impersonate/ImpersonateAuthActions';
import { UserActions } from './UserActions';
import { GoogleAuthActions } from '../Auth/Services/Authentication/Google/GoogleAuthActions';

export const UserMiddleware: Middleware = ({ dispatch, getState }: MiddlewareAPI<any, AppState>) => next => (action: any) => {
    // ! exist in ThunkMiddleware
    // if (typeof action === 'function') {
    //     return action(dispatch, getState);
    // }
    if (action && action.type && (
        action.type === EmailAuthActions.LOGIN_EMAIL_SUCCESS ||
        action.type === EmailAuthActions.REFRESH_EMAIL_SUCCESS ||
        action.type === GoogleAuthActions.SET_REFRESH_TOKEN ||
        action.type === GoogleAuthActions.SET_TOKEN||
        action.type === ActiveDirectoryAuthActions.LOGIN_AD_SUCCESS ||
        action.type === ActiveDirectoryAuthActions.REFRESH_AD_SUCCESS ||
        action.type === ImpersonateAuthActions.LOGIN_IMPERSONATE_SUCCESS ||
        action.type === ImpersonateAuthActions.REFRESH_IMPERSONATE_SUCCES)
    ) {
        const res = next(action);
        dispatch(UserActions.retrieveAuthenticatedUserInfo());
        return res;
    }
    return next(action);
};
