import 'moment/locale/fr';
import 'react-toastify/dist/ReactToastify.css';

import Redux from 'redux';
import { Persistor } from 'redux-persist';

import React from 'react';
import { Provider } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import { EssentialInfo } from './lib/IdeLink.Web.Common/src/components/Templates/EssentialInfo/EssentialInfo';
import { IntlWrapper } from './lib/IdeLink.Web.Common/src/components/Templates/IntlWrapper/intlWrapper';
import { Loading } from './lib/IdeLink.Web.Common/src/scenes/Loading';
import { AuthGate } from './lib/IdeLink.Web.Common/src/components/Templates/AuthGate/AuthGate';
import { LazyLoader } from './lib/IdeLink.Web.Common/src/components/Templates/LazyLoader/LazyLoader';

export type AppWrapperProps = { store: Redux.Store; persistor: Persistor };

export const AppWrapper = ({ store, persistor, children }: React.PropsWithChildren<AppWrapperProps>) => (
    <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
            <IntlWrapper>
                <EssentialInfo>
                    <AuthGate>
                        <LazyLoader delay={3 * 1000}>
                            <div style={{ height: '100%' }}>
                                {children}
                                <ToastContainer
                                    hideProgressBar
                                    autoClose={8000}
                                    position={toast.POSITION.BOTTOM_RIGHT}
                                    style={{ width: 'auto' }}
                                />
                            </div>
                        </LazyLoader>
                    </AuthGate>
                </EssentialInfo>
            </IntlWrapper>
        </PersistGate>
    </Provider>
);
