import loglevel from 'loglevel';
import { EnvironmentHelper } from '../../Helpers/EnvironmentHelper';

const log = loglevel.getLogger('default-logger');

log.setDefaultLevel(log.levels.SILENT);
if (EnvironmentHelper.isDev()) {
    log.setLevel(log.levels.DEBUG);
    window.log = log;
}

export { log, log as logger, log as SimpleLogger };
