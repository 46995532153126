import { esCommons } from "./esCommons";
import { esCommunications } from "./esCommunications";
import { esDashboard } from "./esDashboard";
import { esEvents } from "./esEvents";
import { esFuncs } from "./esFuncs";
import { esGondoles } from "./esGondoles";
import { esLogin } from "./esLogin";
import { esOffers } from "./esOffers";
import { esOperations } from "./esOperations";
import { esPasswords } from "./esPasswords";
import { esProfile } from "./esProfile";
import { esSurveys } from "./esSurveys";
import { esToasts } from "./esToasts";

export const esMessages = {
    ...esCommons,
    ...esCommunications,
    ...esDashboard,
    ...esEvents,
    ...esFuncs,
    ...esGondoles,
    ...esLogin,
    ...esOffers,
    ...esOperations,
    ...esPasswords,
    ...esProfile,
    ...esSurveys,
    ...esToasts
};
