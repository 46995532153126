import { withFuncFactory } from '../../Factory';
import { XHRRequest } from '../../services/api';
import { UserMeResponse } from './Models/UserMe';


export class UserApi {
    public static GET_ME_URL = withFuncFactory(() => '/api-admin/v1/me');

    public static retrieveAuthenticatedUserInfo(token: string, dispatch?: any): Promise<UserMeResponse> {
        return XHRRequest.get<UserMeResponse>(UserApi.GET_ME_URL(), { token, dispatch });
    }
}

