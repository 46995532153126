import { frLogin } from '../../lib/IdeLink.Web.Common/src/modules/Auth/Locales/fr/frLogin';
import { frPasswords } from '../../lib/IdeLink.Web.Common/src/modules/Auth/Locales/fr/frPasswords';
import { frProfile } from './frProfile';
import { frFeedFuncs } from './frAllFeedFuncs';
import { frCommons, frToastMessages } from './frCommons';
import { frCommunications } from './frCommunications';
import { frDashboard } from './frDashboard';
import { frEvents } from './frEvents';
import { frGondoles } from './frGondoles';
import { frOperations } from './frOperations';
import { frCommonsOffers, frOffers } from './frOffers';
import { frSurveys } from './frSurveys';

export const frMessages = {
    ...frProfile,
    ...frLogin,
    ...frPasswords,

    ...frCommons,
    ...frToastMessages,

    ...frDashboard,

    ...frCommonsOffers,
    ...frOffers,
    ...frOperations,

    ...frCommunications,
    ...frEvents,
    ...frSurveys,
    ...frGondoles,

    ...frFeedFuncs, //* pour les feeds
};
