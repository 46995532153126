export const frPasswords = {
    'toast.success.password.forgot': 'Demande de réinitialisation envoyée',
    'toast.success.password.reset': 'Mot de passe réinitialisé',
    'toast.success.password.initialize': 'Mot de passe initialisé',

    // FORGOT

    'password.forgot.title': 'Mot de passe oublié',
    'password.forgot.label': 'Merci de renseigner votre adresse mail afin de réinitialiser votre mot de passe.',
    'password.forgot.inputEmailLabel': 'Email',
    'password.forgot.inputEmailEmpty': 'Veuillez saisir une adresse email valide',
    'password.forgot.submitLabel': 'Réinitialiser mon mot de passe',
    'password.forgot.goBackLabel': 'Retour à la connexion',
    'password.forgot.pageTitle': 'Réinitialisation mot de passe',

    // RESET

    'password.reset.title': 'Réinitialiser mon mot de passe',
    'password.reset.label':
        'Suite à votre demande de réinitialisation de mot de passe, merci de renseigner ici un nouveau mot de passe et de le confirmer.',
    'password.reset.inputPassword': 'Mot de passe',
    'password.reset.inputPasswordEmpty': 'Veuillez saisir un mot de passe',
    'password.reset.inputPasswordConfirmation': 'Confirmation mot de passe',
    'password.reset.inputPasswordConfirmationEmpty': 'Veuillez confirmer votre mot de passe',
    'password.reset.passwordsNotSame': 'Les mots de passe ne sont pas identiques',
    'password.reset.submitLabel': 'Réinitialiser',
    'password.reset.pageTitle': 'Réinitialiser mon mot de passe',
    'password.reset.success.message': 'Votre mot de passe a bien été réinitialisé !',
    'password.reset.ask.reset.message':
        'Le lien pour réinitialiser votre mot de passe semble avoir expiré. En cliquant sur le bouton ci-dessous vous pourrez redemander la réinitialisation de votre mot de passe.',
    'password.reset.ask.reset.label': 'Mot de passe oublié',

    // INITIALIZE

    'password.initialize.title': 'Initialiser mon mot de passe',
    'password.initialize.label':
        'Suite à la création de votre compte, merci de renseigner ici un mot de passe et de le confirmer.',
    'password.initialize.inputPassword': 'Mot de passe',
    'password.initialize.inputPasswordEmpty': 'Veuillez saisir un mot de passe',
    'password.initialize.inputPasswordConfirmation': 'Confirmation mot de passe',
    'password.initialize.inputPasswordConfirmationEmpty': 'Veuillez confirmer votre mot de passe',
    'password.initialize.passwordsNotSame': 'Les mots de passe ne sont pas identiques',
    'password.initialize.submitLabel': 'Initialiser',
    'password.initialize.pageTitle': 'Initialiser mon mot de passe',
    'password.initialize.success.message': 'Votre mot de passe a bien été initialisé !',
    'password.initialize.ask.initialize.message':
        "Le lien pour initialiser votre mot de passe semble avoir expiré. En cliquant sur le bouton ci-dessous vous pourrez demander l'envoi d'un nouvel email d'initialisation",
    'password.initialize.ask.initialize.label': 'Mot de passe oublié',
};
