import './SurveysTheme.scss';

import defaultImgSurvey from '../assets/default-img-survey-square.svg';
import emptyIconSurvey from '../assets/ic-survey-empty.svg';
import iconColorSurvey from '../assets/survey-color.svg';
import iconWhite from '../assets/survey.svg';
import { IdelinkModuleTheme } from '../../../xlink/configs/IdeLinkTheme';

export const SurveysTheme: IdelinkModuleTheme = {
    className: 'idelink-theme il-theme-surveys',
    backgroundColor: '##00427b', //? prussian-blue
    feedImage: defaultImgSurvey,
    emptyIcon: emptyIconSurvey,
    iconColor: iconColorSurvey,
    iconWhite,
};
