import './Profile.scss';

import React from 'react';

import { ChangePasswordButton } from './ChangePasswordButton/ChangePasswordButton';
import { ProfileListInfo } from './ProfileListInfo/ProfileListInfo';
import { UserInfoCard } from './UserInfoCard/UserInfoCard';

export const Profile = () => {
    return (
        <React.Fragment>
            <UserInfoCard classes={{ root: 'profile-profile-root' }} />
            <ChangePasswordButton />
            <ProfileListInfo />
        </React.Fragment>
    );
};
