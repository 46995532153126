import './LabelWithIcon.scss';

import classNames from 'classnames';
import React from 'react';

export enum LabelIconPosition {
    right = 'right',
    left = 'left',
    top = 'top',
    bottom = 'bottom',
}

export interface LabelWithIconProps {
    label?: React.ReactNode;
    content?: React.ReactNode;
    icon?: string;
    onClick?: () => void;
    position?: LabelIconPosition;
    classes?: { root?: string; icon?: string; iconContainer?: string; label?: string };
}

export const LabelWithIcon = ({
    label,
    content,
    onClick,
    icon,
    position = LabelIconPosition.left,
    classes,
}: LabelWithIconProps) => {
    const {
        root: rootClasses = '',
        iconContainer: iconContainerclasses = '',
        icon: iconClasses = '',
        label: labelClasses = '',
    } = classes || {};

    const enhancedRootClasses = classNames('LabelWithIcon-container', {
        clickable: onClick,
        'column-direction': position === 'top' || position === 'bottom',
        [rootClasses]: rootClasses,
    });

    const enhancedLabelClasses = classNames('LabelWithIcon-label', labelClasses);

    const renderIcon = () => {
        if (!icon) {
            return null;
        }

        const enhancedIconContainerClasses = classNames('icon-container', {
            'icon-right': position === 'right',
            'icon-left': position === 'left',
            'icon-top': position === 'top',
            'icon-bottom': position === 'bottom',
            [iconContainerclasses]: iconContainerclasses,
        });
        const enhancedIconClasses = classNames('icon', iconClasses);

        return (
            <div className={enhancedIconContainerClasses}>
                <img className={enhancedIconClasses} alt="label-icon" src={icon} />
            </div>
        );
    };

    const renderContent = () => {
        if (content) {
            return content;
        }
        return <div className={enhancedLabelClasses}>{label}</div>;
    };

    return (
        <div className={enhancedRootClasses} onClick={onClick}>
            {position === 'left' || position === 'top' ? renderIcon() : null}
            {renderContent()}
            {position === 'right' || position === 'bottom' ? renderIcon() : null}
        </div>
    );
};
