import React, { HTMLAttributes } from 'react';

import icCloseWhite from './icons/close/ic-close-white.svg';
import icCloseBlack from './icons/close/ic-close-black.svg';

import icCrossWhite from './icons/cross-white.svg';
import icCrossGrey from './icons/cross-grey.svg';

import icSearchGrey from './icons/search-grey.svg';

import icCalendar from './icons/calendar.svg';
import icClock from './icons/clock.svg';
import icRepeat from './icons/repeat.svg';
import icDragnDrop from './icons/dragndrop.svg';

import icCheck from './icons/ic-check.svg';

import icExpandLessGrey from './icons/expand-less-grey.svg';
import icExpandMoreGrey from './icons/expand-more-grey.svg';

import logoIdelink from './logos/logo_idelink_franchise.svg';

import icEye from './icons/eye.svg';
import icPen from './icons/pen.svg';
import icCamera from './icons/camera.svg';
import icTrash from './icons/trash.svg';

import icPdfFile from './icons/pdf.svg';

import icVideoFile from './icons/videofile.svg';

import icRbUnselected from './icons/ic-rb-unselected.svg';
import icRbSelected from './icons/ic-rb-selected.svg';

import icLeft from './icons/arrows/left.svg';
import icRight from './icons/arrows/right.svg';

export interface IconProps extends HTMLAttributes<HTMLImageElement> {
    src?: string;
    alt?: string;
}

export const Icon = ({ src, alt, ...attrs }: IconProps): React.ReactElement => {
    return <img {...attrs} src={src} alt={alt} />;
};

export class Assets {
    public static IcCloseWhite = () => icCloseWhite;
    public static IcCloseBlack = () => icCloseBlack;

    public static IconCrossWhite = () => icCrossWhite;
    public static IconCrossGrey = () => icCrossGrey;
    public static IconSearchGrey = () => icSearchGrey;

    public static IconCheck = () => icCheck;

    public static IconCalendar = () => icCalendar;
    public static IconClock = () => icClock;
    public static IconRepeat = () => icRepeat;
    public static IconDragnDrop = () => icDragnDrop;

    public static IconExpandLessGrey = () => icExpandLessGrey;
    public static IconExpandMoreGrey = () => icExpandMoreGrey;

    public static IconEye = () => icEye;
    public static IconPen = () => icPen;
    public static IconCamera = () => icCamera;
    public static IconTrash = () => icTrash;
    public static IconVideoFile = () => icVideoFile;
    public static IconPdfFile = () => icPdfFile;

    public static LogoIdelinkFranchisePath = () => logoIdelink;

    public static IconRbSelected = (props?: IconProps) => <Icon src={icRbSelected} {...props} />;
    public static IconRbUnselected = (props?: IconProps) => <Icon src={icRbUnselected} {...props} />;

    public static IconLeft = (props?: IconProps) => <Icon src={icLeft} {...props} />;
    public static IconRight = (props?: IconProps) => <Icon src={icRight} {...props} />;
}
