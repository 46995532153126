import './EventsTheme.scss';

import { IdelinkModuleTheme } from '../../../xlink/configs/IdeLinkTheme';
import defaultImgEvent from '../assets/default-img-event-square.svg';
import iconColorEvent from '../assets/event-color.svg';
import iconWhite from '../assets/event.svg';
import emptyIconEvent from '../assets/ic-event-empty.svg';

export const EventsTheme: IdelinkModuleTheme = {
    className: 'idelink-theme il-theme-events',
    backgroundColor: '#5bd7d9', //? seafoam-blue
    feedImage: defaultImgEvent,
    emptyIcon: emptyIconEvent,
    iconColor: iconColorEvent,
    iconWhite,
};
