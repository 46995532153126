import './CustomTooltip.scss';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

export interface CustomTooltipProps {
    title: React.ReactNode;
    children: React.ReactNode;
    className?: string;
}

export const CustomTooltip = ({ title, className = '', children }: CustomTooltipProps) => {
    const enhancedTitleClasses = classNames('CustomTooltip-title', { [className]: className });
    if (!title) {
        return <div className={enhancedTitleClasses}>{children}</div>;
    }

    return (
        <Tooltip title={title} enterDelay={500}>
            <div className={enhancedTitleClasses}>{children}</div>
        </Tooltip>
    );
};
