import './navbarItem.scss';

import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { withFactory } from '../../../../Factory';
import { LabelWithIcon } from '../../../Labels/LabelWithIcon';
import { CustomTooltip } from '../../../Tooltips/CustomTooltip/CustomTooltip';
import { Link } from 'react-router-dom';

export interface NavbarModuleItem {
    readonly icon?: string;
    readonly label?: React.ReactNode;
    readonly route: string;
    readonly disabled?: boolean;
    readonly hide?: boolean;
    readonly childRoutes?: Array<string>;

    readonly onMouseOver: () => void;
    readonly routeMatcher?: (route: any) => boolean;
}

export interface NavBarItemProps {
    readonly item: NavbarModuleItem;
    readonly selected: boolean;
    readonly classes?: {
        link?: string;
        icon?: string;
        navbarIcon?: undefined;
    };
}

const NavBarItem = ({ item, selected, classes = {} }: NavBarItemProps) => {
    if (item.hide) {
        return null;
    }

    const imgClassNames = classnames(
        'navbarcategory-item',
        {
            disabled: item.disabled,
            selected,
        },
        classes.link
    );

    const iconClassName = classnames('icon-container', classes.icon);

    if (item.disabled) {
        return (
            <CustomTooltip title={<FormattedMessage id="navbar.no.permission.label" />}>
                <div className={imgClassNames}>
                    <LabelWithIcon icon={item.icon} label={item.label} classes={{ iconContainer: iconClassName }} />
                </div>
            </CustomTooltip>
        );
    }
    return (
        <Link to={item.route} className={imgClassNames} onMouseOver={item.onMouseOver}>
            <LabelWithIcon icon={item.icon} label={item.label} classes={{ iconContainer: iconClassName }} />
        </Link>
    );
};

const NavbarItemFactory = withFactory(NavBarItem);

export { NavBarItem, NavbarItemFactory };
