import './activeDirectoryConnect.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Loader } from '../../../../../components/Loader';

export interface ActiveDirectoryConnectProps {
    loading?: boolean;
    login: () => Promise<void>;
}

export const ActiveDirectoryConnect = ({ login: propLogin, loading }: ActiveDirectoryConnectProps) => {
    const history = useHistory();

    const login = () => propLogin().then(() => history.push('/'));

    if (loading) {
        return <Loader />;
    }
    return (
        <div className="active-dir-connect-root">
            <div className="active-dir-message">{<FormattedMessage id="login.active.directory.message" />}</div>
            <div role="button" className="active-dir-login-btn" onClick={login}>
                <div className="label-container">
                    <div className="label">{`${(<FormattedMessage id="login.active.directory.btnLabel" />)} ${(
                        <FormattedMessage id="login.active.directory.org.name" />
                    )}`}</div>
                </div>
            </div>
        </div>
    );
};
