import { withFuncFactory } from '../../lib/IdeLink.Web.Common/src/Factory';
import anymatch from 'anymatch';


export class NavbarUtils {
    public static getWhitelistedShopSelectionRoutes = () => [];

    /**
     * @param {String} pathname - the current URL path
     * @param {String[]} whitelist - the array of strings patterns that the shop selection is allowed on
     * @returns {Boolean} if should display the shop selection
     */
    public static displayShopSelection = ({ pathname = '/', whitelist = [] }) => anymatch(whitelist, pathname);
}

export const NavbarUtilsFactory = {
    getWhitelistedShopSelectionRoutes: withFuncFactory(NavbarUtils.getWhitelistedShopSelectionRoutes),
    displayShopSelection: withFuncFactory(NavbarUtils.displayShopSelection),
};
