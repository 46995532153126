import { createLogger } from 'redux-logger';
import { logger } from './logger';
import { Middleware } from 'redux';
import { EnvironmentHelper } from '../../Helpers/EnvironmentHelper';

export class ReduxLogger {

    public static stateTransformer = (state: { [field: string]: any }) => {
        const newState: { [field: string]: any } = {};

        for (let i of Object.keys(state)) {
            if (ReduxLogger.isCollection(state[i])) {
                newState[i] = state[i].toJS();
            } else {
                newState[i] = state[i];
            }
        }
        return newState;
    };

    static isCollection (maybeCollection: any) {
        return Boolean(maybeCollection && maybeCollection['@@__IMMUTABLE_ITERABLE__@@']);
    }

    public static reduxLogger = () => createLogger({
        predicate: (getState, action) => !action.type.includes('@@router'),
        stateTransformer: ReduxLogger.stateTransformer,
        logger,
    });

    public static withMiddlewares = (middlewares: Array<Middleware> = []) => {
        if (EnvironmentHelper.isDev()) {
            const reduxLogger: any = createLogger({
                predicate: (getState, action) => !action.type.includes('@@router'),
                stateTransformer: ReduxLogger.stateTransformer,
            });
            middlewares.push(reduxLogger);
        }
        return middlewares;
    };
}
