import { getApiRoute } from '../../configs/ApiRoutesMap';
import { Analytics } from '../../lib/IdeLink.Web.Common/src/services/analytics/Analytics';
import {
    AnalyticsRoutesMap,
    getAnalyticsRoutesFactory,
} from '../../lib/IdeLink.Web.Common/src/services/analytics/AnalyticsRoutes';
import { OffersModule } from '../../modules/Offers/OffersModule';

export enum FrontAnalyticsRoutes {
    FA_ROUTE_PACKAGED_ORDER_LIST = 'common_analytics_route_packaged_orders_list',
    FA_ROUTE_PACKAGED_ORDER_DETAILS = 'common_analytics_route_packaged_orders_details',
    FA_ROUTE_PACKAGED_ORDER_DETAILS_PRODUCT = 'common_analytics_route_packaged_orders_details_product',
    FA_ROUTE_PACKAGED_ORDER_DETAILS_INTERSTITIAL = 'common_analytics_route_packaged_orders_details_product_interstitial',

    FA_ROUTE_OPS_LIST = 'analytics_ops_list',
    FA_ROUTE_OPS_DETAILS_OP = 'analytics_ops_details_op',
    FA_ROUTE_OPS_DETAILS_OP_PRODUCT = 'analytics_ops_details_op_product',
    FA_ROUTE_OPS_DETAILS_OP_INTERSTITIAL = 'analytics_ops_details_op_interstitial',
    FA_ROUTE_OPS_DETAILS_ENG = 'analytics_ops_details_engagement',
    FA_ROUTE_OPS_DETAILS_ENG_PRODUCT = 'analytics_ops_details_engagement_product',
    FA_ROUTE_OPS_DETAILS_ENG_INTERSTITIAL = 'analytics_ops_details_engagement_interstitial',

    FA_ROUTE_SURVEYS_LIST = 'analytics_surveys_list',
    FA_ROUTE_SURVEYS_DETAILS = 'analytics_surveys_details',
    FA_ROUTE_SURVEYS_DETAILS_INTERSTITIAL = 'analytics_surveys_details_interstitial',

    FA_ROUTE_GONDOLES_LIST = 'analytics_gondoles_list',
    FA_ROUTE_GONDOLES_DETAILS = 'analytics_gondoles_details',
    FA_ROUTE_GONDOLES_DETAILS_INTERSTITIAL = 'analytics_gondoles_details_interstitial',

    FA_ROUTE_EVENTS_LIST = 'analytics_events_list',
    FA_ROUTE_EVENTS_DETAILS = 'analytics_events_details',
    FA_ROUTE_EVENTS_DETAILS_INTERSTITIAL = 'analytics_events_details_interstitial',

    FA_ROUTE_COMMS_LIST = 'analytics_comms_list',
    FA_ROUTE_COMMS_DIGITAL_MARKETINGS_DETAILS = 'analytics_comms_digital_marketings_details',
    FA_ROUTE_COMMS_DIGITAL_MARKETINGS_INTERSTITIAL = 'analytics_comms_digital_marketings_interstitial',
    FA_ROUTE_COMMS_LEAFLETS_DETAILS = 'analytics_comms_leaflets_details',
    FA_ROUTE_COMMS_LEAFLETS_INTERSTITIAL = 'analytics_comms_leaflets_interstitial',
    FA_ROUTE_COMMS_PARTNERSHIPS_DETAILS = 'analytics_comms_partnerships_details',
    FA_ROUTE_COMMS_PARTNERSHIPS_INTERSTITIAL = 'analytics_comms_partnerships_interstitial',

}

export const GET_FRONT_ANALYTICS_ROUTES_MAP = (): AnalyticsRoutesMap => ({
    [FrontAnalyticsRoutes.FA_ROUTE_PACKAGED_ORDER_LIST]: () => getApiRoute(OffersModule.OFFERS_MODULE_CODE),
    [FrontAnalyticsRoutes.FA_ROUTE_PACKAGED_ORDER_DETAILS]: () => `${getApiRoute(OffersModule.OFFERS_MODULE_CODE)}/{id}`,
    [FrontAnalyticsRoutes.FA_ROUTE_PACKAGED_ORDER_DETAILS_PRODUCT]: () => `${getApiRoute(OffersModule.OFFERS_MODULE_CODE)}/{id}/product/{productId}`,
    [FrontAnalyticsRoutes.FA_ROUTE_PACKAGED_ORDER_DETAILS_INTERSTITIAL]: () => `${getApiRoute(OffersModule.OFFERS_MODULE_CODE)}/{id}/interstitial`,

    [FrontAnalyticsRoutes.FA_ROUTE_OPS_LIST]: () => '/operations',
    [FrontAnalyticsRoutes.FA_ROUTE_OPS_DETAILS_OP]: () => `/operations/{id}`,
    [FrontAnalyticsRoutes.FA_ROUTE_OPS_DETAILS_OP_PRODUCT]: () => `/operations/{id}/product/{productId}`,
    [FrontAnalyticsRoutes.FA_ROUTE_OPS_DETAILS_OP_INTERSTITIAL]: () => `/operations/{id}/interstitial`,

    [FrontAnalyticsRoutes.FA_ROUTE_OPS_DETAILS_ENG]: () => `/operations/{id}`,
    [FrontAnalyticsRoutes.FA_ROUTE_OPS_DETAILS_ENG_PRODUCT]: () => `/operations/{id}/product/{productId}`,
    [FrontAnalyticsRoutes.FA_ROUTE_OPS_DETAILS_ENG_INTERSTITIAL]: () => `/operations/{id}/interstitial`,

    [FrontAnalyticsRoutes.FA_ROUTE_SURVEYS_LIST]: () => '/surveys',
    [FrontAnalyticsRoutes.FA_ROUTE_SURVEYS_DETAILS]: () => `/surveys/{id}`,
    [FrontAnalyticsRoutes.FA_ROUTE_EVENTS_DETAILS_INTERSTITIAL]: () => `/surveys/{id}/interstitial`,

    [FrontAnalyticsRoutes.FA_ROUTE_EVENTS_LIST]: () => '/happenings',
    [FrontAnalyticsRoutes.FA_ROUTE_EVENTS_DETAILS]: () => 'happenings/{id}',
    [FrontAnalyticsRoutes.FA_ROUTE_SURVEYS_DETAILS_INTERSTITIAL]: () => 'happenings/{id}/interstitial',

    [FrontAnalyticsRoutes.FA_ROUTE_COMMS_LIST]: () => '/communication',
    [FrontAnalyticsRoutes.FA_ROUTE_COMMS_DIGITAL_MARKETINGS_DETAILS]: () => '/communication/digitalmarketing/{id}',
    [FrontAnalyticsRoutes.FA_ROUTE_COMMS_DIGITAL_MARKETINGS_INTERSTITIAL]: () => '/communication/digitalmarketing/{id}/interstitial',
    [FrontAnalyticsRoutes.FA_ROUTE_COMMS_LEAFLETS_DETAILS]: () => '/communication/leaflets/{id}',
    [FrontAnalyticsRoutes.FA_ROUTE_COMMS_LEAFLETS_INTERSTITIAL]: () => '/communication/leaflets/{id}/interstitial',
    [FrontAnalyticsRoutes.FA_ROUTE_COMMS_PARTNERSHIPS_DETAILS]: () => '/communication/partnership/{id}',
    [FrontAnalyticsRoutes.FA_ROUTE_COMMS_PARTNERSHIPS_INTERSTITIAL]: () => '/communication/partnership/{id}/interstitial',
});

export class FrontAnalytics {
    public static initialize = () => {
        getAnalyticsRoutesFactory.register('**', GET_FRONT_ANALYTICS_ROUTES_MAP);
        Analytics.initialize();
    }
}
