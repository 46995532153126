import React from 'react';

import { ButtonFactory } from '..';
import { ButtonProps } from '../Button';

export interface ButtonGroupProps {
    cLocation?: string;
    actions?: Array<ButtonProps>;
}

export const ButtonGroup = ({ cLocation = '/', actions = [] }: ButtonGroupProps) => {
    if (!actions || !actions.length) {
        return null;
    }
    return (
        <React.Fragment>
            {actions.map((action, index) => (
                <ButtonFactory {...action} key={index} cLocation={`${cLocation}ButtonGroup/`} />
            ))}
        </React.Fragment>
    );
};
