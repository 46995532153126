import './ProfileListInfo.scss';

import ChevronRight from '@material-ui/icons/ChevronRight';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppState } from '../../../../lib/IdeLink.Web.Common/src/Hooks/useAppState';
import { Analytics } from '../../../../lib/IdeLink.Web.Common/src/services/analytics/Analytics';
import {
    AnalyticsRoutes,
    getAnalyticsRoute,
} from '../../../../lib/IdeLink.Web.Common/src/services/analytics/AnalyticsRoutes';
import { ShopForUserWithRoles } from '../../../../models/ShopForUser';

export const ProfileListInfo = () => {
    const shops: Array<ShopForUserWithRoles> = useAppState().MyShops.get('my_shops') || [];
    const hasShops = Boolean(shops.length);

    return (
        <React.Fragment>
            <div className="pli-item pli-header border border-full">
                <FormattedMessage id="profile.my.shops" />
            </div>

            {hasShops &&
                shops.map(shop => (
                    <div key={shop.id} className="pli-item pli-row border border-small">
                        {shop.name}
                    </div>
                ))}

            {!hasShops && (
                <div className="pli-item pli-row pli-no-shops">
                    <FormattedMessage id="profile.no.shops" />
                </div>
            )}
            <div className="pli-item pli-header border border-full about-header">
                <FormattedMessage id="profile.about" />
            </div>
            <ProfileLinkItem
                title={<FormattedMessage id="profile.licenses" />}
                href="/licences.html"
                onClick={() => Analytics.log({ route: getAnalyticsRoute(AnalyticsRoutes.CA_ROUTE_LICENSES) })}
            />
            <ProfileLinkItem
                title={<FormattedMessage id="profile.eula" />}
                href="/cgu.html"
                onClick={() => Analytics.log({ route: getAnalyticsRoute(AnalyticsRoutes.CA_ROUTE_CGU) })}
            />
        </React.Fragment>
    );
};

export type ProfileLinkItemProps = { title: React.ReactNode; href: string; onClick: () => void };

const ProfileLinkItem = ({ title, href, onClick }: ProfileLinkItemProps) => {
    return (
        <a
            className="pli-item pli-row border border-small"
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClick}
        >
            {title}
            <ChevronRight className="pli-link-chevron" />
        </a>
    );
};
