export const frProfile = {
    'toast.apiErrors.WEAK_PASSWORD': 'Le mot de passe est trop faible',
    'toast.apiErrors.INVALID_OLD_PASSWORD': "Votre ancien mot de passe n'est pas valide",

    // NOTIFICATIONS

    'profile.email_notifications_enabled.label': 'S’abonner aux notifications par mail',
    'profile.email.notifications.enabled.success': 'Notifications activées',
    'profile.email.notifications.disabled.success': 'Notifications désactivées',

    'profile.my.shops': 'MES CENTRES',
    'profile.no.shops': "Vous n'avez pas de centres ",

    // CHANGE PASSWORD

    'profile.change.password.button': 'Changer le mot de passe',

    'profile.change.password.dialog.title': 'Changement de mot de passe',

    'profile.password.old.label':
        'Veuillez saisir votre ancien mot de passe pour pouvoir le modifier',
    'profile.password.old.placeholder': 'Ancien mot de passe',

    'profile.password.new.label': 'Saisissez votre nouveau mot de passe',
    'profile.password.new.placeholder': 'Nouveau mot de passe',

    'profile.password.confirmation.label': 'Validez votre nouveau mot de passe',
    'profile.password.confirmation.placeholder': 'Validation nouveau mot de passe',

    'profile.change.password.dialog.button.submit.label': 'Ok',

    'profile.change.password.toast.missing.inputs': 'Un ou plusieurs champs ne sont pas remplies',
    'profile.change.password.toast.confirmation.not.same': 'Les nouveaux mots de passe ne sont pas identiques',

    'profile.change.password.toast.success': 'Mot de passe changé !',

    // PROFILE OTHER
    'profile.about': 'À PROPOS',
    'profile.licenses': 'Licences',
    'profile.eula': 'CGU',
};
