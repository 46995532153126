import { Route } from 'react-router-dom';

import { RouteItem } from '../../types/RouteItem';
import { LoginPageLoading } from './Pages/LoginPage/LoginPageLoading';
import { MagicLinkPageLoading } from './Pages/MagicLink/MagicLinkPageLoading';
import { ForgottenPasswordPageLoading } from './Pages/Password/ForgottenPassword/ForgottenPasswordPageLoading';
import { InitializePasswordPageLoading } from './Pages/Password/InitializePassword/InitializePasswordPageLoading';
import { ResetPasswordPageLoading } from './Pages/Password/ResetPassword/ResetPasswordPageLoading';

export class AuthModule {
    public static AUTH_LOGIN: string = '/login';
    public static AUTH_PASSWORD_FORGOT: string = '/password/forgot';
    public static AUTH_PASSWORD_RESET: string = '/passwords/reset/:email/:validation_code';
    public static AUTH_PASSWORD_INITIALIZE: string = '/passwords/initialize/:email/:validation_code';

    //! ONLY FOR IRRITALK
    public static AUTH_MAGIC_LINK: string = '/magic/:token';

    public static getLoginRoute = (): RouteItem => ({
        render: Route,
        props: {
            path: AuthModule.AUTH_LOGIN,
            component: LoginPageLoading,
        },
    });

    public static getForgottenPasswordRoute = (): RouteItem => ({
        render: Route,
        props: {
            path: AuthModule.AUTH_PASSWORD_FORGOT,
            component: ForgottenPasswordPageLoading,
        },
    });

    public static getResetPasswordRoute = (): RouteItem => ({
        render: Route,
        props: {
            path: AuthModule.AUTH_PASSWORD_RESET,
            component: ResetPasswordPageLoading,
        },
    });

    public static getInitializePassword = (): RouteItem => ({
        render: Route,
        props: {
            path: AuthModule.AUTH_PASSWORD_INITIALIZE,
            component: InitializePasswordPageLoading,
        },
    });

    public static getAuthRoutes = (): Array<RouteItem> => [
        AuthModule.getLoginRoute(),
        AuthModule.getForgottenPasswordRoute(),
        AuthModule.getResetPasswordRoute(),
        AuthModule.getInitializePassword(),

        //AuthModule.getMagicLinkRoute({}),
    ];

    public static getMagicLinkRoute = ({ render, props }: any) => ({
        render: render || Route,
        props: {
            path: AuthModule.AUTH_MAGIC_LINK,
            component: MagicLinkPageLoading,
            ...props,
        },
    });
}
