import { getApiRoutesMapFactory } from '../lib/IdeLink.Web.FrontOffice/src/configs/ApiRoutesMap';
import { AuthModule } from '../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/AuthModule';
import { RouteItem } from '../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/types/RouteItem';
import { CommunicationsModule } from '../lib/IdeLink.Web.FrontOffice/src/modules/Communications/CommunicationsModule';
import { DashboardModule } from '../lib/IdeLink.Web.FrontOffice/src/modules/Dashboard/DashboardModule';
import { EventsModule } from '../lib/IdeLink.Web.FrontOffice/src/modules/Events/EventsModule';
import { GondolesModule } from '../lib/IdeLink.Web.FrontOffice/src/modules/Gondoles/GondolesModule';
import { OffersModule } from '../lib/IdeLink.Web.FrontOffice/src/modules/Offers/OffersModule';
import { OperationsModule } from '../lib/IdeLink.Web.FrontOffice/src/modules/Operations/OperationsModule';
import { SurveysModule } from '../lib/IdeLink.Web.FrontOffice/src/modules/Surveys/SurveysModule';

export class FluizyRouteConfigs {

    public static getAppRoutes = (): Array<RouteItem> => [
        ...AuthModule.getAuthRoutes(),

        //* MODULES
        DashboardModule.getDashboardRoute(),
        ...OffersModule.getPackagedOrdersRoutes(),
        ...OperationsModule.getOperationsRoutes(),
        ...CommunicationsModule.getCommunicationRoutes(),
        ...EventsModule.getEventRoutes(),
        ...SurveysModule.getSurveysRoutes(),
        ...SurveysModule.getSurveysRoutes(),
        ...GondolesModule.getGondolesRoutes(),
    ];

    public static GET_API_ROUTE_MAPPING = () => ({ [OffersModule.OFFERS_MODULE_CODE]: '/gooddeals' });

    public static initApiRouteMappingConfig = () => {
        getApiRoutesMapFactory.register('**', FluizyRouteConfigs.GET_API_ROUTE_MAPPING);
    };
}

