export const frOffers = {
    'offers.navbar': 'Bons Plans',
    'offers.title': 'Bons plans',

    'toast.apiErrors.ONLY_ONE_ORDER_ALLOWED':
        "Vous ne pouvez faire qu'une seule commande. Merci de modifier votre commande actuelle.",

    'offer.list.emptyLabel': "Il n'y a pas de bons plans pour le moment",
    'offer.details.notFound': 'Aucun bon plan trouvé',

    //! OPERATION ONLY
    'offer.details.product.dialog.seen_in_prospectus': 'Présent dans le prospectus',
    'offer.details.product.dialog.product_highlighted_4x3': 'Produit mis en avant en 4x3',
    'offer.details.product.dialog.product_highlighted_80x120': 'Produit mis en avant en 80x120',

    'offer.details.product.dialog.additionnalInfos': 'Information supplémentaire : {infos}',

    //! OPERATION ONLY
    'offer.product.category.Product': 'Produit',
    'offer.product.category.Box': 'BOX',
    'offer.product.category.Palette': 'Palette',
    'offer.product.category.TG': 'TG',

    'offer.details.product.item.quantity_minimum': 'Qté mini',
    'offer.details.product.item.quantity_maximum': 'Qté maxi',
    'offer.details.product.item.quantity_buy_by_multiple_of': 'Multiple de',

    'offer.details.product.dialog.quantity_minimum': 'Nb mini',
    'offer.details.product.dialog.quantity_maximum': 'Nb maxi',
    'offer.details.product.dialog.quantity_buy_by_multiple_of': 'Multiple de',

    'offer.details.product.dialog.quantities.title': 'Nombre de Produit',

    'offer.details.product.box.quantity': 'Quantité',
    'offer.details.product.box.button.quantity_recommendation.apply': 'Appliquer la préco : x{quantity_recommendation}',
    'offer.details.product.box.label.accept_tg': 'Je souhaite recevoir cette TG ?',

    'offer.details.button.quantities_recommendation.apply': 'Appliquer toutes les préconisation',

    'offer.details.product.box.label.recommendation_tg':
        '{value, plural, =0 {Préco : Non} =1 {Préco : Oui}  other {Préco : - }}',
    'offer.details.product.box.tg.label': '{  value, plural, =0 {Non} =1 {Oui}  other { - } }',

    'offer.details.delivery_week.no_info': "Pas d'informations disponibles sur la semaine de livraison",

    'packaged.order.label': 'Bon plan',
    'packaged.order.details.title': 'Détails Bon Plan',
    'packaged.order.feed.total_price': 'Montant commandé :',

    'offer.product.error.quantity.missing.title': 'Quantité non renseigné',
    'offer.product.error.quantity.missing.description':
        "Vous devez saisir une quantité pour le produit {title} ({code}) n'est pas valide",
    'offer.product.error.quantity.missing.toast': 'Vous devez saisir une quantité',

    'offer.product.error.quantity.minimum.title': 'Quantité non valide',
    'offer.product.error.quantity.minimum.description':
        "La quantité saisie pour le produit {title} ({code}) n'est pas valide. La quantité minimale est {quantity}.",
    'offer.product.error.quantity.minimum.toast':
        "La quantité saisie n'est pas valide. La quantité minimale est {quantity}",

    'offer.product.error.quantity.maximum.title': 'Quantité non valide',
    'offer.product.error.quantity.maximum.description':
        "La quantité saisie pour le produit {title} ({code}) n'est pas valide. La quantité maximale est {quantity}.",
    'offer.product.error.quantity.maximum.toast':
        "La quantité saisie n'est pas valide. La quantité maximale est {quantity}",

    'offer.product.error.quantity.multiple_of.title': 'Quantité non valide',
    'offer.product.error.quantity.multiple_of.description':
        "La quantité saisie pour le produit {title} ({code}) n'est pas valide. La quantité doit être un multiple de {quantity}.",
    'offer.product.error.quantity.multiple_of.toast':
        "La quantité saisie n'est pas valide. La quantité doit être un multiple de {quantity}",

    'offer.validation.box.total_price.title': 'Prix total',
    'offer.validation.dialog.total_price.label': 'Total commandé :',
};

//! STRINGS USED IN OFFERS (GoodDeals) AND OPERATIONS
export const frCommonsOffers = {
    'operation.details.operation.validate': 'Valider engagement',
    'operation.details.engagement.validate': 'Valider engagement',
    'packaged.order.details.validate': 'Valider engagement',

    'offer.details.product.dialog.reference': 'Ref. {reference}',
    'offer.details.product.dialog.normal_price': 'Prix normal',
    'offer.details.product.dialog.price': 'Prix promo',
    'offer.details.product.dialog.bought_price': 'PA',
    'offer.details.product.dialog.sell_price': 'PVC',
    'offer.details.product.dialog.odr_price': 'Après ODR',

    'offer.details.product.dialog.empty_price': '-',

    'offer.details.product.seeOrderedProducts': 'Voir les produits commandés < 12 derniers mois : {count}',
    'offer.details.product.last_3_orders_for_this_product': '3 dernières commandes de ce produit',

    'offer.last_3_orders.table.header.name': 'Plans de vente',
    'offer.last_3_orders.table.header.quantity': 'Quantité',
};
