export const esLogin = {
    'login.author': 'Albert Einstein, el mundo tal como lo veo, 1931',
    'login.cgu.label.cgu': 'Condiciones generales de usos',
    'login.cgu.label.read': 'Leer',
    'login.citation':
        '“La experiencia más hermosa que podemos tener es el misterio. Es la principal emoción que se encuentra en la cuna del verdadero arte y la verdadera ciencia ".',
    'login.google.btnLabel': 'Google Connect',
    'login.google.label': 'Bienvenido a su solicitud de Midaslink. Para conectarse, use su cuenta de Google.',
    'login.google.title': 'Conexión',
    'login.impersonate.btnLabel': 'Conexión de mantenimiento',
    'login.impersonate.dialog.inputPlaceholder': 'Ingrese el código de código',
    'login.impersonate.dialog.title': 'Conexión de mantenimiento',
    'login.impersonate.error': 'El código PIN informado parece estar mal.',
    'login.pageTitle': 'Conexión',
    'login.username.btnLabel': 'Conectarse',
    'login.username.error':
        'Los identificadores informados parecen erroneos. Vuelva a intentarlo o restablezca su contraseña a través de "¿contraseña olvidada?" ».',
    'login.username.inputEmailEmpty': 'Por favor, introduce una dirección de correo electrónico válida',
    'login.username.inputPasswordEmpty': 'Por favor introduzca su contraseña',
    'login.username.label': 'Conéctese con sus identificadores.',
    'login.username.labelPassword': 'Contraseña',
    'login.username.labelUsername': 'Correo electrónico',
    'login.username.passForgotLabel': '¿Contraseña olvidada?',
    'login.username.title': 'Conexión',
};