import './connectBox.scss';

import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import React from 'react';

import { withFactory } from '../../../../../Factory';

export interface ConnectBoxProps {
    className?: string;
    children?: React.ReactNode;
}

export const ConnectBox = ({ children, className = '' }: ConnectBoxProps) => (
    <Paper className={classNames('connect-container', { [className]: className })} elevation={2}>
        {children}
    </Paper>
);

export const ConnectBoxFactory = withFactory(ConnectBox);
