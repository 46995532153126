import './ProfileHeaderMenu.scss';

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Analytics } from '../../../../../../services/analytics/Analytics';
import { useCommonConfig } from '../../../../../../configs/CommonConfig';
import { withFactory } from '../../../../../../Factory';
import { Loader } from '../../../../../Loader';
import { AnalyticsRoutes, getAnalyticsRoute } from '../../../../../../services/analytics/AnalyticsRoutes';

export interface ProfileHeaderMenuProps {
    readonly loading: boolean;
    readonly menulabel: string;
    readonly onLogout: () => void;
}

export const ProfileHeaderMenu = ({ loading, menulabel, onLogout }: ProfileHeaderMenuProps) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const anchorEl = React.useRef(null);

    const handleClickButton = () => {
        setOpen(true);
        Analytics.log({ route: getAnalyticsRoute(AnalyticsRoutes.CA_ROUTE_PROFILE) });
    };

    const handleClose = () => {
        setOpen(false);
        Analytics.log({ route: getAnalyticsRoute(AnalyticsRoutes.CA_ROUTE_PROFILE), back: true });
    };

    const { showProfile } = useCommonConfig();

    return (
        <div className="profile-header-menu-root">
            <Button
                buttonRef={anchorEl}
                onClick={handleClickButton}
                classes={{ root: 'pfm-button-root', text: 'pfm-button-text' }}
            >
                {loading && <Loader position="center" />}
                {!loading && <div className="pfm-button-label">{menulabel}</div>}
                <ExpandMoreIcon className={classNames({ 'pfm-button-expand-icon': true, open })} />
            </Button>

            <Popover
                open={open}
                anchorEl={anchorEl.current}
                anchorReference="anchorEl"
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                classes={{ paper: classNames('profile-header-popover-paper', { showProfile }) }}
            >
                <Button
                    classes={{ root: classNames('profile-disconnect-button', { showProfile }), text: 'text' }}
                    onClick={() => {
                        Analytics.log({ category: 'Clic', action: 'Logout', label: 'Profil' });
                        onLogout();
                    }}
                >
                    <FormattedMessage id="commons.header.menu.logout" />
                </Button>

                {/* must use factory render : profile is showProfile on front like fluizy front*/}
                {showProfile && <ProfileFactory />}
            </Popover>
        </div>
    );
};

export const ProfileHeaderMenuFactory = withFactory(ProfileHeaderMenu);

export const Profile = () => null;
export const ProfileFactory = withFactory(Profile);
