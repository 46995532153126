export const esGondoles = {
    'gondoles.details.notFound':
        'Imposible encontrar el cuestionario de proxy de góndola, intente nuevamente en unos minutos.',
    'gondoles.details.title': 'Detalles de Góndola',
    'gondoles.label': 'Góndola',
    'gondoles.list.emptyLabel': 'Actualmente no hay cuestionario de posicionamiento de góndola disponible',
    'gondoles.missingQuestions.description':
        'Parece que no ha respondido a todas las preguntas, verifique si ha completado todas las preguntas.',
    'gondoles.missingQuestions.title': 'Respuestas faltantes',
    'gondoles.questions.additionnalInfos': 'Información adicional: {info}',
    'gondoles.questions.itemTitle': 'pregunta',
    'gondoles.questions.metrics.caLastYear': 'Cifra compra Año N-1',
    'gondoles.questions.metrics.currentLength': 'Juego actual',
    'gondoles.questions.metrics.preconisation': 'Recomendación de posicionamiento de imágenes',
    'gondoles.questions.metrics.shopRank': 'Clasificación central dentro de la marca',
    'gondoles.questions.next': 'Próximo',
    'gondoles.questions.text.placeholder': 'Ingrese su respuesta',
    'gondoles.submission.invalidateError': 'Invalidación enfrentada',
    'gondoles.submission.repliedDate': 'Contestarlo',
    'gondoles.submission.saveError': 'Respaldo',
    'gondoles.submission.validateSuccess': 'Respuesta enviada',
    'gondoles.title': 'Góndolas',
    'gondoles.validationDialog.duplicateText': 'Puede duplicar esta respuesta para otro centro.',
    'gondoles.validationDialog.noDuplicate': 'No tienes otros centros para lesqules, puedes duplicar esta respuesta.',
    'gondoles.validationDialog.subtitle': 'Su respuesta se ha tenido en cuenta.',
    'gondoles.validationDialog.title': '¡Respuesta enviada!',
};