import './authBase.scss';

import React from 'react';

import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { LogoFactory } from '../../../../../components/Logo';
import { withFactory } from '../../../../../Factory';
import { MessageFactory } from '../Message/Message';

export const AUTH_BASE_C_LOCATION = 'AuthBase';

export interface AuthBaseProps {
    cLocation?: string;
    pageTitle?: string;
    left?: React.ReactNode;
    leftImg?: string;
    right: React.ReactNode;
    displayWelcomeMessage?: boolean;
    classes?: {
        root?: string;
        left?: string;
        leftContentContainer?: string;
        right?: string;
    };
}

export const AuthBase = ({
    cLocation = '/',
    pageTitle,
    left = undefined,
    right = undefined,
    classes,
    leftImg = undefined,
    displayWelcomeMessage = false,
}: AuthBaseProps) => {
    const { root: classNameRoot = '', right: classNameRight = '' } = classes || {};

    return (
        <React.Fragment>
            <div className={`authbase-page ${classNameRoot}`}>
                {left ? (
                    left
                ) : (
                    <DefaultLeftPanel
                        imageUrl={leftImg}
                        cLocation={cLocation}
                        displayWelcomeMessage={displayWelcomeMessage}
                    />
                )}

                {right && <div className={`authbase-right-container ${classNameRight}`}>{right}</div>}
            </div>
            {pageTitle && (
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>
            )}
        </React.Fragment>
    );
};

AuthBase.LeftPanelContainer = ({ children, className, ...props }: React.HTMLProps<HTMLDivElement>) => (
    <div className={classNames(`authbase-left-container`, className)} {...props}>
        {children}
    </div>
);

AuthBase.Logo = ({ className, ...props }: {} & React.ComponentProps<typeof LogoFactory>) => (
    <LogoFactory className={classNames('authbase-left-logo', className)} {...props} />
);
AuthBase.WelcomeMessage = ({ className, ...props }: {} & React.ComponentProps<typeof MessageFactory>) => (
    <MessageFactory className={classNames('authbase-left-message', className)} {...props} />
);

type DefaultLeftPanelProps = {
    cLocation?: string;
    imageUrl?: string;
    displayWelcomeMessage?: boolean;
};

const DefaultLeftPanel = ({ imageUrl, cLocation, displayWelcomeMessage }: DefaultLeftPanelProps) => {
    return (
        <AuthBase.LeftPanelContainer style={{ backgroundImage: imageUrl ? `url(${imageUrl})` : 'none' }}>
            <div className={`authbase-left-content-container`}>
                <AuthBase.Logo cLocation={`${cLocation}${AUTH_BASE_C_LOCATION}/`} />
                {displayWelcomeMessage && (
                    <AuthBase.WelcomeMessage cLocation={`${cLocation}${AUTH_BASE_C_LOCATION}/`} />
                )}
            </div>
        </AuthBase.LeftPanelContainer>
    );
};

export const AuthBaseFactory = withFactory(AuthBase);
