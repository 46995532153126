import { AnyAction } from 'redux';

import { I18nActions } from './I18nActions';
import { I18nState } from './I18nMessages';

//import { getInitialLocaleFactory, getSupportedLocalesFactory } from './I18nLocaleConfig';
// const initialState: I18nState = {
//     locales: getSupportedLocalesFactory(),
//     locale: getInitialLocaleFactory(),
//     messages: {},
// };

const initialState: I18nState = { locale: 'fr', locales: ['fr'], messages: {} };

export const I18nReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case I18nActions.SET_LOCALE:
            if ((state.locales || []).indexOf(action.payload.locale) !== -1) {
                return {
                    ...state,
                    locale: action.payload.locale,
                    messages: action.payload.messages,
                };
            }
            return state;
        case I18nActions.ADD_MESSAGES:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    ...action.payload.messages,
                },
            };
        default:
            return state;
    }
};

