import { withFuncFactory } from '../../Factory';
import { AuthenticationActions } from '../Auth/Services/Authentication/Base/AuthenticationActions';
import { UserMe } from './Models/UserMe';

export interface UserMeHandlerParameters {
    userData: UserMe;
    dispatch: any;
}

//* MAYBE RENAME THE METHOD NAME
export const UserMeHandler = ({ dispatch }: UserMeHandlerParameters) => {
    dispatch(AuthenticationActions.setAuthenticated(true));
}

export const UserMeHandlerFactory = withFuncFactory(UserMeHandler);