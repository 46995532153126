import React from 'react';
import { FormattedMessage } from 'react-intl';

import iconHomeWhite from './assets/home.svg';
import { NavbarModuleItem } from '../../lib/IdeLink.Web.Common/src/components/NavBar/components/NavBarItem/NavBarItem';
import { PrivateRoute } from '../../routers/PrivateRoute';
import { RouteItem } from '../../lib/IdeLink.Web.Common/src/types/RouteItem';
import { DashboardPageLoading } from './Pages/DashboardPage/DashboardPageLoading';

export class DashboardModule {
    public static DASHBOARD_ROUTE = '/';
    public static DASHBOARD_ROUTE_USER_ACTIONS = (userActions: boolean) => `?actions=${userActions}`;

    public static getDashboardRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: DashboardModule.DASHBOARD_ROUTE,
            component: DashboardPageLoading,
        },
    });

    public static getDashboardNavbarModuleItem = (): NavbarModuleItem => ({
        label: <FormattedMessage id="dashboard.navbar" />,
        route: DashboardModule.DASHBOARD_ROUTE,
        icon: iconHomeWhite,
        routeMatcher: ({ pathname }) => pathname === DashboardModule.DASHBOARD_ROUTE,
        onMouseOver: () => DashboardModule.preloadDashboard(),
    });

    private static preloadDashboard = () => DashboardPageLoading.preload();
}
