import React from 'react';

import { withFactory, WithFactoryProps } from '../../Factory';

export interface LogoProps {
    readonly src?: string;
    readonly className?: string;
    readonly alt?: string;
}

export const Logo = ({ cLocation, src, ...rest }: WithFactoryProps<LogoProps>) => {
    return <img alt="logo" src={src} {...rest} />;
};

export const LogoFactory = withFactory(Logo);
