import { withFuncFactory } from '../../Factory';

export enum AnalyticsRoutes {
    CA_ROUTE_LOGIN = '/core/login',

    CA_ROUTE_HOME = 'HOME',
    CA_ROUTE_PROFILE = 'PROFILE',

    CA_ROUTE_LICENSES = 'LICENSES',
    CA_ROUTE_CGU = 'CGU',

    CA_ROUTE_FORGOT_PW = '/common/forgottenpassword',
    CA_ROUTE_FORGOT_PW_FAIL = '/common/forgottenpassword/failed',
    CA_ROUTE_FORGOT_PW_SUCCESS = '/common/forgottenpassword/success',

    CA_ROUTE_CHANGE_PW = '/common/updatepassword',
    CA_ROUTE_CHANGE_PW_SUCCESS = '/common/updatepassword/interstitial',
}

export type AnalyticsRoutesMap = Record<string, any>;

export const GET_DEFAULT_ANALYTICS_ROUTES_MAP = (): AnalyticsRoutesMap => ({
    [AnalyticsRoutes.CA_ROUTE_LOGIN]: '/core/login',

    [AnalyticsRoutes.CA_ROUTE_HOME]: '/core/home',
    [AnalyticsRoutes.CA_ROUTE_PROFILE]: '/core/profile',

    [AnalyticsRoutes.CA_ROUTE_LICENSES]: '/core/profile/licence',
    [AnalyticsRoutes.CA_ROUTE_CGU]: '/core/profile/cgu',

    [AnalyticsRoutes.CA_ROUTE_FORGOT_PW]: '/common/forgottenpassword',
    [AnalyticsRoutes.CA_ROUTE_FORGOT_PW_FAIL]: '/common/forgottenpassword/failed',
    [AnalyticsRoutes.CA_ROUTE_FORGOT_PW_SUCCESS]: '/common/forgottenpassword/success',

    [AnalyticsRoutes.CA_ROUTE_CHANGE_PW]: '/common/updatepassword',
    [AnalyticsRoutes.CA_ROUTE_CHANGE_PW_SUCCESS]: '/common/updatepassword/interstitial',
});

export const getDefaultAnalyticsRoutesMap = (): AnalyticsRoutesMap => GET_DEFAULT_ANALYTICS_ROUTES_MAP();

export const getAnalyticsRoutesFactory = withFuncFactory(getDefaultAnalyticsRoutesMap);

export const getAnalyticsRoute = (key: string) => {
    const analyticsRoute = getAnalyticsRoutesFactory()[key];
    return typeof analyticsRoute === 'function' ? analyticsRoute() : analyticsRoute;
};
