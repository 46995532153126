import './FluizyConnectHeader.scss';

import React from 'react';
import {
    ConnectHeader,
    ConnectHeaderFactory,
    ConnectHeaderProps,
} from '../../../lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/modules/Auth/Pages/components/ConnectHeader/ConnectHeader';

const FluizyConnectHeader = (props: ConnectHeaderProps) => <ConnectHeader {...props} className="FluizyConnectHeader" />;

export const overrideFluizyConnectHeader = () => ConnectHeaderFactory.register('**', FluizyConnectHeader);
