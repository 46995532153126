export const esToasts = {
    'toast.alert.FrontRoleCode.GroupLeader': 'Estás visualizando solo para este centro',
    'toast.apiErrors.403': 'La cuenta no está autorizada para acceder a la solicitud.',
    'toast.apiErrors.ALL_QUANTITY_MUST_BE_FILLED': 'Deben rellenarse todos los campos',
    'toast.apiErrors.BACKOFFICE_USER_EMAIL_ALREADY_EXISTS': 'Ya existe un usuario con el mismo correo electrónico',
    'toast.apiErrors.defaultMessage': 'Algo no ha salido como estaba planeado, inténtelo de nuevo',
    'toast.apiErrors.DOCUMENT_URL_ERROR': 'Imposible recuperar el documento de la URL indicada',
    'toast.apiErrors.EMAIL_NOT_FOUND': 'No se encontró la dirección de correo electrónico',
    'toast.apiErrors.EVENT_ALREADY_PUBLISHED': 'Este evento ya está publicado',
    'toast.apiErrors.EVENT_ALREADY_UNPUBLISHED': 'Este evento ya está en el estado de publicación cancelada',
    'toast.apiErrors.EVENT_CANT_DELETE_PUBLISHED': 'Imposible eliminar un evento que se ha publicado',
    'toast.apiErrors.EVENT_EDIT_DATES_PUBLISHED': 'Este evento se ha publicado, las fechas ya no son modificables.',
    'toast.apiErrors.EVENT_EXPIRED': 'Ya no puedes responder a este evento, la fecha de vencimiento ya ha pasado',
    'toast.apiErrors.EVENT_INVALID_CHOICE_DATE': 'La fecha de finalización debe ser mayor que la fecha de inicio',
    'toast.apiErrors.EVENT_INVALID_END_DATE': 'La fecha de vencimiento debe ser antes del evento',
    'toast.apiErrors.EVENT_MISSING_AUDIENCE':
        'No está definida ninguna audiencia para este evento, no se puede publicar',
    'toast.apiErrors.EVENT_MISSING_CHOICE_DATE': 'La fecha de inicio o la fecha de finalización no es válida',
    'toast.apiErrors.EVENT_MISSING_END_DATE':
        'La fecha de vencimiento no está definida para este evento, no se puede publicar',
    'toast.apiErrors.EVENT_NO_DATE': 'No está definida ninguna fecha para este evento, no se puede publicar',
    'toast.apiErrors.EXTERNAL_REFERENCE_INVALID_FORMAT':
        'El formato de la referencia externa no es válido. La referencia externa solo puede contener letras, números y caracteres "_", "-" y "."',
    'toast.apiErrors.FRONT_USER_EMAIL_ALREADY_EXISTS': 'Ya existe un usuario con el mismo correo electrónico',
    'toast.apiErrors.FRONT_USER_HAVE_SHOPS': 'Este usuario está asociado con los centros, no se puede eliminar',
    'toast.apiErrors.GEOCODING_CANT_CONTACT_API': 'Se produjo un error al recuperar la posición GPS',
    'toast.apiErrors.GEOCODING_INVALID_RESULT_API': 'Se produjo un error al recuperar la posición GPS',
    'toast.apiErrors.GEOCODING_NO_RESULT_FOUND': 'No se encontró ningún puesto para esta dirección',
    'toast.apiErrors.GROUP_HAVE_CHILDREN': 'Este grupo no está vacío, no se puede eliminar',
    'toast.apiErrors.GROUP_LEADER_READONLY': 'No tienes derecho para hacer esta acción',
    'toast.apiErrors.IMAGE_URL_ERROR': 'Imposible recuperar la imagen de la URL indicada',
    'toast.apiErrors.INVALID_DOCUMENT_FORMAT':
        'El formato de este documento no es compatible, solo se autorizan los documentos PDF',
    'toast.apiErrors.INVALID_IMAGE_FORMAT': 'No pudimos tratar esta imagen, su formato no parece válido',
    'toast.apiErrors.INVALID_OLD_PASSWORD': 'Su contraseña anterior no es válida',
    'toast.apiErrors.noShops': 'No tienes derecho de acceso a ninguna tienda.',
    'toast.apiErrors.ONLY_ONE_ORDER_ALLOWED': 'Solo puedes hacer un pedido. Modifique su pedido actual.',
    'toast.apiErrors.PACKAGED_ORDER_ALREADY_PUBLISHED': 'Esta oferta ya está publicado',
    'toast.apiErrors.PACKAGED_ORDER_ALREADY_UNPUBLISHED': 'Esta oferta ya está en el estado de publicación cancelada',
    'toast.apiErrors.PACKAGED_ORDER_CANT_DELETE_PUBLISHED': 'Imposible eliminar un oferta que se ha publicado',
    'toast.apiErrors.PACKAGED_ORDER_MISSING_AUDIENCE':
        'Ninguna audiencia está definida para esta oferta, no se puede publicar',
    'toast.apiErrors.PACKAGED_ORDER_MISSING_END_DATE':
        'La fecha de vencimiento no está definida para esta oferta, no se puede publicar',
    'toast.apiErrors.PACKAGED_ORDER_MISSING_NOTIFICATION_CONFIGURATION':
        'Configure el envío de notificación antes de llevar a cabo esta operación.',
    'toast.apiErrors.PACKAGED_ORDER_MISSING_PRODUCT': 'El plan correcto debe contener al menos un producto',
    'toast.apiErrors.PACKAGED_ORDER_MISSING_UNIT_PRICE':
        'El precio unitario no está definida para esta oferta, no se puede publicar',
    'toast.apiErrors.PACKAGED_ORDER_NOT_PUBLISHED':
        'Esta operación solo se puede llevar a cabo en una oferta publicada.',
    'toast.apiErrors.PACKAGED_ORDER_PRODUCT_MISSING_MARGIN_RATE': 'La tasa de margen debe definirse para cada producto',
    'toast.apiErrors.PACKAGED_ORDER_PRODUCT_MISSING_MARGIN_RATE_PROMO':
        'La tasa de margen promocional debe definirse para cada producto',
    'toast.apiErrors.PACKAGED_ORDER_PRODUCT_MISSING_PURCHASE_PRICE':
        'El precio de compra debe definirse para cada producto',
    'toast.apiErrors.PACKAGED_ORDER_PRODUCT_MISSING_PURCHASE_PRICE_PROMO':
        'El precio de la compra promocional debe definirse para cada producto',
    'toast.apiErrors.PACKAGED_ORDER_PRODUCT_MISSING_QUANTITY': 'La cantidad debe definirse para cada producto',
    'toast.apiErrors.PACKAGED_ORDER_PUBLISHED_CANT_ADD_PRODUCT':
        'No se puede agregar un nuevo producto en un oferta que se ha publicado',
    'toast.apiErrors.PACKAGED_ORDER_PUBLISHED_CANT_DELETE_PRODUCT':
        'Imposible eliminar un producto existente en un oferta que se ha publicado',
    'toast.apiErrors.PACKAGED_ORDER_PUBLISHED_CANT_EDIT':
        'Se publica esta oferta, el precio unitario no se puede modificar',
    'toast.apiErrors.PACKAGED_ORDER_PUBLISHED_CANT_EDIT_PRODUCT':
        'Imposible modificar la referencia, el precio de la compra promocional o la cantidad de un producto en un oferta que ya se ha publicado',
    'toast.apiErrors.QUESTIONNAIRE_ANSWER_CHOICE_NOT_AVAILABLE':
        'No tienes una respuesta correcta a una de las preguntas.',
    'toast.apiErrors.QUESTIONNAIRE_MISSING_QUESTIONS': 'El cuestionario debe contener al menos una pregunta',
    'toast.apiErrors.QUESTIONNAIRE_PUBLISHED_CANT_EDIT':
        'Imposible modificar la estructura de un cuestionario después de la publicación',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_CHOICE_AMBIGUOUS_EXTERNAL_REFERENCE':
        'Varias respuestas corresponden a esta referencia',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_CHOICE_LABEL_MISSING': 'Cada respuesta posible debe tener una redacción',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_CHOICE_NOT_FOUND': 'Ninguna respuesta corresponde a esta referencia',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_MISSING_QUESTION':
        'Se debe definir una redacción de la pregunta para cada pregunta',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_MISSING_TITLE': 'Se debe definir un título para cada pregunta',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_NOT_ENOUGH_CHOICES':
        'Cada opción de preguntas debe ofrecer al menos dos respuestas posibles',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_SINGLE_CHOICE_ACTION_WHEN_SELECTED_UNDEFINED':
        'El siguiente comportamiento de la pregunta no está bien definido para una de las preguntas de elección única',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_SINGLE_CHOICE_GO_TO_QUESTION_ID_BAD_REFERENCE':
        'El siguiente comportamiento de la pregunta no está bien definido para una de las preguntas de elección única',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_SINGLE_CHOICE_GO_TO_QUESTION_ID_UNDEFINED':
        'El siguiente comportamiento de la pregunta no está bien definido para una de las preguntas de elección única',
    'toast.apiErrors.QUESTIONNAIRE_QUESTION_SINGLE_CHOICE_LABEL_NOT_DEFINED':
        'Cada respuesta posible debe tener una redacción',
    'toast.apiErrors.REMINDER_ALREADY_PUBLISHED': 'Este recordatorio ya está publicado',
    'toast.apiErrors.REMINDER_ALREADY_UNPUBLISHED': 'Este recordatorio ya está en el estado de publicación cancelada',
    'toast.apiErrors.REMINDER_CANT_DELETE_PUBLISHED': 'Imposible de eliminar un recordatorio que se ha publicado',
    'toast.apiErrors.REMINDER_EDIT_ADDRESS_PUBLISHED':
        'Este recordatorio se publica, la dirección ya no es modificable.',
    'toast.apiErrors.REMINDER_EDIT_DATES_PUBLISHED': 'Este recordatorio se publica, las fechas ya no son modificables',
    'toast.apiErrors.REMINDER_MISSING_AUDIENCE':
        'No está definida ninguna audiencia para este retiro, no se puede publicar',
    'toast.apiErrors.REMINDER_MUST_HAVE_DATES': 'La fecha de inicio y la fecha de finalización deben definirse',
    'toast.apiErrors.SURVEY_ALREADY_PUBLISHED': 'Esta encuesta ya está publicada',
    'toast.apiErrors.SURVEY_CANT_DELETE_PUBLISHED': 'Imposible eliminar una encuesta que se ha publicado',
    'toast.apiErrors.SURVEY_MISSING_AUDIENCE':
        'No está definida ninguna audiencia para esta encuesta, no se puede publicar',
    'toast.apiErrors.SURVEY_MISSING_END_DATE':
        'La fecha de vencimiento no está definida para esta encuesta, no se puede publicar',
    'toast.apiErrors.SURVEY_MISSING_TITLE': 'El título no está definido para esta encuesta, no se puede publicar',
    'toast.apiErrors.SURVEY_REPLY_VALIDATED_CANNOT_BE_UPDATED':
        'No puede cambiar una respuesta de una encuesta ya validada',
    'toast.apiErrors.TUNABLE_OFFER_ALREADY_PUBLISHED': 'Esta oferta ya está publicada',
    'toast.apiErrors.TUNABLE_OFFER_ALREADY_UNPUBLISHED': 'Esta oferta ya está en el estado de publicación cancelada',
    'toast.apiErrors.TUNABLE_OFFER_CANT_DELETE_PUBLISHED': 'Imposible eliminar un oferta que se ha publicado',
    'toast.apiErrors.TUNABLE_OFFER_MISSING_AUDIENCE':
        'Ninguna audiencia está definida para esta oferta, no se puede publicar',
    'toast.apiErrors.TUNABLE_OFFER_MISSING_END_DATE':
        'La fecha de vencimiento no está definida para esta oferta, no se puede publicar',
    'toast.apiErrors.TUNABLE_OFFER_MISSING_NOTIFICATION_CONFIGURATION':
        'Configure el envío de notificación antes de llevar a cabo esta operación.',
    'toast.apiErrors.TUNABLE_OFFER_MISSING_PRODUCT': 'El plan correcto debe contener al menos un producto',
    'toast.apiErrors.TUNABLE_OFFER_MISSING_TARGET_QUANTITY': 'Ingrese la cantidad de objetivo total',
    'toast.apiErrors.TUNABLE_OFFER_NOT_PUBLISHED':
        'Esta operación solo se puede llevar a cabo en una oferta publicada.',
    'toast.apiErrors.TUNABLE_OFFER_PRODUCT_MISSING_MARGIN_RATE': 'La tasa de margen debe definirse para cada producto',
    'toast.apiErrors.TUNABLE_OFFER_PRODUCT_MISSING_MARGIN_RATE_PROMO':
        'La tasa de margen promocional debe definirse para cada producto',
    'toast.apiErrors.TUNABLE_OFFER_PRODUCT_MISSING_PURCHASE_PRICE':
        'El precio de compra debe definirse para cada producto',
    'toast.apiErrors.TUNABLE_OFFER_PRODUCT_MISSING_PURCHASE_PRICE_PROMO':
        'El precio de la compra promocional debe definirse para cada producto',
    'toast.apiErrors.TUNABLE_OFFER_PUBLISHED_CANT_ADD_PRODUCT':
        'No se puede agregar un nuevo producto en un oferta que se ha publicado',
    'toast.apiErrors.TUNABLE_OFFER_PUBLISHED_CANT_DELETE_PRODUCT':
        'Imposible eliminar un producto existente en un oferta que se ha publicado',
    'toast.apiErrors.TUNABLE_OFFER_PUBLISHED_CANT_EDIT':
        'Se publica esta oferta, el precio unitario no se puede modificar',
    'toast.apiErrors.TUNABLE_OFFER_PUBLISHED_CANT_EDIT_PRODUCT':
        'Imposible modificar la referencia, el precio de la compra promocional o la cantidad de un producto en un oferta que ya se ha publicado',
    'toast.apiErrors.UNABLE_TO_UPLOAD_DOCUMENT':
        'No hemos logrado enviar el documento, intente nuevamente en unos momentos',
    'toast.apiErrors.UNABLE_TO_UPLOAD_IMAGE':
        'No hemos logrado enviar la imagen, gracias por intentarlo en unos momentos',
    'toast.apiErrors.VIDEO_ALREADY_PUBLISHED': 'Este video ya está publicado',
    'toast.apiErrors.VIDEO_ALREADY_UNPUBLISHED': 'Este video ya está en la publicación cancelada.',
    'toast.apiErrors.VIDEO_CAN_NOT_BE_DELETED': 'Incapaz de eliminar un video que se ha publicado',
    'toast.apiErrors.VIDEO_FILE_NOT_READY':
        'El procesamiento de video aún no se ha terminado, intente nuevamente en unos momentos',
    'toast.apiErrors.VIDEO_FILE_NOT_SUPPORTED': 'No pudimos procesar este video, su formato no parece válido',
    'toast.apiErrors.VIDEO_MISSING_AUDIENCE': 'No se define ninguna audiencia para este video, no se puede publicar',
    'toast.apiErrors.VIDEO_MISSING_FILE':
        'Se debe enviar un archivo de video para permitir que se publique este momento digital',
    'toast.apiErrors.WEAK_PASSWORD': 'La contraseña no es muy segura',
    'toast.error.video': 'No se puede enviar su video',
    'toast.success.audienceFromQuestion': 'La audiencia ha sido actualizada en la jerarquía (no olvide guardar)',
    'toast.success.create': 'Creación exitosa',
    'toast.success.manualNotif': 'Relanzamiento hecho.',
    'toast.success.password.forgot': 'Solicitud de reinicio enviada',
    'toast.success.password.initialize': 'Contraseña inicializada',
    'toast.success.password.reset': 'Restablecer la contraseña',
    'toast.success.publish': 'El elemento fue publicado',
    'toast.success.unpublish': 'El elemento ha sido despublicado',
    'toast.success.update': 'Datos actualizados',
};
