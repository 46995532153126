export const frDashboard = {
    'dashboard.navbar': 'Tableau de bord',

    'dashboard.title': 'Bienvenue sur Fluizy !',
    'dashboard.page.title': 'Tableau de bord',

    'dashboard.tabs.all': 'Tout',
    'dashboard.tabs.mine': 'Mes actions',

    'dashboard.feed.title': 'Activité',
    'dashboard.feed.empty': "Vous n'avez pas d'activité ",

    'dashboard.todos.title': 'À faire',
    'dashboard.todos.empty': 'Félicitations ! Vous êtes à jour !',

    'dashboard.todo.Audits.title': '{itemCount, plural, one {AUDIT} other {AUDITS}}',
    'dashboard.todo.PackagedOrders.title': '{itemCount, plural, one {BON PLAN} other {BONS PLANS}}',
    'dashboard.todo.GoodDeals.title': '{itemCount, plural, one {BON PLAN} other {BONS PLANS}}',
    'dashboard.todo.Operations.title': '{itemCount, plural, one {PLAN DE VENTE} other {PLANS DE VENTE}}',
    'dashboard.todo.CommunicationsGroup.title': '{itemCount, plural, one {COMMUNICATION} other {COMMUNICATIONS}}',
    'dashboard.todo.Events.title': '{itemCount, plural, one {ÉVÈNEMENT} other {ÉVÈNEMENTS}}',
    'dashboard.todo.Surveys.title': '{itemCount, plural, one {SONDAGE} other {SONDAGES}}',
    'dashboard.todo.Gondolas.title': '{itemCount, plural, one {GONDOLE} other {GONDOLES}}',

    'dashboard.todo.News.title': '{itemCount, plural, one {ACTUALITÉ} other {ACTUALITÉS}}',
    'dashboard.todo.Videos.title': '{itemCount, plural, one {VIDÉO} other {VIDÉOS}}',

    //* FEEDS

    'Feed.Titles.Core.Welcome': () => 'Bienvenue',
    Feed_Core_Welcome: ({ firstName }: any) => `Bienvenue ${firstName || ''} !!! Nous sommes ravis de vous accueillir sur Fluizy. Nous sommes là pour vous accompagner au quotidien.`,

};
