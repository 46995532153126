
export const frLogin = {
    //* LOGIN
    'login.citation': '"The most beautiful experience we can have is the mysterious. It is the fundamental emotion that stands at the cradle of true art and true science."',
    'login.author': 'Albert Einstein, The World as I See It, 1931',

    'login.pageTitle': 'Connexion',
    'login.username.title': 'Connexion',
    'login.username.label': 'Merci de vous connecter avec vos identifiants.',
    'login.username.btnLabel': 'Se connecter',
    'login.username.labelUsername': 'Email',
    'login.username.labelPassword': 'Mot de passe',
    'login.username.inputEmailEmpty': 'Veuillez saisir une adresse email valide',
    'login.username.inputPasswordEmpty': 'Veuillez saisir votre mot de passe',
    'login.username.passForgotLabel': 'Mot de passe oublié ?',
    'login.username.error': 'Les identifiants renseignés semblent être erronés. Veuillez réessayer ou réinitialisez votre mot de passe via le lien « Mot de passe oublié ? ».',

    'login.google.title': 'Connexion',
    'login.google.label': 'Bienvenue sur votre application Midaslink. Pour vous connecter, utilisez votre compte Google.',
    'login.google.btnLabel': 'Se connecter avec Google',

    'login.impersonate.btnLabel': 'Connexion maintenance',
    'login.impersonate.dialog.title': 'Connexion maintenance',
    'login.impersonate.dialog.inputPlaceholder': 'Saisir le code-pin',
    'login.impersonate.error': 'Le code pin renseigné semble être erroné.',

    'login.cgu.label.read': 'Lire ',
    'login.cgu.label.cgu': "les conditions générale d'utilsations",
};
