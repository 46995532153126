import './navbarCategory.scss';

import React from 'react';

import { NavbarItemFactory, NavbarModuleItem } from '../NavBarItem/NavBarItem';

const C_LOCATION = 'NavBarCategory';

export interface NavBarCategoryItem {
    readonly categoryName?: string;
    readonly items: Array<NavbarModuleItem>;
}

export interface NavBarCategoryProps {
    readonly cLocation?: string;
    readonly category: NavBarCategoryItem;
    readonly isElementSelected: (item: NavbarModuleItem) => boolean;
}

const NavBarCategory = ({
    cLocation = '/',
    isElementSelected,
    category: { categoryName, items },
}: NavBarCategoryProps) => {
    return (
        <div className="navbarcategory-container">
            {categoryName && <div className="category-title">{categoryName}</div>}
            {items.map((item: NavbarModuleItem, index: number) => (
                <NavbarItemFactory
                    key={item.route || index}
                    cLocation={`${cLocation}${C_LOCATION}/`}
                    item={item}
                    selected={isElementSelected(item)}
                />
            ))}
        </div>
    );
};

export { NavBarCategory };
