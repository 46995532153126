export const esEvents = {
    'events.details.notFound': 'No se encuentra ningún evento',
    'events.details.title': 'Detalles del evento',
    'events.feed.negativeReply': 'No participo',
    'events.feed.noReply': 'No contesté',
    'events.feed.positiveReply': 'Participo',
    'events.label': 'Evento',
    'events.list.emptyLabel': 'No hay eventos por el momento.',
    'events.participation.date': 'El {eventStartDate} de {eventStartDateStartTime} a {eventEndDateEndTime}',
    'events.participation.date_range':
        'Desde {eventStartDate} a {eventStartDateStartTime} a {eventEndDate} a {eventEndDateEndTime}',
    'events.participation.isParticipating': 'Participo',
    'events.participation.notParticipating': 'No participo',
    'events.participation.question': '¿Planeas participar en este evento?',
    'events.participation.title': 'PARTICIPACIÓN',
    'events.submission.invalidateError': 'Invalidación enfrentada',
    'events.submission.repliedDate': 'Contestarlo',
    'events.submission.response': 'Responder',
    'events.submission.saveError': 'Respaldo',
    'events.submission.validateSuccess': 'Respuesta enviada',
    'events.title': 'Eventos',
    'events.validationDialog.duplicateText': 'Puede duplicar esta respuesta para otro centro.',
    'events.validationDialog.noDuplicate': 'No tienes otros centros para los cuales puedas duplicar esta respuesta.',
    'events.validationDialog.subtitle': 'Su respuesta se ha tenido en cuenta.',
    'events.validationDialog.title': '¡Respuesta enviada!',
};