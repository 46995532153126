
import { frMessages } from "../../lib/IdeLink.Web.FrontOffice/src/locales/fr/frAllMessages";


export const frFluizyMessages = {
    ...frMessages,
    'app.name': 'Fluizy',
    'login.welcome': 'Bienvenue sur l’espace Fluizy ! Merci de vous connecter avec vos identifiants pour accéder à votre espace personnel et profiter du réseau Norauto Franchisés !',
};


