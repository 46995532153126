import './button.scss';

import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import Loader from 'react-loaders';
import { Link } from 'react-router-dom';

import { LabelIconPosition, LabelWithIcon, LabelWithIconProps } from '../../Labels/LabelWithIcon';
import { withFactory } from '../../../Factory';
import { useIdelinkTheme } from '../../../../../../xlink/configs/IdeLinkTheme';

export interface ButtonProps {
    light?: boolean;
    full?: boolean; // use this prop when multiple buttons are displayed in a same container to make their size equal
    onClick?: (e: React.MouseEvent) => void;
    label?: React.ReactNode;
    disabledInfo?: React.ReactNode;
    iconPosition?: LabelIconPosition;
    icon?: string;
    disabled?: boolean;
    danger?: boolean;
    classes?: {
        root?: string;
        button?: string;
    };
    type?: 'button' | 'submit' | 'reset';
    loading?: boolean;
    labelProps?: LabelWithIconProps;
    tabIndex?: number;
    fullWidth?: boolean;
    to?: string;
    visible?: boolean;
    style?: {
        root?: CSSProperties;
        content?: CSSProperties;
    };
}

export const Button = ({
    light = false,
    full = false,
    onClick = (e?: React.MouseEvent) => {},
    label = '',
    disabledInfo = '',
    iconPosition,
    icon = '',
    disabled = false,
    danger = false,
    classes,
    type = 'button',
    loading = false,
    labelProps = {},
    tabIndex = undefined,
    to = undefined,
    visible = true,
    style,
}: ButtonProps) => {
    const idelinkTheme : any = useIdelinkTheme();

    if (!visible) {
        return null;
    }

    const renderDisabledInfo = () => {
        if (disabled && disabledInfo) {
            return (
                <div className="disabled-info-container">
                    <div className="disabled-info">{disabledInfo}</div>
                </div>
            );
        }

        return null;
    };

    let buttonClass = idelinkTheme.buttons?.className ?? '';

    const { root: classNameRoot = '', button: classNameButton = '' } = classes || {};
    const buttonContainerClassNames = classNames({
        'button-container': true,
        full,
        [classNameRoot]: classNameRoot,
    });

    const buttonClassNames = classNames({
        button: true,
        light,
        full,
        disabled,
        danger,
        [buttonClass]: buttonClass,
        [classNameButton]: classNameButton,
    });

    const loaderClasses = classNames({ 'Button-loader': true, light });
    const buttonNode = (
        <div style={style?.root} className={buttonContainerClassNames}>
            <button
                type={type}
                style={style?.content}
                className={buttonClassNames}
                onClick={(e: React.MouseEvent) => !disabled && onClick(e)}
                tabIndex={tabIndex}
                disabled={disabled || loading}
            >
                <div className="button-content">
                    {loading && <Loader type="ball-beat" innerClassName={loaderClasses} active />}
                    {!loading && <LabelWithIcon {...labelProps} label={label} icon={icon} position={iconPosition} />}
                </div>
            </button>
            {renderDisabledInfo()}
        </div>
    );

    if (!to) return buttonNode;

    return (
        <Link to={to} style={style?.root}>
            {buttonNode}
        </Link>
    );
};

export const ButtonFactory = withFactory(Button);

export const DangerButton = (props: ButtonProps) => <ButtonFactory {...props} danger light />;
