import { LoginMethod } from '../AuthenticationConstants';

export class AuthenticationActions {

    public static SET_INITIALIZED = 'AUTHENTICATION_SET_INITIALIZED';
    public static SET_LOGIN_IN_PRGRESSS = 'SET_LOGIN_IN_PRGRESSS';
    public static LOG_OUT = 'AUTHENTICATION_LOG_OUT';
    public static SET_AUTHENTICATED = 'app/auth/base/set/isAuthenticated';
    public static SET_LOGIN_METHOD = 'app/auth/set/login/method';
    public static REMOVE_ERROR = 'app/auth/remove/error';

    public static logout = (reason?: string) => ({
        type: AuthenticationActions.LOG_OUT,
        payload: { reason },
    });

    public static setInitialized = (initialized: boolean) => ({
        type: AuthenticationActions.SET_INITIALIZED,
        payload: { initialized },
    });

    public static setAuthenticated = (authenticated: boolean) => ({
        type: AuthenticationActions.SET_AUTHENTICATED,
        payload: { authenticated },
    });

    public static setLoginMethod = (loginMethod: LoginMethod) => ({
        type: AuthenticationActions.SET_LOGIN_METHOD,
        payload: { loginMethod, },
    });
    public static setLoginInProgress = (loginInProgress: boolean) => ({
        type: AuthenticationActions.SET_LOGIN_IN_PRGRESSS,
        payload: { loginInProgress },
    });

    public static setUsernameLoginMethod = () => AuthenticationActions.setLoginMethod(LoginMethod.LOGIN_METHOD_USERNAME)
    public static setGoogleLoginMethod = () => AuthenticationActions.setLoginMethod(LoginMethod.LOGIN_METHOD_GOOGLE);
    public static setADLoginMethod = () => AuthenticationActions.setLoginMethod(LoginMethod.LOGIN_METHOD_ACTIVE_DIRECTORY);
    public static setImpersonateLoginMethod = () => AuthenticationActions.setLoginMethod(LoginMethod.LOGIN_METHOD_IMPERSONATE);
}

