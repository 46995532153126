import './usernameConnect.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { ButtonFactory } from '../../../../../components/Buttons';
import { withFactory } from '../../../../../Factory';
import { I18nState } from '../../../../../services/i18n/I18nMessages';
import { LoginInput } from '../LoginInput/LoginInput';
import { useIdelinkTheme } from '../../../../../../../../xlink/configs/IdeLinkTheme';

export interface UsernameConnectProps {
    readonly cLocation?: string;
    readonly i18n: I18nState;
    readonly loading?: boolean;
    readonly login: (email: string, password: string) => Promise<void>;
    readonly disabled?: boolean;
    readonly errorEmailInput?: boolean;
    readonly errorPasswordInput?: boolean;
}

const UsernameConnect = ({
    cLocation = '/',
    i18n,
    login = () => Promise.resolve(),
    loading = false,
    disabled = false,
    errorEmailInput = false,
    errorPasswordInput = false,
}: UsernameConnectProps) => {
    const theme: any = useIdelinkTheme();
    const history = useHistory();
    const locationState: any = useLocation().state;

    const [email, setEmail] = React.useState(locationState ? locationState.initialEmail : '');
    const [password, setPassword] = React.useState('');

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (!disabled) {
            login(email, password).catch((err) => err);
        }
    };

    const handlePushToForgotten = () => {
        history.push('/password/forgot', { initialEmail: email });
    };

    let loginButtonClass =  theme.loginButton?.className ?? '';

    return (
        <form onSubmit={handleSubmit} className="username-connect-container">
            <div className="username-connect-label">
                <FormattedMessage id="login.username.label" />
            </div>

            <LoginInput
                name="email"
                value={email}
                onChange={(event: any) => setEmail(event.target.value)}
                placeholder={i18n.messages['login.username.labelUsername']}
                disabled={disabled}
                error={errorEmailInput}
            />
            <LoginInput
                type="password"
                name="password"
                value={password}
                onChange={(event: any) => setPassword(event.target.value)}
                placeholder={i18n.messages['login.username.labelPassword']}
                disabled={disabled}
                error={errorPasswordInput}
            />

            <div className="username-connect-pass-forgot-label">
                <div className="username-connect-pass-forgot-link" onClick={handlePushToForgotten}>
                    <FormattedMessage id="login.username.passForgotLabel" />
                </div>
            </div>

            <ButtonFactory
                full
                loading={loading}
                disabled={disabled}
                cLocation={`${cLocation}UsernameConnect/`}
                label={<FormattedMessage id="login.username.btnLabel" />}
                classes={{ button: 'username-connect-button username-connect-override ' + loginButtonClass}}
                onClick={handleSubmit}
                type="submit"
            />
        </form>
    );
};

const UsernameConnectFactory = withFactory(UsernameConnect);

export { UsernameConnect, UsernameConnectFactory };
