export const esSurveys = {
    'surveys.details.notFound': 'No se encontró ninguna encuesta',
    'surveys.details.title': 'Detalles de la encuesta',
    'surveys.label': 'Encuesta',
    'surveys.list.emptyLabel': 'No hay encuesta en este momento.',
    'surveys.missingQuestions.description':
        'Parece que no ha respondido a todas las preguntas, verifique si ha completado todas las preguntas.',
    'surveys.missingQuestions.title': 'Respuestas faltantes',
    'surveys.questions.itemTitle': 'pregunta',
    'surveys.questions.next': 'Próximo',
    'surveys.questions.text.placeholder': 'Ingrese su respuesta',
    'surveys.submission.invalidateError': 'Invalidación enfrentada',
    'surveys.submission.repliedDate': 'Respondido {fecha}',
    'surveys.submission.saveError': 'Respaldo',
    'surveys.submission.validateSuccess': 'Respuesta enviada',
    'surveys.title': 'Encuestas',
    'surveys.validationDialog.duplicateText': 'Puedes duplicar esta respuesta para otro centro.',
    'surveys.validationDialog.noDuplicate': 'No hay otros centros para los que duplicar esta respuesta.',
    'surveys.validationDialog.subtitle': 'Su respuesta se ha tenido en cuenta.',
    'surveys.validationDialog.title': '¡Respuesta enviada!',
}