import React from 'react';
import { FormattedMessage } from 'react-intl';

import { withFactory } from '../../../../../Factory';
import { useConnect } from '../../../Hooks/Connect/useConnect';

import { I18nState } from '../../../../../services/i18n/I18nMessages';
import { LoginMethod } from '../../../Services/Authentication/AuthenticationConstants';
import { AuthenticationUtils } from '../../../Services/Authentication/Base/AuthenticationUtils';
import { ActiveDirectoryConnect } from '../ActiveDirectoryConnect/ActiveDirectoryConnect';
import { ConnectBoxFactory } from '../ConnectBox/ConnectBox';
import { ConnectContentFactory } from '../ConnectContent/ConnectContent';
import { ConnectHeaderFactory } from '../ConnectHeader/ConnectHeader';
import { ErrorFactory } from '../Error/Error';
import { GoogleConnect } from '../GoogleConnect/GoogleConnect';
import { UsernameConnect } from '../UsernameConnect/UsernameConnect';

export interface ConnectProps {
    readonly cLocation?: string;
    readonly i18n: I18nState;
    readonly loginMethod: LoginMethod;
    readonly setAuthFromGoogleCredentials: (credentials: any) => Promise<any>;
    readonly loginWithEmail: (email: string, password: string) => Promise<any>;
    readonly loginActiveDirectory: () => Promise<any>;
    readonly loginWithImpersonate: (pin: string) => Promise<any>;

    readonly emailConnecting?: boolean;
    readonly googleConnecting?: boolean;
    readonly activeDirectoryConnecting?: boolean;
    readonly impersonateConnecting?: boolean;
}

export const Connect = ({
    cLocation = '/',
    i18n,
    loginMethod,
    loginWithEmail,
    loginActiveDirectory,
    setAuthFromGoogleCredentials,

    emailConnecting,
    googleConnecting,
    activeDirectoryConnecting,
    impersonateConnecting,
}: ConnectProps) => {
    const {
        connectState: { error, errorEmailInput, errorPasswordInput },
        doLoginWithEmail,
        doLoginAzure,
    } = useConnect({
        emailConnecting,
        loginWithEmail,
        activeDirectoryConnecting,
        loginActiveDirectory,
        googleConnecting,
        impersonateConnecting,
    });

    return (
        <React.Fragment>
            <ErrorFactory cLocation={`${cLocation}Connect/`} hide={!error && !errorEmailInput && !errorPasswordInput}>
                {error || errorEmailInput || errorPasswordInput}
            </ErrorFactory>
            <ConnectBoxFactory>
                <ConnectHeaderFactory>{<FormattedMessage id="login.google.title" />}</ConnectHeaderFactory>
                <ConnectContentFactory>
                    {AuthenticationUtils.isLoginMethodGoogle(loginMethod) && (
                        <GoogleConnect
                            loading={googleConnecting}
                            setAuthFromGoogleCredentials={setAuthFromGoogleCredentials}
                        />
                    )}
                    {AuthenticationUtils.isLoginMethodUsername(loginMethod) && (
                        <UsernameConnect
                            i18n={i18n}
                            cLocation={`${cLocation}Connect/`}
                            loading={emailConnecting}
                            disabled={emailConnecting}
                            login={doLoginWithEmail}
                            errorEmailInput={Boolean(errorEmailInput)}
                            errorPasswordInput={Boolean(errorPasswordInput)}
                        />
                    )}
                    {AuthenticationUtils.isLoginMethodActiveDirectory(loginMethod) && (
                        <ActiveDirectoryConnect loading={activeDirectoryConnecting} login={doLoginAzure} />
                    )}
                </ConnectContentFactory>
            </ConnectBoxFactory>
        </React.Fragment>
    );
};

export const ConnectFactory = withFactory(Connect);
