export const esCommons = {
    'commons.404.pageTitle': '404',
    'commons.action.edit': 'Editar',
    'commons.action.export': 'Excel de exportación',
    'commons.action.validate': 'Validar',
    'commons.available': 'En curso',
    'commons.category.label': 'Categoría',
    'commons.category.value': 'Categoría: {valor}',
    'commons.document.joinedLabel': 'Articulación pdf',
    'commons.document.noDocument': 'Sin PDF',
    'commons.empty': '-',
    'commons.filters.title': 'Filtrar:',
    'commons.fromTo': 'De {de} a {a}',
    'commons.header.dialog.cancel': 'anular',
    'commons.header.dialog.content': '¿Estás seguro de que quieres cerrar sesión?',
    'commons.header.dialog.logout': 'Desconectarse',
    'commons.header.dialog.title': 'Desconexión',
    'commons.header.menu.logout': 'Desconectarse',
    'commons.module.news.content.unavailable':
        'La visualización de noticias no está disponible en la aplicación web Fluizy. Para acceder a él, puede usar la aplicación móvil Fluizy disponible para iPhone y Android.',
    'commons.module.videos.content.unavailable':
        'La visualización de videos no está disponible en la aplicación web Fluizy. Para acceder a él, puede usar la aplicación móvil Fluizy disponible para iPhone y Android.',
    'commons.negativeReply': 'No participo',
    'commons.no': 'No',
    'commons.noReply': 'No respondido',
    'commons.notAvailable': 'Terminado',
    'commons.ok': 'OK',
    'commons.or': 'O',
    'commons.positiveReply': 'Respondí',
    'commons.publishedBy': 'Publicado por {nombre}',
    'commons.replyDate': 'Fecha de respuesta:',
    'commons.shops.none': 'No tienes centros',
    'commons.shops.noSelection': 'Elija un centro',
    'commons.submission.shop': 'Tienda',
    'commons.the': 'La fecha}',
    'commons.upTo': 'Hasta la fecha}',
    'commons.validationDialog.myShops': 'Mis centros',
    'commons.validationDialog.switch.successful': 'Ahora estás en el centro {nombre}',
    'commons.yes': 'Sí',
};
