import './ImpersonateConnectButton.scss';

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Loader } from '../../../../../components/Loader';
import icConnectMaintenance from '../../../assets/ic_connect_maintenance.svg';
import { I18nState } from '../../../../../services/i18n/I18nMessages';
import { InputPinDialog } from './InputPinDialog/InputPinDialog';

export interface ImpersonateConnectProps {
    readonly i18n: I18nState;
    readonly loading?: boolean;
    readonly disabled?: boolean;
    readonly loginWithImpersonate?: (pin: string) => Promise<void>;

    readonly classes?: { root?: string };
}

export const ImpersonateButtonConnect = ({
    i18n,
    loading = false,
    disabled = false,
    loginWithImpersonate = (pin: string) => Promise.resolve(),
    classes = {},
}: ImpersonateConnectProps) => {
    const [openDialogInputPin, setOpenDialogInputPin] = React.useState<boolean>(false);

    return (
        <React.Fragment>
            <div
                role="button"
                className={classNames({ 'ImpersonateButtonConnect-button': true, [classes.root || '']: classes.root })}
                onClick={disabled ? undefined : () => setOpenDialogInputPin(true)}
            >
                {!loading && (
                    <div className="ImpersonateButtonConnect-button-label">
                        <img className="ImpersonateButtonConnect-icon" src={icConnectMaintenance} alt="google-logo" />
                        <div className="ImpersonateButtonConnect-title">
                            <FormattedMessage id="login.impersonate.btnLabel" />
                        </div>
                    </div>
                )}
                {loading && <Loader classes={{ container: 'ImpersonateButtonConnect-loader' }} />}
            </div>
            <InputPinDialog
                open={openDialogInputPin}
                onCancel={() => setOpenDialogInputPin(false)}
                onValidateInput={(pin: string) => {
                    setOpenDialogInputPin(false);
                    loginWithImpersonate(pin);
                }}
                inputHintLabel={i18n.messages['login.impersonate.dialog.inputPlaceholder']}
            />
        </React.Fragment>
    );
};
