export const esProfile = {
    'profile.about': 'SOBRE MI',
    'profile.change.password.button': 'Cambia la contraseña',
    'profile.change.password.dialog.button.submit.label': 'OK',
    'profile.change.password.dialog.title': 'Cambio de contraseña',
    'profile.change.password.toast.confirmation.not.same': 'Las nuevas contraseñas no son idénticas',
    'profile.change.password.toast.missing.inputs': 'Uno o más campos no están llenos',
    'profile.change.password.toast.success': '¡Contraseña cambiada!',
    'profile.email_notifications_enabled.label': 'Suscribirse a notificaciones por correo electrónico',
    'profile.email.notifications.disabled.success': 'Notificaciones para discapacitados',
    'profile.email.notifications.enabled.success': 'Notificaciones activadas',
    'profile.eula': 'Cuero',
    'profile.licenses': 'Licencias',
    'profile.my.shops': 'Mis centros',
    'profile.no.shops': 'No tienes centros',
    'profile.password.confirmation.label': 'Validar su nueva contraseña',
    'profile.password.confirmation.placeholder': 'Nueva validación de contraseña',
    'profile.password.new.label': 'Introduzca su nueva contraseña',
    'profile.password.new.placeholder': 'Nueva contraseña',
    'profile.password.old.label': 'Ingrese su contraseña anterior para poder modificarla',
    'profile.password.old.placeholder': 'antigua contraseña',
}