export const esDashboard = {
    'dashboard.feed.empty': 'No tienes actividad',
    'dashboard.feed.title': 'Actividad',
    'dashboard.navbar': 'Dashboard',
    'dashboard.page.title': 'Dashboard',
    'dashboard.tabs.all': 'Todo',
    'dashboard.tabs.mine': 'Mis acciones',
    'dashboard.title': '¡Bienvenido a Fluizy!',
    'dashboard.todo.Audits.title': '{itemCount, plural, one {AUDITORÍA} other {AUDITORÍAS}}',
    'dashboard.todo.CommunicationsGroup.title': '{itemCount, plural, one {COMUNICACIÓN} other {COMUNICACIONES}}',
    'dashboard.todo.Events.title': '{itemCount, plural, one {EVENTO} other {EVENTOS}}',
    'dashboard.todo.Gondolas.title': '{itemCount, plural, one {GONDOLA} other {GÓNDOLAS}}',
    'dashboard.todo.GoodDeals.title': '{itemCount, plural, one {OFERTA} other {OFERTAS}}',
    'dashboard.todo.News.title': '{itemCount, plural, one {NOTICIA} other {NOTICIAS}}',
    'dashboard.todo.Operations.title': '{itemCount, plural, one {PLAN DE VENTAS} other {PLANES DE VENTAS}}',
    'dashboard.todo.PackagedOrders.title': '{itemCount, plural, one {OFERTA} other {OFERTAS}}',
    'dashboard.todo.Surveys.title': '{itemCount, plural, one {ENCUESTA} other {ENCUESTAS}}',
    'dashboard.todo.Videos.title': '{itemCount, plural, one {VIDEO} other {VIDEOS}}',
    'dashboard.todos.empty': '¡Felicitaciones! Estas actualizado!',
    'dashboard.todos.title': 'Por hacer',
};
