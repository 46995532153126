import qs from 'qs';

export const qsOptions: qs.IStringifyOptions = {
    indices: false,
    arrayFormat: 'repeat',
    addQueryPrefix: true,
};

export const mergeParams = (...params: Array<string>) =>
    qs.stringify(
        {
            ...params.reduce((acc: {}, curr: {}) => ({ ...acc, ...curr }), {}),
        },
        qsOptions
    );

export const defaultSerializeRequestParameters = (params: {}): string => {
    return qs.stringify(params, { ...qsOptions });
};

export const serializeRequestParameters = (params: {}): string => {
    return qs.stringify(params, { ...qsOptions, addQueryPrefix: false });
};