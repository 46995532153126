import { AuthenticationActions } from '../Auth/Services/Authentication/Base/AuthenticationActions';
import { AuthenticationOperations } from '../Auth/Services/Authentication/Base/AuthenticationOperations';
import { UserMeResponse } from './Models/UserMe';
import { UserApi } from './UserApi';
import { UserMeHandlerFactory } from './UserMeHandler';

export class UserActions {

    public static SET_INITIALIZED = 'USER_SET_INITIALIZED';
    public static SET_ME = 'USER_SET_ME';
    public static SET_IDLE = 'USER_SET_IDLE';
    public static SET_AUTHENTICATED_USER_INFO = 'USER_SET_AUTHENTICATED_USER_INFO';

    public static setInitialized(initialized: boolean, field?: string) {
        return { type: UserActions.SET_INITIALIZED, initialized };
    }

    public static setMe = (me: any) => ({ type: UserActions.SET_ME, me })

    public static setIdle = (idle: any) => ({ type: UserActions.SET_IDLE, idle });

    public static setAuthenticatedUserInfo(info: any) {
        return { type: UserActions.SET_AUTHENTICATED_USER_INFO, info };
    }

    public static retrieveAuthenticatedUserInfo = (token: string = '', reset = false) => (dispatch: any) => {
        if (reset) dispatch(UserActions.setInitialized(false, 'info'));

        if (token) {
            return UserApi.retrieveAuthenticatedUserInfo(token, dispatch)
                .then((res: any) => {
                    const userData = res.data;
                    dispatch(AuthenticationActions.setLoginInProgress(false));
                    dispatch(UserActions.setAuthenticatedUserInfo(userData));
                    UserMeHandlerFactory({ userData, dispatch });
                    return Promise.resolve(userData);
                })
                .catch(error => {
                    dispatch(AuthenticationActions.setLoginInProgress(false));
                    dispatch(AuthenticationOperations.logout(`Could not retrieve user info, ${error.toString()}`));
                    return Promise.reject(error);
                });
        }

        return dispatch(AuthenticationOperations.getAuthorization()).then((authToken: any) => {
            if (authToken && authToken.isError) {
                dispatch(AuthenticationActions.setLoginInProgress(false));
                dispatch(
                    AuthenticationOperations.logout(
                        `Could not retrieve access token to retrieve user info, ${(
                            authToken.error || authToken.err
                        ).toString()}`
                    )
                );
                return Promise.reject(authToken.error || authToken.err);
            }

            if (authToken) {
                return UserApi.retrieveAuthenticatedUserInfo(authToken, dispatch)
                    .then((res: UserMeResponse) => {
                        const userData = res.data;
                        dispatch(AuthenticationActions.setLoginInProgress(false));
                        dispatch(UserActions.setAuthenticatedUserInfo(userData));
                        UserMeHandlerFactory({ userData, dispatch });
                        return Promise.resolve(userData);
                    })
                    .catch(error => {
                        dispatch(AuthenticationActions.setLoginInProgress(false));
                        dispatch(
                            AuthenticationOperations.logout(
                                `Could not retrieve user info after manually getting token, ${error.toString()}`
                            )
                        );
                        return Promise.reject(error);
                    });
            }
        });
    };
}

