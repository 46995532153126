import './GoogleConnect.scss';

import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { FormattedMessage } from 'react-intl';

import { Loader } from '../../../../../components/Loader';
import googleLogo from '../../../assets/google_g_logo.png';
import { GoogleAuthService } from '../../../Services/Authentication/Google/GoogleAuthService';

export interface GoogleConnectProps {
    readonly loading?: boolean;
    readonly setAuthFromGoogleCredentials: (credentials: any) => Promise<void>;
}

export const GoogleConnect = ({ loading, setAuthFromGoogleCredentials }: GoogleConnectProps) => {

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setAuthFromGoogleCredentials(codeResponse),
        flow: 'auth-code',
        onError: () => GoogleAuthService.logout(),
    });

    return (
        <React.Fragment>
            {loading && <Loader />}
            {!loading && (
                <React.Fragment>
                    <div className="google-label">{<FormattedMessage id="login.google.label" />}</div>
                    <div role="button" className="google-login-btn" onClick={() => login()}>
                        <div className="google-logo-container">
                            <img alt="google-logo" className="google-logo-img" src={googleLogo} />
                        </div>
                        <div className="label-container">
                            <div className="label">
                                <FormattedMessage id="login.google.btnLabel" />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};
