
import { I18nMessages, I18nState } from './I18nMessages';

export class I18nActions {

    public static SET_LOCALE = 'app/i18n/set/locale';
    public static ADD_MESSAGES = 'app/i18n/add/messages'

    public static setLocale = (data: I18nState) => ({
        type: I18nActions.SET_LOCALE,
        payload: { locale: data.locale, messages: data.messages },
    });

    public static addMessages = (messages: I18nMessages) => ({
        type: I18nActions.ADD_MESSAGES,
        payload: { messages },
    });
}

