
import './OperationsTheme.scss'

import iconWhite from '../assets/ic_operation_white.svg';
import defaultImgOperation from '../assets/default-img-plandevente-square.svg';
import emptyIconOperation from '../assets/ic-operation-empty.svg';
import iconColorOperation from '../assets/operation-color.svg';
import { IdelinkModuleTheme } from '../../../xlink/configs/IdeLinkTheme';

export const OperationsTheme: IdelinkModuleTheme = {
    className: 'idelink-theme il-theme-operations',
    backgroundColor: '#348ee8',
    feedImage: defaultImgOperation,
    emptyIcon: emptyIconOperation,
    iconColor: iconColorOperation,
    iconWhite,
};

export const EngagmentsTheme: IdelinkModuleTheme = {
    className: 'idelink-theme il-theme-engagements',
    backgroundColor: '#fcb731',
    feedImage: defaultImgOperation,
    iconColor: iconColorOperation,
    iconWhite,
};
