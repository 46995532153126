import { CommunicationType } from '../../modules/Communications/Models/CommunicationType';

export const frCommunications = {
    'communications.navbar': 'Communication',
    'communication.title': 'Communications',

    'communication.list.emptyLabel': "Il n'y a pas de plans de communications pour le moment",

    'communication.type.digital.label': 'Digital',
    'communication.type.leafletType.label': 'Prospectus',
    'communication.type.partnerships.label': 'Partenariat',

    // CATEGORY FILTER
    [`communication.type.${CommunicationType.DigitalMarketing}`]: 'Digital',
    [`communication.type.${CommunicationType.Partnership}`]: 'Partenariat',
    [`communication.type.${CommunicationType.Leaflet}`]: 'Prospectus',

    // COMMUNICATION DETAILS
    'communication.details.notFound': 'Aucune communication trouvée',
    'communication.error.type.message': 'il y a des communication avec des erreurs de type',

    // COMMUNICATION DETAILS > VALID AND INVALID BOXES
    'communication.submission.repliedDate': 'Commande passée le {date}',

    'communication.submission.quantity': 'Quantité',
    'communication.submission.total.before_promo_price': 'Prix total',

    'communication.invalid.quantities.title': 'Quantité invalide',
    'communication.invalid.quantities.message': 'La quantité renseignée est invalide ou est inférieure à la quantité minimum : {quantity_min}',

    'communication.submission.label': 'Commander',

    // COMMUNICATION DETAILS > before_promo_price BOXE
    'communication.initial.before_promo_price.label': 'PRIX INITIAL',
    'communication.promo_price.per.person.value': '{price}/PERS.',

    'communication.promo.promo_price.label': 'PRIX PROMO',
    'communication.promo.promo_price_no_before.label': 'PRIX',

    // COMMUNICATION DETAILS > INFO COM BOXE
    'communication.page.count.label': 'Nb de pages',
    'communication.page.count.value': '{page_count} pages',
    'communication.weight.label': 'Poids',
    'communication.weight.value': '{weight} g',
    'communication.minimal.count.label': 'Nb pers. ciblé',
    'communication.minimal.count.value': '{count} personnes',
};
