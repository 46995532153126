import * as analyticsLogger from 'loglevel';

import { ConfigFactory } from '../../configs/ApiConfig';
import { EnvironmentHelper } from '../../Helpers/EnvironmentHelper';
import { StoreProvider } from '../../redux/StoreProvider';
import { DeviceInformation, SelectedShopInformations, UserInformations } from './AnalyticsInformations';

// @ts-ignore
import remote from 'loglevel-plugin-remote';

export interface LogFields {
    [field: string]: any;
    category?: string;
    action?: string;
    label?: string;
    route?: string;
}

const getLogHeader = () =>
    JSON.stringify({ index: { _index: ConfigFactory.getLogsConfig().elasticSearchIndex, _type: 'analytics' } });

export class Analytics {
    public static initialize = () => {
        analyticsLogger.setLevel(analyticsLogger.levels.INFO);

        //@ts-ignore : disable normal console logging
        analyticsLogger.methodFactory = () => () => undefined;

        remote.apply(analyticsLogger, {
            url: ConfigFactory.getLogsConfig().domain,
            //* we send a string, but it elasticsearch expects NDJSON format
            headers: { 'Content-Type': 'application/json' },
            //* the body must have a newline at the end, but we cannot control that
            //* so just add a newline on every message
            format: ({ message }: any) => `${message}\n`,
            capacity: 10,
            interval: EnvironmentHelper.isDev() ? 1000 : 5 * 60 * 1000, //! 1s in dev, 5mn otherwise
        });
        analyticsLogger.setLevel(analyticsLogger.getLevel());
    };

    public static log = (fields: LogFields) => {
        const { User, MyShops } = StoreProvider.getAppState();
        console.log(User, MyShops);

        const message = {
            Fields: { ...fields },
            timestamp: new Date().toISOString(),
            LogLevel: 'Information',
            ...DeviceInformation.getElasticSearchDeviceInfos(),
            ...UserInformations.toElasticSearchUserInfos(User.get('info')),
            ...SelectedShopInformations.toElasticSearchSelectedShopInfos(MyShops ? MyShops.get('selectedShop') : undefined), //DO NOT CALL IN BO
        };

        const log = `${getLogHeader()}\n${JSON.stringify(message)}`;

        analyticsLogger.info(log);
    };
}
