import { XHRRequest } from '../../lib/IdeLink.Web.Common/src/services/api';
import { ApiResponse } from '../../lib/IdeLink.Web.Common/src/services/api/ApiResponse';
import { CurrentFrontUserMeResponse, UpdateEmailNotificationsRequest, UpdatePasswordRequest } from './UserMeResponse';

export class FrontUserApi {

    public static UPDATE_EMAIL_NOTIFICATION = '/api-front/v1/me/emailnotifications';

    public static UPDATE_PASSWORD = '/api/v1/oauth2/passwords/update';

    public static enableEmailnotifications = (bodyRequest: UpdateEmailNotificationsRequest) => {
        return XHRRequest.patch<CurrentFrontUserMeResponse>(FrontUserApi.UPDATE_EMAIL_NOTIFICATION, bodyRequest);
    };

    public static updatePassword = (bodyRequest: UpdatePasswordRequest) => {
        return XHRRequest.patch<ApiResponse>(FrontUserApi.UPDATE_PASSWORD, bodyRequest);
    };
}
