import './error.scss';

import classnames from 'classnames';
import React from 'react';

import { withFactory } from '../../../../../Factory';

export interface ErrorProps {
    readonly hide?: boolean;
    readonly classNameRoot?: string;
    readonly classNameContent?: string;
    readonly children?: React.ReactNode;
}

export const Error = ({ classNameRoot = '', classNameContent = '', hide = false, children }: ErrorProps) => {
    const containerClassName = classnames('auth-box-error-container', classNameRoot, { hide });
    return (
        <div className={containerClassName}>
            <div className={`auth-box-errorcontent-container ${classNameContent}`}>{children}</div>
        </div>
    );
};

export const ErrorFactory = withFactory(Error);
