import { AppState } from '../../../../../app/AppState';
import { ActiveDirectoryAuthService } from '../ActiveDirectory/ActiveDirectoryAuthService';
import { EmailAuthService } from '../Email/EmailAuthService';
import { GoogleAuthService } from '../Google/GoogleAuthService';
import { ImpersonateAuthService } from '../Impersonate/ImpersonateAuthService';
import { AuthenticationUtils } from './AuthenticationUtils';

export class AuthenticationOperations {
    public static logout = (reason?: string) => (dispatch: (action: any) => any, getState: () => AppState) => {
        const loginMethod = getState().Authentication.get('loginMethod');

        if (AuthenticationUtils.isLoginMethodGoogle(loginMethod)) {
            dispatch(GoogleAuthService.logout(reason));
        }
        if (AuthenticationUtils.isLoginMethodUsername(loginMethod)) {
            dispatch(EmailAuthService.logout(reason));
        }
        if (AuthenticationUtils.isLoginMethodActiveDirectory(loginMethod)) {
            dispatch(ActiveDirectoryAuthService.logoutAd(reason));
        }
        if (AuthenticationUtils.isLoginMethodImpersonate(loginMethod)) {
            dispatch(ImpersonateAuthService.logout(reason));
        }
        return Promise.resolve({ isError: true, error: new Error('Login method not set') });
    };

    public static refreshAuthentication =
        (forceRefresh?: boolean) => (dispatch: (action: any) => any, getState: () => AppState) => {
            const loginMethod = getState().Authentication.get('loginMethod');

            if (AuthenticationUtils.isLoginMethodGoogle(loginMethod)) {
                return dispatch(GoogleAuthService.refreshGoogleAuthentication)
            }
            if (AuthenticationUtils.isLoginMethodUsername(loginMethod)) {
                return dispatch(EmailAuthService.refreshAuth(forceRefresh));
            }
            if (AuthenticationUtils.isLoginMethodActiveDirectory(loginMethod)) {
                return dispatch(ActiveDirectoryAuthService.refreshAd(forceRefresh));
            }
            if (AuthenticationUtils.isLoginMethodImpersonate(loginMethod)) {
                return dispatch(ImpersonateAuthService.refreshAuth(forceRefresh));
            }
            return Promise.resolve({ isError: true, error: new Error('Login method not set') });
        };

    /** @returns {String} the authorization header, NOT the token**/
    public static getAuthorization =
        () =>
        async (dispatch: (action: any) => any, getState: () => AppState): Promise<any> => {
            const loginMethod = getState().Authentication.get('loginMethod');

            if (AuthenticationUtils.isLoginMethodUsername(loginMethod)) {
                return dispatch(EmailAuthService.getAuthorization());
            }
            if (AuthenticationUtils.isLoginMethodGoogle(loginMethod)) {
                return dispatch(GoogleAuthService.getGoogleAuthToken());
            }
            if (AuthenticationUtils.isLoginMethodActiveDirectory(loginMethod)) {
                return dispatch(ActiveDirectoryAuthService.getAuthorization());
            }
            if (AuthenticationUtils.isLoginMethodImpersonate(loginMethod)) {
                return dispatch(ImpersonateAuthService.getAuthorization());
            }
            return Promise.resolve({ isError: true, error: new Error('Login method not set') });
        };
}
